import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import Icon from "../components/Icon";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class UnreadFeedback extends Component {
  static contextType = ApiContext;
  state = { unread_count: 0 };

  componentDidMount() {
    this._isMounted = true;
    this.fetchIssues();
    this.interval = setInterval(this.fetchIssues, 1000 * 300);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchIssues = () => {
    let lastFetched = localStorage.getItem("INDIER_LAST_FEEDBACK_FETCH");
    if (!lastFetched) {
      lastFetched = moment().toISOString();
      localStorage.setItem("INDIER_LAST_FEEDBACK_FETCH", lastFetched);
    }
    this.context
      .callApi(() => fetchResources(`unread-feedback?last_checked=${lastFetched}`))
      .then(({ data }) => {
        this.safeSetState({
          unread_count: this.state.unread_count + data
        });
        localStorage.setItem("INDIER_LAST_FEEDBACK_FETCH", moment().toISOString());
        if (data > 0) {
          toast.info(`Received ${data} new ${data > 1 ? "issues" : "issue"}.`);
        }
      })
      .catch(error => {
        clearInterval(this.interval);
      });
  };

  render() {
    const { unread_count } = this.state;
    return (
      <NavLink
        onClick={() => {
          this.setState({ unread_count: 0 });
          this.props.toggleMenu();
        }}
        exact={true}
        className="nav-item"
        to="/admin-feedback">
        <Icon icon="view-feedback" />
        <Trans>View Admin Feedback</Trans>{" "}
        {unread_count > 0 && (
          <span
            className="text-white text-xs bg-red rounded ml-2 p-1 flex items-center justify-center"
            style={{ minWidth: 21 }}>
            {unread_count}
          </span>
        )}
      </NavLink>
    );
  }
}

export default UnreadFeedback;
