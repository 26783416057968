import React from "react";
import { Trans } from "@lingui/macro";

const Debug = ({ children }) =>
  children && process.env.NODE_ENV === "development" ? (
    <div className="pin-b pin-x p-4 text-sm text-white bg-orange">
      <h3 className="mb-4">
        <Trans>Debug Info</Trans>
      </h3>
      <pre>{JSON.stringify(children, null, 4)}</pre>
    </div>
  ) : null;

export default Debug;
