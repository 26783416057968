import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const AttachmentIndex = props => (
  <div>
    <ResourceIndex
      fileUpload={true}
      endpoint="attachments"
      model={<Trans>Attachments</Trans>}
      download={true}
      headers={["filename", "uploaded_by", "viewable_by"]}
      translations={[
        <Trans>Filename</Trans>,
        <Trans>Uploaded By</Trans>,
        <Trans>Viewable By</Trans>
      ]}
      {...props}
      actions={["download", "delete"]}
    />
  </div>
);

export default AttachmentIndex;
