import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import moment from "moment";

class TripShow extends Component {
  static contextType = ApiContext;
  state = {
    feedback: [],
    filter: "all",
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchFeedback();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchFeedback();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchFeedback = () => {
    const { id } = this.props.match.params;
    this.safeSetState({ isLoading: true, error: null });
    this.context
      .callApi(() => fetchResources("trips/" + id + "/feedback"))
      .then(({ data }) => {
        this.safeSetState({
          feedback: data.data,
          staff: data.data.filter(f => f.userable_type === "App\\Staff"),
          clients: data.data.filter(
            f => f.userable_type === "App\\Client" || f.userable_type === "App\\Teacher"
          ),
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const { feedback, isLoading, filter, staff, clients } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Trip Feedback</Trans>
            </h1>
            <div className="flex items-center rounded-t bg-grey-lighter justify-between border-b">
              <div className="text-sm w-full justify-between flex items-center">
                <div className="flex">
                  <span
                    className={`block ${
                      filter === "all"
                        ? "text-teal hover:text-teal-dark"
                        : "text-grey-dark hover:text-grey-darker"
                    } p-4 cursor-pointer`}
                    onClick={() => this.setState({ filter: "all" })}>
                    <Trans>All</Trans>
                  </span>
                  <span
                    className={`block ${
                      filter === "clients"
                        ? "text-teal hover:text-teal-dark"
                        : "text-grey-dark hover:text-grey-darker"
                    } p-4 cursor-pointer`}
                    onClick={() => this.setState({ filter: "clients" })}>
                    <Trans>Clients</Trans>
                  </span>
                  <span
                    className={`block ${
                      filter === "staff"
                        ? "text-teal hover:text-teal-dark"
                        : "text-grey-dark hover:text-grey-darker"
                    } p-4 cursor-pointer`}
                    onClick={() => this.setState({ filter: "staff" })}>
                    <Trans>Staff</Trans>
                  </span>
                </div>
                <Link className="btn btn-solid btn-xs mr-4" to="/feedback">
                  <Trans>New Feedback</Trans>
                </Link>
              </div>
            </div>

            <div className="mb-2">
              <LoadingWrapper isLoading={isLoading}>
                {filter === "all" ? (
                  !feedback.length ? (
                    <div className="bg-white p-4 text-sm rounded-b">
                      <Trans>No feedback yet...</Trans>
                    </div>
                  ) : (
                    feedback.map((f, index) => <FeedbackRow key={index} feedback={f} />)
                  )
                ) : null}
                {filter === "staff" ? (
                  !staff.length ? (
                    <div className="bg-white p-4 text-sm rounded-b">
                      <Trans>No feedback yet...</Trans>
                    </div>
                  ) : (
                    staff.map((f, index) => <FeedbackRow key={index} feedback={f} />)
                  )
                ) : null}
                {filter === "clients" ? (
                  !clients.length ? (
                    <div className="bg-white p-4 text-sm rounded-b">
                      <Trans>No feedback yet...</Trans>
                    </div>
                  ) : (
                    clients.map((f, index) => <FeedbackRow key={index} feedback={f} />)
                  )
                ) : null}
              </LoadingWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const FeedbackRow = ({ feedback }) => (
  <div className="bg-white border-b p-4">
    <div className="flex">
      <div
        className={`mr-2 text-${
          feedback.userable_type.replace("App\\", "").toLowerCase() === "staff"
            ? "blue"
            : feedback.userable_type.replace("App\\", "").toLowerCase() === "client"
            ? "green"
            : "pink"
        }`}>
        <Icon icon={feedback.userable_type.replace("App\\", "").toLowerCase()} />
      </div>
      <div className="w-full">
        <h2 className="mb-1 text-xl">{feedback.title}</h2>
        <div className="text-xs mb-1 text-grey-dark">
          by {feedback.user_name} {moment(feedback.created_at).fromNow()}
        </div>

        <p className="text-sm">{feedback.description}</p>
        {feedback.image ? (
          <div className="p-4">
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/images/${
                feedback.image.id
              }?token=${localStorage.getItem("INDIER_JWT")}`}
              alt=""
            />
          </div>
        ) : null}

        <div className="flex justify-end items-center">
          <a
            className="btn btn-xs mr-2"
            href={`mailto:${feedback.user_email}?subject=RE:${feedback.title}`}>
            <Trans>Email User</Trans>
          </a>
          <Link
            to={`/${feedback.userable_type.replace("App\\", "").toLowerCase()}s/${
              feedback.userable_id
            }`}
            className="btn btn-xs font-light">
            <Trans>View User</Trans>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

export default TripShow;
