import React, { Component } from "react";
import PropTypes from "prop-types";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const defaultToolbarConfig = {
  options: ["inline", "blockType", "fontSize", "list", "link", "history"],
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"]
  },
  inline: {
    inDropdown: false,
    options: ["bold", "italic", "underline"]
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"]
  }
};

class Waiver extends Component {
  static propTypes = {
    content: PropTypes.string.isRequired,
    updateContent: PropTypes.func.isRequired,
    toolbarConfig: PropTypes.object.isRequired
  };
  static defaultProps = {
    content: EditorState.createEmpty(),
    toolbarConfig: defaultToolbarConfig
  };
  state = {
    content: null
  };

  componentDidMount() {
    this._isMounted = true;
    const blocksFromHtml = htmlToDraft(this.props.content);
    const { contentBlocks, entityMap } = blocksFromHtml;
    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const content = EditorState.createWithContent(contentState);
    this.safeSetState({ content });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleContentChange = editorState => {
    this.setState({ content: editorState }, () => {
      const content = draftToHtml(convertToRaw(this.state.content.getCurrentContent()));
      this.props.updateContent(content);
    });
  };

  render() {
    const { content } = this.state;
    const { toolbarConfig } = this.props;

    return (
      <Editor
        toolbar={toolbarConfig}
        editorState={content}
        toolbarClassName="rounded"
        wrapperClassName="wrapperClassName"
        editorClassName="editor-height border rounded p-4 bg-white min-h-150"
        onEditorStateChange={this.handleContentChange}
      />
    );
  }
}

export default Waiver;
