import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { fetchResources } from "../api";
import Advert from "../components/Advert";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";

class AdvertsContainer extends Component {
  static contextType = ApiContext;
  state = {
    adverts: [],
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchAdverts();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchAdverts = () => {
    this.safeSetState({ isLoading: true, error: null });
    this.context
      .callApi(() => fetchResources("/adverts"))
      .then(({ data }) => {
        this.safeSetState({
          adverts: data.data,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetech adverts.</Trans>);
      });
  };

  render() {
    const { adverts, isLoading } = this.state;
    const {
      user: { has_trips }
    } = this.context;

    return (
      <LoadingWrapper isLoading={isLoading}>
        {has_trips && adverts.length ? (
          <div>
            <h3 className="px-4 pt-8 text-white">
              <Trans>Upcoming Trips</Trans>
            </h3>
            <div className="flex overflow-y-auto py-4 pl-4">
              {adverts.map(advert => (
                <div key={advert.id} className="pr-4">
                  <Advert
                    title={advert.title}
                    url={advert.url}
                    trip_name={advert.trip_name}
                    trip_code={advert.trip_code}
                    description={advert.description}
                    image_id={advert.image_id}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </LoadingWrapper>
    );
  }
}

export default AdvertsContainer;
