import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const MedicalConditionIndex = props => (
  <div>
    <ResourceIndex
      goToEdit={true}
      endpoint="medical_conditions"
      model={<Trans>Medical Conditions</Trans>}
      canCreate={true}
      headers={["name"]}
      translations={[<Trans>Medical Condition</Trans>]}
      actions={["edit", "delete"]}
      {...props}
    />
  </div>
);

export default MedicalConditionIndex;
