import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";
import { ApiConsumer } from "../contexts/ApiContext";

const TripIndex = props => (
  <div>
    <ApiConsumer>
      {({ user }) => (
        <ResourceIndex
          endpoint="trips"
          canCreate={true}
          model={<Trans>Trips</Trans>}
          headers={[
            "trip_name",
            "registered",
            "paid",
            // "location_name",
            // "school_name",
            // "code",
            "trip_leader_name",
            "start_date",
            "end_date"
          ]}
          translations={[
            <Trans>Name</Trans>,
            <Trans>Registered</Trans>,
            <Trans>Paid</Trans>,
            // <Trans>Location</Trans>,
            // <Trans>School</Trans>,
            // <Trans>Code</Trans>,
            <Trans>Trip Leader</Trans>,
            <Trans>Start Date</Trans>,
            <Trans>End Date</Trans>
          ]}
          {...props}
          actions={user.role === "team_leader" ? ["show"] : ["show", "edit", "archive", "delete"]}
          hasCalendar={true}
          showAllCalendarEvents={true}
        />
      )}
    </ApiConsumer>
  </div>
);

export default TripIndex;
