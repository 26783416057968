import React from "react";
import ResourceEdit from "./ResourceEdit";

const SchoolEdit = props => (
  <div>
    <ResourceEdit {...props} endpoint="schools" model="School" />
  </div>
);

export default SchoolEdit;
