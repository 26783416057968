import React, { Component } from "react";
import AutoComplete from "../components/GlobalSearch";
import history from "../components/history";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, me } from "../api";
import { toast } from "react-toastify";

class Impersonate extends Component {
  static contextType = ApiContext;

  state = {
    isLoading: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleSelect = user => {
    console.log("%cuser", "color: #3490dc; font-weight: bold", user);
    const { callApi, setApiState } = this.context;
    this.safeSetState({ isLoading: true });
    callApi(() => createResource("/impersonate", { email: user.email }))
      .then(res => {
        localStorage.setItem("INDIER_JWT", res.data.token);
        localStorage.removeItem("INDIER_VERIFY_EMAIL");

        callApi(me)
          .then(res => {
            this.safeSetState({ isLoading: false });
            setApiState("user", res.data);
            history.push("/");
          })
          .catch(e => {
            this.safeSetState({ isLoading: false });
            toast.error(e);
          });
      })
      .catch(e => {
        console.warn("%cFailed to impersonate user.", "color: #f6993f; font-weight: bold", e);
        this.safeSetState({ isLoading: false });
      });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <h1 className="page-header mb-4">Impersonate User</h1>
          <div className="max-w-xs mx-auto mt-4">
            {isLoading ? (
              <div style={{ height: 34 }} className="p-2 text-sm bg-white rounded text-center">
                Logging in...
              </div>
            ) : (
              <AutoComplete
                placeholder="Search by email"
                url="users"
                onSelect={this.handleSelect}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Impersonate;
