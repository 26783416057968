import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";

const Advert = ({ title, description, trip_name, trip_code, image_id, url }) => (
  <div
    className="bg-grey-darker relative overflow-hidden bg-cover bg-center bg-no-repeat rounded"
    style={
      image_id
        ? {
            width: 300,
            height: 300,
            backgroundImage: `url(${
              process.env.REACT_APP_API_BASE_URL
            }/images/${image_id}?token=${localStorage.getItem("INDIER_JWT")})`
          }
        : { width: 300, height: 300 }
    }>
    <div
      className="absolute pin-b pin-x p-2 text-white"
      style={{ backgroundColor: "rgba(0,0,0,.75)" }}>
      <h2 className="mb-1">{title}</h2>
      <p className="mb-4">{trip_name}</p>
      <div className="flex">
        <a
          className="btn btn-orange block flex-1"
          target="_blank"
          rel="noopener noreferrer"
          href={url}>
          <Trans>More Details</Trans>
        </a>
        <Link className="btn block ml-2 flex-1" to={`/trip-registration/${trip_code}`}>
          <Trans>Register</Trans>
        </Link>
      </div>
    </div>
  </div>
);

Advert.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  trip_name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  trip_code: PropTypes.string.isRequired
};

export default Advert;
