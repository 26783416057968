import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const SchoolIndex = props => (
  <div>
    <ResourceIndex
      endpoint="schools"
      canCreate={true}
      model={<Trans>Schools</Trans>}
      translations={[<Trans>School Name</Trans>]}
      headers={["name"]}
      actions={["edit", "delete"]}
      {...props}
    />
  </div>
);

export default SchoolIndex;
