import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import moment from "moment";
import Icon from "./Icon";
import history from "./history";
import LoadingWrapper from "./LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { getEvents, getAllEvents, fetchResources, createResource } from "../api";
import Graph from "../components/Graph";

const colors = ["blue", "green", "indigo", "pink", "orange", "teal", "purple", "yellow"];

class Calendar extends Component {
  static contextType = ApiContext;
  static propTypes = {
    hasAgenda: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired
  };
  static defaultProps = {
    hasAgenda: true,
    readOnly: true
  };
  state = {
    currentMonth: moment(),
    selectedDate: moment(),
    events: [],
    isUpdatingTimesheetId: null,
    name: null,
    staff_id: null,
    isAgenda: true
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchEvents();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentMonth !== this.state.currentMonth) {
      this.fetchEvents();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchEvents = () => {
    const { currentMonth } = this.state;
    const { showAll, readOnly } = this.props;
    this.safeSetState({ error: null, isLoading: true });

    if (!readOnly) {
      const id =
        this.props.match && this.props.match.params.staff_id
          ? this.props.match.params.staff_id
          : this.context.user.userable_id;
      return this.context
        .callApi(() => fetchResources(`/timesheets/${id}/?month=${currentMonth.unix()}`))
        .then(({ data }) => {
          this.safeSetState({
            isLoading: false,
            events: data.data,
            name: data.name,
            staff_id: data.staff_id
          });
        })
        .catch(error => {
          toast.error(<Trans>Failed to fetch events.</Trans>);
          this.safeSetState({ error, isLoading: false });
        });
    }

    this.context
      .callApi(() => (showAll ? getAllEvents(currentMonth.unix()) : getEvents(currentMonth.unix())))
      .then(({ data }) => {
        this.safeSetState({ isLoading: false, events: data });
      })
      .catch(error => {
        toast.error(<Trans>Failed to fetch events.</Trans>);
        this.safeSetState({ error, isLoading: false });
      });
  };

  updateDay = (e, day) => {
    e.persist();
    const payload = {
      date: day.format("YYYY-MM-DD"),
      type: e.target.value,
      staff_id: this.state.staff_id ? this.state.staff_id : this.context.user.userable_id
    };
    this.safeSetState({ isUpdatingTimesheetId: day.unix() });
    this.context
      .callApi(() => createResource("/timesheets", payload))
      .then(({ data }) => {
        this.safeSetState({
          isUpdatingTimesheetId: null,
          events: this.state.events.map(event => {
            if (event.date === day.unix()) {
              return { ...event, type: e.target.value };
            }
            return event;
          })
        });
        toast.success(<Trans>Updated!</Trans>);
      })
      .catch(error => {
        toast.error(<Trans>Failed to fetch events.</Trans>);
        this.safeSetState({ error, isUpdatingTimesheetId: null });
      });
  };

  renderHeader = () => {
    const { isAgenda } = this.state;
    const { hasAgenda } = this.props;
    return (
      <div className="flex items-center justify-between mb-2 bg-white rounded">
        <div className="flex">
          <div className="p-2 cursor-pointer hover:text-grey-dark" onClick={this.prevMonth}>
            <Icon icon="left" />
          </div>
          <div className="p-2 cursor-pointer hover:text-grey-dark" onClick={this.nextMonth}>
            <Icon icon="right" />
          </div>
        </div>
        <div
          className="p-2 uppercase cursor-pointer hover:text-orange"
          onClick={() => this.setState({ currentMonth: moment() })}>
          {moment(this.state.currentMonth).format("L")}
        </div>
        {hasAgenda ? (
          <div className="flex mr-2">
            <span
              className={`cursor-pointer text-xs p-2 hover:text-teal-dark ${
                isAgenda ? "text-teal" : ""
              }`}
              onClick={() => this.setState(prevState => ({ isAgenda: true }))}>
              <Trans>Agenda</Trans>
            </span>
            <span
              className={`cursor-pointer text-xs p-2 hover:text-teal-dark ${
                !isAgenda ? "text-teal" : ""
              }`}
              onClick={() => this.setState(prevState => ({ isAgenda: false }))}>
              <Trans>Calendar</Trans>
            </span>
          </div>
        ) : null}
      </div>
    );
  };

  renderDayNames() {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(
        <th
          className="p-2 text-xs font-light text-center uppercase truncate border-b text-grey"
          key={i}>
          {moment(this.state.currentMonth).startOf("isoWeek").add(i, "days").format("dddd")}
        </th>
      );
    }
    return (
      <thead>
        <tr>{days}</tr>
      </thead>
    );
  }

  generateEvent({ id, name, start_date, end_date }, calendarStart, calendarEnd, day, index) {
    if (moment(day).isBetween(start_date, end_date, null, "[]")) {
      return (
        <div
          key={index}
          onClick={() => history.push(`/trips/${id}`)}
          className={`truncate text-xs mb-1 hover:bg-${colors[index]} cursor-pointer p-1 rounded bg-${colors[index]}-light text-white text-sm`}>
          {name}
        </div>
      );
    }
    return (
      <div key={index} className="p-1 mb-1 text-sm">
        &nbsp;
      </div>
    );
  }

  renderCells = () => {
    const { currentMonth, selectedDate, events, isUpdatingTimesheetId } = this.state;
    const { readOnly } = this.props;
    const monthStart = moment(currentMonth).startOf("month");
    const monthEnd = moment(currentMonth).endOf("month");
    const calendarStart = moment(monthStart).startOf("isoWeek");
    const calendarEnd = moment(monthEnd).endOf("isoWeek");
    const rows = [];

    let days = [];
    let day = calendarStart;
    let formattedDate = "";

    while (day <= calendarEnd) {
      for (let i = 0; i < 7; i++) {
        formattedDate = moment(day).format("D");
        let cellContent;

        if (readOnly) {
          cellContent = (
            <td
              className={`calendar-day ${
                !moment(day).isSame(monthStart, "month")
                  ? "text-grey-light"
                  : moment(day).isSame(selectedDate, "day")
                  ? "bg-blue text-white"
                  : ""
              }`}
              key={day}>
              <div className="p-1 mb-1 text-xs text-right">{formattedDate}</div>
              {/* eslint-disable-next-line no-loop-func */}
              {events.map((event, index) =>
                this.generateEvent(event, calendarStart, calendarEnd, day, index)
              )}
            </td>
          );
        } else {
          let event = events.filter(e => moment(e.date).isSame(day, "day"));

          if (event.length) {
            event = event[0];
          } else {
            event = {
              type: "none",
              date: ""
            };
          }
          cellContent = (
            <TimesheetDay
              key={day}
              isUpdatingTimesheetId={isUpdatingTimesheetId}
              updateDay={this.updateDay}
              day={day}
              event={event}
              monthStart={monthStart}
              selectedDate={selectedDate}
              formattedDate={formattedDate}
            />
          );
        }
        days.push(cellContent);
        day = moment(day).add(1, "days");
      }
      rows.push(
        <tr className="calendar-week" key={day}>
          {days}
        </tr>
      );
      days = [];
    }
    return <tbody>{rows}</tbody>;
  };

  renderAgenda = () => {
    const { events } = this.state;
    return (
      <>
        {!events.length && (
          <div className="p-2 mb-2 bg-white rounded">
            <h3 className="text-sm font-light">
              <Trans>No trips this month.</Trans>
            </h3>
          </div>
        )}
        {events.map((event, index) => {
          const running = moment().isBetween(event.start_date, event.end_date, null, []);
          return (
            <div
              key={index}
              onClick={() => history.push(`/trips/${event.id}`)}
              className={`${
                running ? "bg-blue text-white" : "bg-white"
              } rounded p-2 mb-2 cursor-pointer hover:text-orange`}>
              <h3 className="mb-2 text-base font-light">{event.name}</h3>
              <div className="text-xs text-grey">
                {moment(event.start_date).format("LL")} - {moment(event.end_date).format("LL")}
              </div>
            </div>
          );
        })}
      </>
    );
  };

  nextMonth = () => {
    this.setState(prevState => ({
      currentMonth: moment(prevState.currentMonth).add(1, "months")
    }));
  };

  prevMonth = () => {
    this.setState(prevState => ({
      currentMonth: moment(prevState.currentMonth).subtract(1, "months")
    }));
  };

  parseGraphData = (response, color, title) => {
    let chartData = {
      labels: response.labels,
      datasets: [
        {
          label: title,
          data: response.data,
          fill: true,
          borderColor: `rgb(${color}, .3)`,
          backgroundColor: [
            "rgba(184, 194, 204, 1)",
            "rgba(56, 193, 114, 1)",
            "rgba(101, 116, 205, 1)",
            "rgba(246, 153, 63, 1)",
            "rgba(246, 109, 155, 1)",
            "rgba(77, 192, 181, 1)",
            "rgba(149, 97, 226, 1)",
            "rgba(255, 237, 74)",
          ]
        }
      ],
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                precision: 0
              }
            }
          ]
        }
      }
    };
    return chartData;
  };

  renderGraph = () => {
    const { events } = this.state;
    const office = events.filter(e => e.type === "office").length;
    const trip = events.filter(e => e.type === "trip").length;
    const scouting = events.filter(e => e.type === "scouting").length;
    const prep_day = events.filter(e => e.type === "prep_day").length;
    const travel = events.filter(e => e.type === "travel").length;
    const half_day = events.filter(e => e.type === "half_day").length;
    const sick_day = events.filter(e => e.type === "sick_day").length;
    const holiday = events.filter(e => e.type === "holiday").length;

    const data = {
      labels: ["Office", "Trip", "Scouting", "Prep", "Travel", "Half-Day", "Sick Day", "Holiday"],
      data: [office, trip, scouting, prep_day, travel, half_day, sick_day, holiday]
    };

    const graphData = this.parseGraphData(data, "255,255,255", "Work Breakdown");

    return (
      <div className="p-4 mt-4 bg-white rounded">
        <Graph
          type="bar"
          title={`${this.context.user.locale === "en" ? "Work Breakdown" : "工作分解"}`}
          data={graphData}
        />
      </div>
    );
  };

  render() {
    const { isLoading, isAgenda, events, name } = this.state;
    const { hasAgenda, readOnly } = this.props;
    return (
      <div className="">
        <div className="">
          {this.renderHeader()}
          <LoadingWrapper isLoading={isLoading}>
            {hasAgenda && isAgenda ? (
              this.renderAgenda()
            ) : events ? (
              !readOnly && !events.length ? null : (
                <>
                  {name ? <h1 className="page-header">{name}</h1> : null}
                  <div className="p-2 bg-white rounded">
                    <table className="w-full table-fixed">
                      {this.renderDayNames()}
                      {this.renderCells()}
                    </table>
                  </div>
                  {readOnly ? null : this.renderGraph()}
                </>
              )
            ) : null}
          </LoadingWrapper>
        </div>
      </div>
    );
  }
}

class TimesheetDay extends Component {
  static contextType = ApiContext;
  state = { workType: this.props.event.type };

  render() {
    const {
      day,
      monthStart,
      selectedDate,
      formattedDate,
      updateDay,
      event,
      isUpdatingTimesheetId
    } = this.props;
    const { workType } = this.state;
    const {
      user: { role }
    } = this.context;

    let additionalStyles = "";

    if (!moment(day).isSame(monthStart, "month")) {
      additionalStyles = "text-grey-light";
    }

    if (moment(day).isSame(selectedDate, "day")) {
      additionalStyles = "bg-blue text-white";
    }

    if (workType === "office") {
      additionalStyles = "bg-grey";
    }

    if (workType !== "none") {
      additionalStyles = "text-white ";
      switch (workType) {
        case "office":
          additionalStyles += "bg-grey";
          break;
        case "trip":
          additionalStyles += "bg-green";
          break;
        case "scouting":
          additionalStyles += "bg-indigo";
          break;
        case "prep_day":
          additionalStyles += "bg-orange";
          break;
        case "travel":
          additionalStyles += "bg-pink";
          break;
        case "half_day":
          additionalStyles += "bg-teal";
          break;
        case "sick_day":
          additionalStyles += "bg-purple";
          break;
        case "holiday":
          additionalStyles += "bg-yellow";
          break;
        default:
          break;
      }
    }

    return (
      <td className={`calendar-day ${additionalStyles}`} key={day}>
        <div className="flex items-center p-1 mb-1 text-xs">
          {event.verified_at ? (
            <span
              style={{
                padding: "1px",
                height: 16,
                width: 16,
                fontSize: ".5rem"
              }}
              className="flex items-center justify-center text-xs text-white rounded-full bg-red">
              V
            </span>
          ) : null}{" "}
          <div className="ml-auto">{formattedDate}</div>
        </div>
        <div className="w-full p-2">
          <select
            className="w-full form-input"
            value={workType}
            disabled={
              (role === "staff" && event.verified_at) ||
              event.type === "trip" ||
              isUpdatingTimesheetId === day.unix()
            }
            onChange={e => {
              this.setState({ workType: e.target.value });
              updateDay(e, day);
            }}>
            <option value="none">None</option>
            <option value="office">Office</option>
            <option value="trip" disabled>
              Trip
            </option>
            <option value="prep_day">Prep Day</option>
            <option value="travel">Travel</option>
            <option value="scouting">Scouting</option>
            <option value="half_day">Half-day</option>
            <option value="sick_day">Sick Day</option>
            <option value="holiday">Holiday</option>
          </select>
        </div>
      </td>
    );
  }
}

export default Calendar;
