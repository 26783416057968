import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans, t } from "@lingui/macro";
import _ from "lodash";
import moment from "moment";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { i18n } from "../App";
import Icon from "./Icon";
import DeleteButton from "./DeleteButton";
import GlobalSearch from "./GlobalSearch";
import MediaUpload from "./MediaUpload";
import Gender from "./Gender";
import history from "./history";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, deleteResource } from "../api";

class Table extends Component {
  static contextType = ApiContext;
  static propTypes = {
    items: PropTypes.array.isRequired,
    fetch: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    headers: PropTypes.array.isRequired,
    translations: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    isComponent: PropTypes.bool.isRequired,
    fileUpload: PropTypes.bool.isRequired,
    canCreate: PropTypes.bool.isRequired,
    canSearch: PropTypes.bool.isRequired,
    download: PropTypes.bool.isRequired
  };

  state = {
    orderedItems: this.props.items,
    orderBy: null,
    isUpdatingPermissions: false,
    direction: "asc",
    isUnregisteringParticipant: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleUpload = data => {
    this.safeSetState({
      orderedItems: this.state.orderedItems.concat(data)
    });
  };

  removeFromState = item => {
    toast.success(<Trans>Success!</Trans>);
    this.safeSetState({
      orderedItems: this.state.orderedItems.filter(i => i.id !== item.id)
    });
  };

  orderBy = header => {
    let direction = "asc";

    if (this.state.orderBy === header) {
      direction = this.state.direction === "asc" ? "desc" : "asc";
    }

    this.setState(prevState => ({
      orderedItems: _.orderBy(prevState.orderedItems, header, direction),
      orderBy: header,
      direction
    }));
  };

  updatePermissions = (e, type, id) => {
    let payload = {};
    payload["viewable_by_" + type] = e.target.checked;

    this.safeSetState({ isUpdatingPermissions: true, error: null });
    this.context
      .callApi(() => createResource(`/attachment-permission/${id}`, payload))
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isUpdatingPermissions: false });
      })
      .catch(error => {
        this.safeSetState({ isUpdatingPermissions: false, error: error.errors });
        toast.error(error.message);
      });
  };

  unregisterParticipant = (trip_id, participant_id) => {
    const confirm = window.confirm(i18n._(t`Are you sure you want to remove this participant?`));

    if (!confirm) return;

    this.safeSetState({
      isUnregisteringParticipant: true
    });
    this.context
      .callApi(() => deleteResource(`remove-participant/${trip_id}/${participant_id}`))
      .then(({ data }) => {
        this.safeSetState({
          isUnregisteringParticipant: false
        });
        history.push("/");
        toast.success(<Trans>Removed participant from trip!</Trans>);
      })
      .catch(error => {
        toast.error(<Trans>Failed to remove participant.</Trans>);
        this.safeSetState({ isUnregisteringParticipant: false, error });
      });
  };

  render() {
    const {
      headers,
      translations,
      endpoint,
      model,
      links,
      meta,
      actions,
      goToEdit,
      isComponent,
      fileUpload,
      download,
      canCreate,
      canSearch,
      isUpdatingPermissions,
      queryPayment
    } = this.props;
    const { orderedItems, direction, orderBy, isUnregisteringParticipant } = this.state;
    const { user } = this.context;
    return (
      <div>
        {isComponent ? null : (
          <h1 className="page-header">
            {model}
            {meta && meta.total ? <span className="inline-block ml-2">({meta.total})</span> : null}
          </h1>
        )}
        {(canSearch || fileUpload || canCreate || endpoint === "newsletters") && (
          <div className="flex items-center justify-between mb-2 text-right">
            {canSearch ? (
              <div
                style={{ maxWidth: 240 }}
                className={`${fileUpload || canCreate ? "mr-2" : ""} w-full`}>
                <GlobalSearch
                  goToEdit={goToEdit}
                  url={endpoint}
                  download={download}
                  placeholder={i18n._(t`Search`)}
                />
              </div>
            ) : null}
            {fileUpload ? (
              <div>
                <MediaUpload url="/attachments" onSuccess={this.handleUpload} />
              </div>
            ) : null}
            {canCreate ? (
              <Link to={`/${endpoint}/create`} className="btn btn-solid-blue">
                <Trans>Create</Trans>
              </Link>
            ) : null}
            {endpoint === "newsletters" ? (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="block ml-auto btn btn-solid-teal no-external"
                download
                href={`${
                  process.env.REACT_APP_API_BASE_URL
                }/newsletters/download?token=${localStorage.getItem("INDIER_JWT")}`}>
                <Trans>Download CSV</Trans>
              </a>
            ) : null}
          </div>
        )}
        <div className="overflow-x-auto bg-white rounded shadow custom-scroll custom-scroll-horz">
          <table className="w-full">
            <thead>
              <tr className="bg-grey-lighter">
                {actions.length ? (
                  <th className="px-4 py-2 text-xs font-normal text-left uppercase border-b text-grey border-grey">
                    <Trans>Actions</Trans>
                  </th>
                ) : null}
                {headers.map((title, index) => (
                  <th
                    onClick={() => this.orderBy(title)}
                    key={index}
                    className="px-4 py-2 text-xs font-normal text-left uppercase whitespace-no-wrap border-b cursor-pointer hover:bg-grey-light text-grey-dark border-grey">
                    {translations[index]}
                    {orderBy === title && direction === "asc" && <Icon size={12} icon="up" />}
                    {orderBy === title && direction === "desc" && <Icon size={12} icon="down" />}
                  </th>
                ))}
              </tr>
            </thead>
            <tfoot>
              {links && links.first !== links.last && (
                <tr className="text-xs bg-grey-lightest transition">
                  <td className="px-4 py-2" colSpan={headers.length + (actions.length ? 1 : 0)}>
                    <div className="flex items-center justify-between">
                      {links.first && (
                        <button
                          className="mr-2 text-grey hover:text-grey-dark transition"
                          onClick={() => this.props.fetch(links.first)}>
                          <Trans>First</Trans>
                        </button>
                      )}
                      <div>
                        {links.prev && (
                          <button
                            className="mr-2 text-grey hover:text-grey-dark transition"
                            onClick={() => this.props.fetch(links.prev)}>
                            <Trans>Prev</Trans>
                          </button>
                        )}
                        {links.next && (
                          <button
                            className="mr-2 text-grey hover:text-grey-dark transition"
                            onClick={() => this.props.fetch(links.next)}>
                            <Trans>Next</Trans>
                          </button>
                        )}
                      </div>
                      {links.last && (
                        <button
                          className="mr-2 text-grey hover:text-grey-dark transition"
                          onClick={() => this.props.fetch(links.last)}>
                          <Trans>Last</Trans>
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              )}
              {meta ? (
                <tr className="text-xs">
                  <td className="px-4 py-2" colSpan={headers.length - (actions.length ? 0 : 1)}>
                    {meta && meta.from && (
                      <>
                        {user.locale === "en"
                          ? `Showing ${meta.from}-${meta.to} of ${meta.total}`
                          : `显示${meta.from}-${meta.to}, 共${meta.total}`}
                      </>
                    )}
                  </td>
                  <td className="px-4 py-2 text-right whitespace-no-wrap">
                    {user.locale === "en"
                      ? `Page ${meta.current_page} of ${meta.last_page}`
                      : `第${meta.current_page}页，共${meta.last_page}页`}
                  </td>
                </tr>
              ) : null}
            </tfoot>
            <tbody>
              {!orderedItems.length && (
                <tr>
                  <td
                    className="px-4 py-3 text-sm whitespace-no-wrap border-b border-grey-light"
                    colSpan={headers.length + (actions.length ? 1 : 0)}>
                    <Trans>No items.</Trans>
                  </td>
                </tr>
              )}
              {orderedItems.map((item, index) => (
                <tr key={index} className="hover:bg-grey-lightest">
                  {actions.length ? (
                    <td className="px-4 py-3 text-sm text-left whitespace-no-wrap border-b border-grey-light">
                      {actions.includes("download") && (
                        <a
                          rel="noopener noreferrer"
                          className="mr-2 no-underline text-grey hover:text-blue"
                          href={`${process.env.REACT_APP_API_BASE_URL}/attachments/${
                            item.id
                          }?token=${localStorage.getItem("INDIER_JWT")}`}>
                          <Icon icon="download" size={16} />
                        </a>
                      )}
                      {actions.includes("risk_assessment") && (
                        <>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-2 no-underline no-external text-grey hover:text-blue"
                            href={`${process.env.REACT_APP_API_BASE_URL}/risk_assessments/${
                              item.id
                            }/pdf?token=${localStorage.getItem("INDIER_JWT")}`}>
                            <Icon icon="download" size={16} /> (EN)
                          </a>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            className="mr-2 no-underline no-external text-grey hover:text-blue"
                            href={`${process.env.REACT_APP_API_BASE_URL}/risk_assessments/${
                              item.id
                            }/pdf?lang=zh&token=${localStorage.getItem("INDIER_JWT")}`}>
                            <Icon icon="download" size={16} /> (中文)
                          </a>
                          {canCreate && (
                            <Link
                              className="mr-2 no-underline text-grey hover:text-blue"
                              to={`/${endpoint}/create/${item.id}`}>
                              <Icon icon="copy" size={16} />
                            </Link>
                          )}
                        </>
                      )}
                      {actions.includes("show") && (
                        <Link
                          className="mr-2 no-underline text-grey hover:text-blue"
                          to={`/${endpoint}/${item.slug ? item.slug : item.id}`}>
                          <Icon icon="show" size={16} />
                        </Link>
                      )}
                      {actions.includes("edit") && (
                        <Link
                          className="mr-2 no-underline text-grey hover:text-teal"
                          to={`/${endpoint}/${item.id}/edit`}>
                          <Icon icon="edit" size={16} />
                        </Link>
                      )}
                      {actions.includes("unregister") && (
                        <button
                          disabled={isUnregisteringParticipant}
                          className="mr-2 no-underline text-grey hover:text-red"
                          onClick={() => this.unregisterParticipant(item.id, item.participant_id)}>
                          <Icon icon="close-outline" size={16} />
                        </button>
                      )}
                      {actions.includes("unarchive") && (
                        <DeleteButton
                          action="restore"
                          url={`/${endpoint}/${item.id}`}
                          onDelete={() => this.removeFromState(item)}
                          className="mr-2 cursor-pointer transition text-grey hover:text-orange">
                          <Icon icon="archive" size={16} />
                        </DeleteButton>
                      )}
                      {actions.includes("archive") && (
                        <DeleteButton
                          action="archive"
                          url={`/archived-${endpoint}/${item.id}`}
                          onDelete={() => this.removeFromState(item)}
                          className="mr-2 cursor-pointer transition text-grey hover:text-orange">
                          <Icon icon="archive" size={16} />
                        </DeleteButton>
                      )}
                      {actions.includes("delete") && ["admin", "manager"].includes(user.role) && (
                        <DeleteButton
                          url={`/${endpoint}/${item.id}`}
                          onDelete={() => this.removeFromState(item)}
                          className="cursor-pointer transition text-grey hover:text-red">
                          <Icon icon="trash" size={16} />
                        </DeleteButton>
                      )}
                      {actions.includes("query") && (
                        <button
                          onClick={() => queryPayment(item.id)}
                          className="mr-2 text-grey hover:text-teal">
                          <Trans>Query</Trans>
                        </button>
                      )}
                    </td>
                  ) : null}
                  {headers.map((header, index) => (
                    <td
                      key={index + orderedItems.length}
                      className="px-4 py-3 text-sm whitespace-no-wrap border-b border-grey-light">
                      {header === "email" ? (
                        <a href={`mailto:${item[header]}`}>{item[header]}</a>
                      ) : header === "avatar" ? (
                        item.avatar ? (
                          <img
                            className="ml-0 nav-avatar"
                            src={item.avatar.replace(".jpg", "-thumb.jpg")}
                            alt=""
                          />
                        ) : (
                          <Icon icon="user" size={24} />
                        )
                      ) : header === "trip_leader_name" ? (
                        item.trip_leader_name ? (
                          item.trip_leader_name
                        ) : (
                          <Trans>None selected</Trans>
                        )
                      ) : header === "registered" ? (
                        <>
                          {item.total_registrations}/{item.expected_participants}
                        </>
                      ) : header === "paid" ? (
                        <>
                          {item.total_payments}/{item.expected_participants}
                        </>
                      ) : header === "published_at" ? (
                        item.published_at ? (
                          moment.utc(item.published_at).format("LL")
                        ) : (
                          <Trans>Not published</Trans>
                        )
                      ) : header === "full_name" ? (
                        <>
                          <Gender gender={item.gender} />
                          {item.full_name}
                        </>
                      ) : header === "created_at" ? (
                        moment.utc(item.start_date).format("LL")
                      ) : header === "start_date" ? (
                        moment.utc(item.start_date).format("LL")
                      ) : header === "end_date" ? (
                        moment.utc(item.end_date).format("LL")
                      ) : header === "phone" ? (
                        item.phone ? (
                          <a href={`tel:${item.phone}`}>{item.phone}</a>
                        ) : (
                          <Trans>None</Trans>
                        )
                      ) : header === "location" ? (
                        item.location_trans && user.locale !== "en" ? (
                          item.location_trans
                        ) : (
                          item.location
                        )
                      ) : header === "participant_name" ? (
                        item.participant_name_pinyin && user.locale === "en" ? (
                          item.participant_name_pinyin
                        ) : (
                          item.participant_name
                        )
                      ) : header === "payment_type" ? (
                        <span
                          className={`text-${
                            item.payment_type === "cash"
                              ? "orange"
                              : item.payment_type === "alipay"
                              ? "blue"
                              : "green"
                          }`}>
                          <Icon icon={item.payment_type} size={24} />
                        </span>
                      ) : header === "status" ? (
                        item.payment_status !== "unpaid" ? (
                          <Trans>Paid</Trans>
                        ) : (
                          <span className="font-bold text-red">
                            <Trans>Unpaid</Trans>
                            <Link
                              className="p-1 ml-2 mr-2 text-sm text-white no-underline rounded bg-green hover:bg-green-dark hover:text-white"
                              to={`/pay/${item.slug ? item.slug : item.id}/${item.participant_id}`}>
                              <Trans>Pay Now</Trans>
                            </Link>
                          </span>
                        )
                      ) : header === "viewable_by" ? (
                        <>
                          {user.role === "admin" || user.role === "manager" ? (
                            <label className="mr-2 cursor-pointer">
                              <Trans>Staff</Trans>{" "}
                              <input
                                type="checkbox"
                                defaultChecked={item.viewable_by_staff}
                                onClick={e => this.updatePermissions(e, "staff", item.id)}
                                disabled={isUpdatingPermissions}
                              />
                            </label>
                          ) : null}
                          <label className="mr-2 cursor-pointer">
                            <Trans>Teacher</Trans>{" "}
                            <input
                              type="checkbox"
                              defaultChecked={item.viewable_by_teacher}
                              onClick={e => this.updatePermissions(e, "teacher", item.id)}
                              disabled={isUpdatingPermissions}
                            />
                          </label>
                          <label className="cursor-pointer">
                            <Trans>Client</Trans>{" "}
                            <input
                              type="checkbox"
                              defaultChecked={item.viewable_by_client}
                              onClick={e => this.updatePermissions(e, "client", item.id)}
                              disabled={isUpdatingPermissions}
                            />
                          </label>
                        </>
                      ) : (
                        item[header]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Table;
