import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import history from "../components/history";
import PasswordInput from "../components/PasswordInput";
import { updateResource as updatePassword } from "../api";
import { ApiContext } from "../contexts/ApiContext";

class Register extends Component {
  static contextType = ApiContext;

  state =
    process.env.NODE_ENV === "development"
      ? {
          current_password: "dc.com",
          password: "dc.com",
          password_confirmation: "dc.com",
          isLoading: false,
          error: null
        }
      : {
          current_password: "",
          password: "",
          password_confirmation: "",
          isLoading: false,
          error: null
        };

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  changePassword = e => {
    e.preventDefault();
    const { current_password, password, password_confirmation } = this.state;
    if (!current_password || !password || !password_confirmation) return;
    const { callApi } = this.context;
    this.safeSetState({ isLoading: true });
    callApi(() => updatePassword("change-password", this.state))
      .then(res => {
        this.safeSetState({ isLoading: false });
        history.push("/");
        toast.success(<Trans>Password changed!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error: error.errors });
        toast.error(<Trans>Failed to change password.</Trans>);
      });
  };

  render() {
    const { isLoading, password, password_confirmation, current_password, error } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Change Password</Trans>
            </h1>
            <div className="form-container">
              <form method="POST" onSubmit={this.changePassword}>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Current Password</Trans>
                  </label>
                  <div className="w-full">
                    <PasswordInput
                      value={current_password}
                      onChange={e => this.setState({ current_password: e.target.value })}
                      autoComplete="current-password"
                      disabled={isLoading}
                      className="form-input w-full"
                    />
                    {error && error.current_password && (
                      <span className="mt-2 inline-block text-xs text-red">
                        {error.current_password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>New Password</Trans>
                  </label>
                  <div className="w-full">
                    <PasswordInput
                      value={password}
                      onChange={e => this.setState({ password: e.target.value })}
                      autoComplete="new-password"
                      disabled={isLoading}
                      className="form-input w-full"
                    />
                    {error && error.password && (
                      <span className="mt-2 inline-block text-xs text-red">{error.password}</span>
                    )}
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>New Password Confirmation</Trans>
                  </label>
                  <div className="w-full">
                    <PasswordInput
                      value={password_confirmation}
                      onChange={e =>
                        this.setState({
                          password_confirmation: e.target.value
                        })
                      }
                      autoComplete="new-password"
                      disabled={isLoading}
                      className="form-input w-full"
                    />
                    {error && error.password_confirmation && (
                      <span className="mt-2 inline-block text-xs text-red">
                        {error.password_confirmation}
                      </span>
                    )}
                  </div>
                </div>

                <div className="form-actions">
                  <button type="submit" disabled={isLoading} className="btn btn-solid-teal w-full">
                    {isLoading ? <Trans>Saving Password...</Trans> : <Trans>Change Password</Trans>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
