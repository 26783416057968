import React from "react";
import MedicalConditionForm from "./MedicalConditionForm";

const LocationCreate = props => (
  <div>
    <MedicalConditionForm isCreate={true} {...props} endpoint="locations" model="Location" />
  </div>
);

export default LocationCreate;
