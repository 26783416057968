import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { i18n } from "../App";
import Icon from "./Icon";
import GlobalSearch from "./GlobalSearch";
import { ApiContext } from "../contexts/ApiContext";

class TopNav extends Component {
  static contextType = ApiContext;
  state = { showMenu: false };

  showMenu = e => {
    e.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.hideMenu);
    });
  };

  hideMenu = e => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.hideMenu);
    });
  };

  render() {
    const { user, logout } = this.context;
    const { showMenu } = this.state;
    return (
      <div
        className="fixed flex items-center justify-between p-2 shadow top-nav main-container z-5 pin-x"
        style={
          process.env.NODE_ENV === "development"
            ? {
                backgroundColor: "rgba(255,0,0,.8)",
                color: "white"
              }
            : { backgroundColor: "rgba(255,255,255,.8)" }
        }>
        {user.type === "Staff" ? (
          <div className="mr-2 border rounded">
            <GlobalSearch
              goToEdit={false}
              url="search"
              placeholder={i18n._(t`Search everything`)}
            />
          </div>
        ) : (
          <div />
        )}
        <div onClick={this.showMenu} className="relative flex items-center">
          <div className="mr-2 cursor-pointer hover:text-teal transition">
            {user.avatar ? (
              <img className="nav-avatar" src={user.avatar} alt="" />
            ) : (
              <Icon icon="user" size={28} />
            )}
          </div>
          <div className="flex items-center text-sm cursor-pointer hover:text-teal transition">
            <span className="hidden mr-1 sm:block">{user.full_name}</span>
            <Icon icon="down" size={16} />
          </div>

          {showMenu && (
            <div
              className="absolute flex flex-col items-start justify-center rounded-b shadow pin-r"
              style={{
                top: 39,
                minWidth: 140,
                backgroundColor: "rgba(255,255,255,.8)"
              }}
              ref={element => {
                this.dropdownMenu = element;
              }}>
              <NavLink
                className="block w-full p-4 font-light no-underline text-grey-dark hover:text-orange"
                exact={true}
                to="/profile">
                <Trans>Profile</Trans>
              </NavLink>
              <span
                className="block w-full p-4 cursor-pointer text-grey-dark hover:text-orange"
                onClick={logout}>
                <Trans>Logout</Trans>
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TopNav;
