import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";
import { ApiConsumer } from "../contexts/ApiContext";

const PaymentIndex = props => (
  <ApiConsumer>
    {({ user }) => (
      <div>
        <ResourceIndex
          endpoint="payments"
          model={<Trans>Payments</Trans>}
          headers={["full_name", "trip_name", "school_name", "amount", "payment_type", "paid_at"]}
          translations={[
            <Trans>Name</Trans>,
            <Trans>Trip</Trans>,
            <Trans>School</Trans>,
            <Trans>Amount</Trans>,
            <Trans>Payment Type</Trans>,
            <Trans>Paid At</Trans>
          ]}
          {...props}
          actions={user.role === "admin" ? ["query"] : []}
        />
      </div>
    )}
  </ApiConsumer>
);

export default PaymentIndex;
