import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { ApiContext } from "../contexts/ApiContext";
import { requestVerificationEmail as sendEmail } from "../api";

class VerifyEmailNotification extends Component {
  static contextType = ApiContext;
  static propTypes = {
    email: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
  };
  state = { isSending: false };

  requestVerificationEmail = () => {
    const { callApi } = this.context;

    this.setState({ isSending: true });

    callApi(() => sendEmail({ email: this.props.email }))
      .then(res => {
        toast.success(res.data.message, { autoClose: false });
        this.setState({ isSending: false });
      })
      .catch(e => {
        console.warn("%cSend verify email failed", "color: #f6993f; font-weight: bold", e);
        const msg =
          typeof e === "string" ? (
            e
          ) : typeof e.message === "string" ? (
            e.message
          ) : (
            <Trans>Failed to send verification email.</Trans>
          );
        toast.error(msg);
        this.setState({ isSending: false });
      });
  };

  render() {
    const { show } = this.props;
    const { isSending } = this.state;

    return (
      <>
        {show && (
          <div className="verify-email-notification">
            {isSending ? (
              <Trans>Sending email...</Trans>
            ) : (
              <Trans>
                You must verify your email before logging in. Click{" "}
                <button
                  onClick={this.requestVerificationEmail}
                  className="underline text-white hover:text-orange-darker">
                  here
                </button>{" "}
                to request another verification email.
              </Trans>
            )}
          </div>
        )}
      </>
    );
  }
}

export default VerifyEmailNotification;
