import React, { Component } from "react";
import { Trans } from "@lingui/macro";

class TripOverview extends Component {
  render() {
    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Trips Overview</Trans>
            </h1>
            <p>
              <Trans>Content coming soon!</Trans>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default TripOverview;
