import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const LocationIndex = props => (
  <div>
    <ResourceIndex
      {...props}
      endpoint="locations"
      actions={["edit", "show", "delete"]}
      canCreate={true}
      model={<Trans>Locations</Trans>}
      headers={["name", "translation"]}
      translations={[<Trans>Location Name</Trans>, <Trans>Translation</Trans>]}
    />
  </div>
);

export default LocationIndex;
