import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
// import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
// import { i18n } from "../App";
// import DeleteButton from "../components/DeleteButton";
import MediaUpload from "../components/MediaUpload";
// import Wysiwyg from "../components/Wysiwyg";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";

class ClientTeacherProfile extends Component {
  static contextType = ApiContext;
  state = {
    email: "",
    avatar: null,
    family_name: "",
    given_names: "",
    phone: "",
    account_id: "",
    user_id: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleImageUpload = data => {
    this.safeSetState({ avatar: `${data}?${new Date().getTime()}` });
    this.context.setApiState("user", {
      ...this.context.user,
      avatar: `${data}?${new Date().getTime()}`
    });
  };

  fetchItem = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoading: true, error: null });
    callApi(() => fetchResources("profile"))
      .then(({ data }) => {
        this.safeSetState({
          avatar: data.avatar ? `${data.avatar}?${new Date().getTime()}` : null,
          email: data.email,
          family_name: data.family_name || "",
          given_names: data.given_names,
          phone: data.phone,
          account_id: data.account_id,
          user_id: data.user_id,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleUpdate = e => {
    e.preventDefault();
    const { callApi } = this.context;
    const { email, family_name, given_names, phone } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    callApi(() =>
      updateResource(`/profile`, {
        email,
        family_name,
        given_names,
        phone
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  render() {
    const {
      email,
      avatar,
      family_name,
      given_names,
      phone,
      user_id,
      error,
      isSubmitting,
      isLoading
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Profile</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} retry={this.fetchItem}>
              <div className="form-container">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Info</Trans>
                </div>

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Avatar</Trans>
                  </label>
                  <div className="w-full">
                    {avatar ? (
                      <div>
                        <img className="profile-avatar" src={avatar} alt="Staff" />
                      </div>
                    ) : (
                      <div className="text-grey mb-4">
                        <Icon icon="user-square" size={300} />
                      </div>
                    )}
                    <MediaUpload
                      onSuccess={this.handleImageUpload}
                      url={`/avatar/${user_id}`}
                      accept="image/*"
                    />
                    {error && error.avatar && (
                      <span className="text-red text-xs">{error.avatar}</span>
                    )}
                  </div>
                </div>
                <form method="POST" onSubmit={this.handleUpdate}>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Family Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={family_name}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            family_name: e.target.value
                          })
                        }
                      />
                      {error && error.family_name && (
                        <span className="text-red text-xs">{error.family_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Given Names</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={given_names}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            given_names: e.target.value
                          })
                        }
                      />
                      {error && error.given_names && (
                        <span className="text-red text-xs">{error.given_names}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Email</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={email}
                        disabled
                        onChange={e => this.safeSetState({ email: e.target.value })}
                      />
                      {error && error.email && (
                        <span className="text-red text-xs">{error.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone Number</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        value={phone}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e => this.safeSetState({ phone: e.target.value })}
                      />
                      {error && error.phone && (
                        <span className="text-red text-xs">{error.phone}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-actions">
                    <div />
                    {/* <DeleteButton */}
                    {/*     url={`staffs/${staff_id}`} */}
                    {/*     onDelete={this.handleDelete}> */}
                    {/*     <button type="button" className="btn btn-solid-red"> */}
                    {/*         <Trans>Delete</Trans> */}
                    {/*     </button> */}
                    {/* </DeleteButton> */}
                    {/* <Link */}
                    {/*     className="btn btn-solid-teal" */}
                    {/*     to={`/staffs/${staff_id}`}> */}
                    {/*     <Trans>View</Trans> */}
                    {/* </Link> */}
                    <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                      {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                    </button>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ClientTeacherProfile;
