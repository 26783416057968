import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import history from "../components/history";
import { ApiContext } from "../contexts/ApiContext";
import { createResource } from "../api";

class StaffCreateForm extends Component {
  static contextType = ApiContext;
  state = {
    family_name: "",
    given_names: "",
    email: "",
    job_title: "",
    role: "staff",
    roles: {
      manager: { name: "Manager", trans: "主管" },
      team_leader: { name: "Team Leader", trans: "小组主管" },
      staff: { name: "Staff", trans: "员工" },
      teacher: { name: "Teacher", trans: "教师" },
      client: { name: "Client", trans: "客户" }
    },
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleCreate = e => {
    e.preventDefault();
    const { email, family_name, given_names, job_title, role } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() =>
        createResource(`/staffs`, { email, family_name, given_names, job_title, role })
      )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
        history.push(`/staffs/${res.data.staff_id}/edit`);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  render() {
    const { locale } = this.context.user;
    const {
      family_name,
      given_names,
      email,
      job_title,
      role,
      error,
      isSubmitting,
      roles
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Create Staff</Trans>
            </h1>
            <div className="form-container">
              <form method="POST" onSubmit={this.handleCreate}>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Family Name</Trans>
                  </label>
                  <div className="w-full">
                    <input
                      className="form-input w-full"
                      type="text"
                      value={family_name}
                      disabled={isSubmitting}
                      onChange={e => this.safeSetState({ family_name: e.target.value })}
                    />
                    {error && error.family_name && (
                      <span className="text-red text-xs">{error.family_name}</span>
                    )}
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Given Names</Trans>
                  </label>
                  <div className="w-full">
                    <input
                      className="form-input w-full"
                      type="text"
                      value={given_names}
                      disabled={isSubmitting}
                      onChange={e => this.safeSetState({ given_names: e.target.value })}
                    />
                    {error && error.given_names && (
                      <span className="text-red text-xs">{error.given_names}</span>
                    )}
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Email</Trans>
                  </label>
                  <div className="w-full">
                    <input
                      className="form-input w-full"
                      type="text"
                      value={email}
                      disabled={isSubmitting}
                      onChange={e => this.safeSetState({ email: e.target.value })}
                    />
                    {error && error.email && (
                      <span className="text-red text-xs">{error.email}</span>
                    )}
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Job Title</Trans>
                  </label>
                  <div className="w-full">
                    <input
                      className="form-input w-full"
                      type="text"
                      value={job_title}
                      disabled={isSubmitting}
                      onChange={e => this.safeSetState({ job_title: e.target.value })}
                    />
                    {error && error.job_title && (
                      <span className="text-red text-xs">{error.job_title}</span>
                    )}
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Role (permissions)</Trans>
                  </label>
                  <div className="w-full">
                    <select
                      value={role}
                      disabled={isSubmitting}
                      className="form-input w-full"
                      onChange={e => this.safeSetState({ role: e.target.value })}>
                      {Object.keys(roles).map((role, index) => (
                        <option key={index} value={role}>
                          {locale === "en" ? roles[role].name : roles[role].trans}
                        </option>
                      ))}
                    </select>
                    {error && error.role && <span className="text-red text-xs">{error.role}</span>}
                  </div>
                </div>
                <div className="form-actions">
                  <Link className="btn btn-solid-red" to={`/staffs`}>
                    <Trans>Cancel</Trans>
                  </Link>
                  <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                    {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffCreateForm;
