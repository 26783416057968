import React from "react";
import ResourceShow from "./ResourceShow";

const SkillEdit = props => (
  <div>
    <ResourceShow {...props} endpoint="locations" model="Location" />
  </div>
);

export default SkillEdit;
