import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import PasswordInput from "../../components/PasswordInput";
import history from "../../components/history";
import { resetPassword as attemptResetPassword } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import logo from "../../assets/logo-sm.png";

class Register extends Component {
  static contextType = ApiContext;

  state =
    process.env.NODE_ENV === "development"
      ? {
          token: "",
          password: "dc.com",
          password_confirmation: "dc.com",
          isLoading: false
        }
      : {
          token: "",
          password: "",
          password_confirmation: "",
          isLoading: false
        };

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  componentDidMount() {
    this._isMounted = true;
    const { token } = this.props.match.params;
    this.safeSetState({ token });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  resetPassword = e => {
    e.preventDefault();
    const { callApi } = this.context;
    this.safeSetState({ isLoading: true });
    callApi(() => attemptResetPassword(this.state))
      .then(res => {
        toast.success(<Trans>Password reset success!</Trans>);
        this.safeSetState({ isLoading: false });

        history.push("/login");
      })
      .catch(e => {
        const msg = e[Object.keys(e)[0]];
        this.safeSetState({ isLoading: false, error: e.errors });
        toast.error(<Trans>Failed to send email. {msg}</Trans>);
      });
  };

  render() {
    const { isLoading } = this.context;
    const { password, password_confirmation, error } = this.state;

    return (
      <div className="full-screen-form-container">
        <div className="full-screen-form">
          <div className="text-center w-full">
            <img className="mb-8" src={logo} alt="" style={{ maxWidth: 200 }} />
          </div>
          <div>
            <form method="POST" onSubmit={this.resetPassword} className="form-no-flex">
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>New Password</Trans>
                </label>
                <PasswordInput
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                  disabled={isLoading}
                  className="form-input w-full"
                />
                {error && error.password && (
                  <span className="mt-2 inline-block text-xs text-red">{error.password}</span>
                )}
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>New Password Confirmation</Trans>
                </label>
                <PasswordInput
                  value={password_confirmation}
                  onChange={e => this.setState({ password_confirmation: e.target.value })}
                  disabled={isLoading}
                  className="form-input w-full"
                />
              </div>
              <button type="submit" disabled={isLoading} className="btn w-full mb-2">
                {isLoading ? <Trans>Resetting Password...</Trans> : <Trans>Reset Password</Trans>}
              </button>
              <div className="text-right text-sm">
                <Trans>Token expired?</Trans>{" "}
                <Link to="/forgot">
                  <Trans>Request a new token</Trans>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Register;
