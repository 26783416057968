import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import release from "../RELEASE_NOTES.md";
import LoadingWrapper from "../components/LoadingWrapper";
import Markdown from "react-markdown";

class WhatsNew extends Component {
  state = { content: null };

  getContent = () => {
    fetch(release)
      .then(r => r.text())
      .then(t =>
        this.safeSetState({
          content: t
        })
      );
  };

  componentDidMount() {
    this._isMounted = true;
    this.getContent();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  render() {
    const { content } = this.state;
    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>What's New?</Trans>
            </h1>
            <LoadingWrapper isLoading={!content}>
              <div className="bg-white rounded static-content p-8">
                <Markdown source={content} />
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default WhatsNew;
