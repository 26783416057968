import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import Icon from "../components/Icon";
import LoadingWrapper from "../components//LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { getTimesheets, updateTimesheet } from "../api";
import { i18n } from "../App";

class TimesheetIndex extends Component {
  static contextType = ApiContext;
  state = {
    currentMonth: moment(),
    timesheets: [],
    isLoading: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchTimesheets();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentMonth !== this.state.currentMonth) {
      this.fetchTimesheets();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchTimesheets = () => {
    const { currentMonth } = this.state;
    this.safeSetState({ error: null, isLoading: true });
    this.context
      .callApi(() => getTimesheets(currentMonth.unix()))
      .then(({ data }) => {
        this.safeSetState({ isLoading: false, timesheets: data });
      })
      .catch(error => {
        toast.error(<Trans>Failed to fetch items.</Trans>);
        this.safeSetState({ error, isLoading: false });
      });
  };

  renderHeader = () => {
    return (
      <div className="flex items-center justify-between mb-2 bg-white rounded">
        <div className="flex">
          <div className="p-2 cursor-pointer hover:text-grey-dark" onClick={this.prevMonth}>
            <Icon icon="left" />
          </div>
          <div className="p-2 cursor-pointer hover:text-grey-dark" onClick={this.nextMonth}>
            <Icon icon="right" />
          </div>
        </div>
        <div
          className="p-2 uppercase cursor-pointer hover:text-orange"
          onClick={() => this.setState({ currentMonth: moment() })}>
          {moment(this.state.currentMonth).format("MMMM YYYY")}
        </div>
      </div>
    );
  };

  nextMonth = () => {
    this.setState(prevState => ({
      currentMonth: moment(prevState.currentMonth).add(1, "months")
    }));
  };

  prevMonth = () => {
    this.setState(prevState => ({
      currentMonth: moment(prevState.currentMonth).subtract(1, "months")
    }));
  };

  render() {
    const { isLoading, timesheets, currentMonth } = this.state;
    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            {this.renderHeader()}
            <LoadingWrapper minHeight={200} isLoading={isLoading}>
              <h1 className="page-header">
                <Trans>Timesheets</Trans>
              </h1>
              <div className="form-container">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Staff</Trans>
                </div>
                {!timesheets.length && (
                  <div className="form-input-group">
                    <div className="flex w-full">
                      <Trans>None</Trans>
                    </div>
                  </div>
                )}
                {timesheets.map((t, index) => (
                  <TimesheetEntry month={currentMonth} key={index} timesheet={t} />
                ))}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

class TimesheetEntry extends Component {
  static contextType = ApiContext;
  static propTypes = {
    timesheet: PropTypes.object.isRequired
  };
  state = {
    verified: this.props.timesheet.verified,
    isVerifying: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  verifyTimesheet = (staff_id, month) => {
    const confirm = window.confirm(
      i18n._(
        t`Are you sure you want to verify this month's timesheet (staff will no longer be able to manually update)?`
      )
    );

    if (!confirm) {
      return;
    }

    this.safeSetState({ isVerifying: true });
    this.context
      .callApi(() => updateTimesheet({ staff_id, month }))
      .then(({ data }) => {
        this.safeSetState({ isVerifying: false, verified: true });
        toast.success(<Trans>Timesheet verified.</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isVerifying: false, error });
        toast.error(<Trans>Failed to update timesheet.</Trans>);
      });
  };

  render() {
    const { timesheet, month } = this.props;
    const { verified, isVerifying } = this.state;

    return (
      <div className="form-input-group">
        <label className="form-label">
          <div className="flex items-center">
            {verified ? (
              <span
                style={{
                  padding: "2px",
                  height: 20,
                  width: 20
                }}
                className="flex items-center justify-center mr-2 text-xs text-white rounded-full bg-red">
                V
              </span>
            ) : null}
            {timesheet.full_name}
          </div>
        </label>
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-col flex-wrap w-full md:flex-row">
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-green lg:mb-0 md:mr-2">
              <Trans>Trip</Trans>: {timesheet.trip}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-orange lg:mb-0 md:mr-2">
              <Trans>Prep</Trans>: {timesheet.prep_day}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-indigo lg:mb-0 md:mr-2">
              <Trans>Scouting</Trans>: {timesheet.scouting}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-grey lg:mb-0 md:mr-2">
              <Trans>Office</Trans>: {timesheet.office}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-pink lg:mb-0 md:mr-2">
              <Trans>Travel</Trans>: {timesheet.travel}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-teal lg:mb-0 md:mr-2">
              <Trans>Half-day</Trans>: {timesheet.half_day}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-purple lg:mb-0 md:mr-2">
              <Trans>Sick Day</Trans>: {timesheet.sick_day}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs text-white rounded bg-yellow lg:mb-0 md:mr-2">
              <Trans>Holiday</Trans>: {timesheet.holiday}
            </span>
            <span
              style={{ minWidth: 80 }}
              className="flex items-center p-1 mb-2 text-xs bg-white border rounded text-blue-darker border-blue-darker lg:mb-0 md:mr-2">
              <Trans>Total</Trans>: {timesheet.total}
            </span>
          </div>
          <div className="flex flex-col md:flex-row">
            {verified ? null : (
              <button
                disabled={isVerifying}
                className="inline-block p-1 mr-2 md:p-0 hover:text-red text-grey"
                onClick={() => this.verifyTimesheet(timesheet.staff_id, month.unix())}>
                <Icon icon="tick" />
              </button>
            )}
            <Link
              className="inline-block p-1 mb-2 md:p-0 md:mb-0 text-grey hover:text-blue"
              to={`timesheets/${timesheet.staff_id}`}>
              <Icon icon="edit" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default TimesheetIndex;
