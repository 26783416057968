import React, { Component } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import MediaUpload from "../components/MediaUpload";
import { submitFeedback, fetchResources } from "../api";
import { i18n } from "../App";

class FeedbackForm extends Component {
  static contextType = ApiContext;
  state = {
    title: "",
    severity: "low",
    email: this.context.user ? this.context.user.email : "",
    browser: "Chrome",
    expected: "",
    steps: "",
    actual: "",
    image_id: null,
    description: "",
    trip_id: "",
    trips: [],
    url: "",
    file: null,
    type: "trip",
    imagePreviewUrl: null,
    user_agent: navigator.userAgent,
    platform: navigator.platform,
    isLoading: false,
    isSubmitting: false,
    isFetchingTrips: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchTrips();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchTrips = () => {
    this.safeSetState({ isFetchingTrips: true });
    this.context
      .callApi(() => fetchResources("my-trips"))
      .then(({ data }) => {
        this.safeSetState({
          trips: data.data,
          isFetchingTrips: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isFetchingTrips: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch trips.</Trans>);
        }
        toast.error(error);
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    let {
      title,
      severity,
      type,
      email,
      expected,
      actual,
      image_id,
      description,
      trip_id,
      user_agent,
      browser,
      steps,
      platform
    } = this.state;

    this.safeSetState({ isSubmitting: true });

    let data = {
      title,
      user_agent,
      steps,
      actual,
      expected,
      browser,
      platform,
      description,
      severity,
      image_id,
      trip_id,
      type,
      email
    };

    this.context
      .callApi(() => submitFeedback(data))
      .then(() => {
        this.safeSetState({
          title: "",
          severity: "low",
          browser: "Chrome",
          description: "",
          trip_id: "",
          actual: "",
          expected: "",
          steps: "",
          url: "",
          file: null,
          imagePreviewUrl: null,
          image_id: null,
          isSubmitting: false,
          error: null
        });

        toast.success(<Trans>Thanks for getting in touch!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create issue, oh the irony...</Trans>);
      });
  };

  handleImageChange = (data, file) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      this.safeSetState({
        image_id: data.id,
        imagePreviewUrl: reader.result
      });
    };
    reader.readAsDataURL(file);
  };

  render() {
    let {
      title,
      severity,
      actual,
      expected,
      steps,
      url,
      imagePreviewUrl,
      type,
      trip_id,
      trips,
      email,
      browser,
      isSubmitting,
      user_agent,
      isFetchingTrips,
      platform,
      error,
      description
    } = this.state;

    let imagePreview;
    if (imagePreviewUrl) {
      imagePreview = <img src={imagePreviewUrl} alt="" />;
    } else {
      imagePreview = (
        <div className="previewText">
          <Trans>Please select an Image for Preview</Trans>
        </div>
      );
    }

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8" style={{ maxWidth: 700 }}>
          <div>
            <h1 className="page-header">
              <Trans>Submit Feedback</Trans>
            </h1>
            <div className="form-container">
              <form action="#" onSubmit={this.handleSubmit}>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Feedback Type</Trans>
                  </label>

                  <div className="flex">
                    <div className="px-4">
                      <label className="text-sm text-grey-dark inline-block cursor-pointer">
                        <Trans>Trip</Trans>
                        <input
                          style={{
                            MozAppearance: "radio",
                            WebkitAppearance: "radio",
                            appearance: "initial"
                          }}
                          className="ml-2 cursor-pointer"
                          type="radio"
                          name="type"
                          checked={this.state.type === "trip"}
                          value="trip"
                          onChange={e =>
                            this.safeSetState({
                              type: e.target.value
                            })
                          }
                        />
                      </label>
                    </div>

                    <div className="px-4">
                      <label className="text-sm text-grey-dark inline-block cursor-pointer">
                        <Trans>Feature</Trans>
                        <input
                          style={{
                            MozAppearance: "radio",
                            WebkitAppearance: "radio",
                            appearance: "initial"
                          }}
                          className="ml-2 cursor-pointer"
                          type="radio"
                          name="type"
                          checked={this.state.type === "feature"}
                          value="feature"
                          onChange={e =>
                            this.safeSetState({
                              type: e.target.value
                            })
                          }
                        />
                      </label>
                    </div>
                    <div className="px-4">
                      <label className="text-sm text-grey-dark inline-block cursor-pointer">
                        <Trans>Bug</Trans>
                        <input
                          style={{
                            MozAppearance: "radio",
                            WebkitAppearance: "radio",
                            appearance: "initial"
                          }}
                          className="ml-2 cursor-pointer"
                          type="radio"
                          name="type"
                          checked={this.state.type === "bug"}
                          value="bug"
                          onChange={e =>
                            this.safeSetState({
                              type: e.target.value
                            })
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-input-group">
                  <label className="form-label required">
                    <Trans>Title</Trans>
                  </label>
                  <div className="w-full">
                    <input
                      type="text"
                      className={`${error && error.title ? "border-red" : ""} form-input w-full`}
                      value={title}
                      onChange={e => this.safeSetState({ title: e.target.value })}
                    />
                    {error && error.title && (
                      <span className="text-red text-xs block mt-1">{error.title}</span>
                    )}
                    <span className="text-xs text-grey">
                      <Trans>Description of the issue or feedback.</Trans>
                    </span>
                  </div>
                </div>

                {type !== "trip" ? (
                  <>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>URL</Trans>
                      </label>
                      <div className="w-full">
                        <input
                          type="text"
                          className="form-input w-full"
                          value={url}
                          onChange={e => this.safeSetState({ url: e.target.value })}
                        />
                        <span className="text-xs text-grey">
                          <Trans>The URL you are on when the error appears.</Trans>
                        </span>
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Severity</Trans>
                      </label>
                      <div className="w-full">
                        <select
                          value={severity}
                          className="form-input w-full"
                          onChange={e =>
                            this.safeSetState({
                              severity: e.target.value
                            })
                          }>
                          <option value="low">{i18n._(t`Low`)}</option>
                          <option value="medium">{i18n._(t`Medium`)}</option>
                          <option value="high">{i18n._(t`High`)}</option>
                        </select>
                        <span className="text-xs text-grey">
                          <Trans>Low: typos, etc</Trans>
                          <br />
                          <Trans>Medium: Affects the user experience, but not urgent</Trans>
                          <br />
                          <Trans>
                            High: Affects user experience, needs fixing as soon as possible
                          </Trans>
                        </span>
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Browser</Trans>
                      </label>
                      <div className="w-full">
                        <select
                          value={browser}
                          className="form-input w-full"
                          onChange={e =>
                            this.safeSetState({
                              browser: e.target.value
                            })
                          }>
                          <option value="Chrome">Chrome</option>
                          <option value="Firefox">Firefox</option>
                          <option value="IE">IE</option>
                          <option value="Opera">Opera</option>
                          <option value="Safari">Safari</option>
                          <option value="Other">{i18n._(t`Other`)}</option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Trip</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        disabled={!trips.length}
                        value={trip_id}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            trip_id: e.target.value
                          })
                        }>
                        <option value="">-- {i18n._(t`Select a Trip`)} --</option>
                        {trips.map((trip, index) => (
                          <option key={index} value={trip.id}>
                            {trip.trip_name} ({moment.utc(trip.start_date).format("LL")} -{" "}
                            {moment.utc(trip.end_date).format("LL")})
                          </option>
                        ))}
                      </select>
                      {error && error.trip_id && (
                        <span className="text-red text-xs block mt-1">{error.trip_id}</span>
                      )}
                      {!isFetchingTrips && !trips.length && (
                        <span className="text-red text-xs block mt-1">
                          <Trans>You aren't registered on any trips yet.</Trans>
                        </span>
                      )}
                    </div>
                  </div>
                )}

                {type === "bug" ? (
                  <div>
                    <div className="form-input-group">
                      <label className="form-label required">
                        <Trans>Steps to Reproduce</Trans>
                      </label>
                      <div className="w-full">
                        <textarea
                          rows="5"
                          className={`${
                            error && error.steps ? "border-red" : ""
                          } form-input w-full`}
                          onChange={e =>
                            this.safeSetState({
                              steps: e.target.value
                            })
                          }
                          value={steps}
                        />
                        {error && error.steps && (
                          <span className="text-red text-xs block mt-1">{error.steps}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label required">
                        <Trans>Expected Result</Trans>
                      </label>
                      <div className="w-full">
                        <textarea
                          rows="5"
                          className={`${
                            error && error.expected ? "border-red" : ""
                          } form-input w-full`}
                          onChange={e =>
                            this.safeSetState({
                              expected: e.target.value
                            })
                          }
                          value={expected}
                        />
                        {error && error.expected && (
                          <span className="text-red text-xs block mt-1">{error.expected}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label required">
                        <Trans>Actual Result</Trans>
                      </label>
                      <div className="w-full">
                        <textarea
                          rows="5"
                          className={`${
                            error && error.actual ? "border-red" : ""
                          } form-input w-full`}
                          onChange={e =>
                            this.safeSetState({
                              actual: e.target.value
                            })
                          }
                          value={actual}
                        />
                        {error && error.actual && (
                          <span className="text-red text-xs block mt-1">{error.actual}</span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-input-group">
                    <label className="form-label required">
                      <Trans>Description</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        rows="5"
                        className={`${
                          error && error.description ? "border-red" : ""
                        } form-input w-full`}
                        onChange={e =>
                          this.safeSetState({
                            description: e.target.value
                          })
                        }
                        value={description}
                      />
                      {error && error.description && (
                        <span className="text-red text-xs block mt-1">{error.description}</span>
                      )}
                    </div>
                  </div>
                )}

                <div className="form-input-group">
                  <label className="form-label">Screenshot</label>
                  <div className="w-full">
                    <MediaUpload
                      onSuccess={this.handleImageChange}
                      url={`/images`}
                      accept="image/*"
                    />
                    {/* <input */}
                    {/*     className="text-sm" */}
                    {/*     type="file" */}
                    {/*     accept="image/*" */}
                    {/*     ref={el => (this.imageUpload = el)} */}
                    {/*     onChange={this.handleImageChange} */}
                    {/* /> */}
                    <span className="block text-xs text-grey leading-normal">Images only.</span>
                  </div>
                </div>

                <div className="m-4 p-4 shadow rounded border text-sm">
                  <p className="mb-1">
                    <strong>
                      <Trans>Title</Trans>:{" "}
                    </strong>
                    {title}
                  </p>
                  <p className="mb-1">
                    <strong>
                      <Trans>Type</Trans>:{" "}
                    </strong>
                    {type}
                  </p>
                  {type !== "trip" ? (
                    <>
                      <p className="mb-1">
                        <strong>
                          <Trans>URL</Trans>:{" "}
                        </strong>
                        {url}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>User Agent</Trans>:{" "}
                        </strong>
                        {user_agent}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>Platform</Trans>:{" "}
                        </strong>
                        {platform}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>Severity</Trans>:{" "}
                        </strong>
                        {severity}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>Browser</Trans>:{" "}
                        </strong>
                        {browser}
                      </p>
                    </>
                  ) : (
                    <p className="mb-1">
                      <strong>
                        <Trans>Trip</Trans>:{" "}
                      </strong>
                      {trips.filter(t => t.id === trip_id).length
                        ? trips.filter(t => t.id === trip_id)[0].name
                        : ""}
                    </p>
                  )}
                  <p className="mb-1">
                    <strong>
                      <Trans>Email</Trans>:{" "}
                    </strong>
                    {email}
                  </p>
                  {type === "bug" ? (
                    <div>
                      <p className="mb-1">
                        <strong>
                          <Trans>Steps to Reproduce</Trans>:{" "}
                        </strong>
                        {steps}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>Expected Result</Trans>:{" "}
                        </strong>
                        {expected}
                      </p>
                      <p className="mb-1">
                        <strong>
                          <Trans>Actual Result</Trans>:{" "}
                        </strong>
                        {actual}
                      </p>
                    </div>
                  ) : (
                    <p className="mb-1">
                      <strong>
                        <Trans>Description</Trans>:{" "}
                      </strong>
                      {description}
                    </p>
                  )}
                  <div className="mb-1">
                    <strong className="block">Screenshot:</strong>
                    <div className="mt-2">{imagePreview}</div>
                  </div>
                </div>

                <div className="form-actions border-t">
                  <Link
                    to="/"
                    style={{ minWidth: "150px" }}
                    type="submit"
                    className="btn w-full btn-solid-red">
                    <Trans>Cancel</Trans>
                  </Link>
                  {process.env.NODE_ENV === "development" ? (
                    <span
                      onClick={() =>
                        this.setState({
                          title: "Some Feedback Title",
                          steps: "1, 2, 3, 4",
                          expected: "Woah!",
                          actual: "Hmm..."
                        })
                      }
                      className="btn mx-auto">
                      <Trans>Generate</Trans>
                    </span>
                  ) : null}
                  <button
                    style={{ minWidth: "150px" }}
                    type="submit"
                    className="ml-auto w-full btn btn-solid-blue"
                    disabled={isSubmitting}>
                    {isSubmitting ? <Trans>Submitting...</Trans> : <Trans>Submit Feedback</Trans>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FeedbackForm;
