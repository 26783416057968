import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";
import { ApiConsumer } from "../contexts/ApiContext";

const MyTrips = props => (
  <div className="">
    <ApiConsumer>
      {({ user }) => (
        <ResourceIndex
          {...props}
          endpoint="my-trips"
          model={<Trans>Trips</Trans>}
          canSearch={false}
          headers={
            user.type === "Client"
              ? ["status", "trip_name", "participant_full_name", "start_date", "end_date"]
              : [
                  "status",
                  "trip_name",
                  "location_name",
                  "school_name",
                  "participant_full_name",
                  "code",
                  "trip_leader_name",
                  "start_date",
                  "end_date"
                ]
          }
          translations={
            user.type === "Client"
              ? [
                  <Trans>Status</Trans>,
                  <Trans>Trip Name</Trans>,
                  <Trans>Participant Name</Trans>,
                  <Trans>Start Date</Trans>,
                  <Trans>End Date</Trans>
                ]
              : [
                  <Trans>Status</Trans>,
                  <Trans>Trip Name</Trans>,
                  <Trans>Location</Trans>,
                  <Trans>School</Trans>,
                  <Trans>Participant Name</Trans>,
                  <Trans>Code</Trans>,
                  <Trans>Trip Leader</Trans>,
                  <Trans>Start Date</Trans>,
                  <Trans>End Date</Trans>
                ]
          }
          actions={user.type === "Client" ? ["show", "pay", "unregister"] : ["show"]}
          hasCalendar={true}
        />
      )}
    </ApiConsumer>
  </div>
);

export default MyTrips;
