import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const ParticipantIndex = props => (
  <div>
    <ResourceIndex
      endpoint="participants"
      model={<Trans>Participants</Trans>}
      actions={["show"]}
      headers={["full_name"]}
      translations={[<Trans>Name</Trans>]}
      {...props}
    />
  </div>
);

export default ParticipantIndex;
