import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";
import { ApiConsumer } from "../contexts/ApiContext";

const MyPayments = props => (
  <div className="">
    <ApiConsumer>
      {({ user }) => (
        <ResourceIndex
          {...props}
          endpoint="my-payments"
          model={<Trans>Payments</Trans>}
          canSearch={false}
          headers={[
            "full_name",
            "trip_name",
            "location_name",
            "school_name",
            "amount",
            "payment_type",
            "paid_at"
          ]}
          translations={[
            <Trans>Participant Name</Trans>,
            <Trans>Trip</Trans>,
            <Trans>Location</Trans>,
            <Trans>School</Trans>,
            <Trans>Amount</Trans>,
            <Trans>Payment Type</Trans>,
            <Trans>Paid At</Trans>
          ]}
          actions={[]}
        />
      )}
    </ApiConsumer>
  </div>
);

export default MyPayments;
