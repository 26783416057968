import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const Feedback = props => (
  <div>
    <ResourceIndex
      endpoint="feedback"
      canSearch={false}
      model={<Trans>Feedback</Trans>}
      headers={[
        "user",
        "title",
        "trip_name",
        "created_at"
        // "location_name",
        // "school_name",
        // "trip_leader_name",
        // "start_date",
        // "end_date"
      ]}
      translations={[
        <Trans>User</Trans>,
        <Trans>Title</Trans>,
        <Trans>Trip Name</Trans>,
        <Trans>Submitted</Trans>
        // <Trans>Location</Trans>,
        // <Trans>School</Trans>,
        // <Trans>Trip Leader</Trans>,
        // <Trans>Start Date</Trans>,
        // <Trans>End Date</Trans>
      ]}
      {...props}
      actions={["show"]}
    />
  </div>
);

export default Feedback;
