import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "../pages/ResourceIndex";

const RiskAssessmentIndex = props => (
  <div>
    <ResourceIndex
      endpoint="risk_assessments"
      canCreate={props.canCreate}
      canSearch={false}
      isComponent={true}
      model={<Trans>Risk Assessment Index</Trans>}
      headers={["name", "location_name"]}
      translations={[<Trans>Title</Trans>, <Trans>Location</Trans>]}
      {...props}
      actions={props.actions}
    />
  </div>
);

export default RiskAssessmentIndex;
