import React from "react";
import ResourceShow from "./ResourceShow";

const SchoolShow = props => (
  <div>
    <ResourceShow {...props} endpoint="schools" model="School" />
  </div>
);

export default SchoolShow;
