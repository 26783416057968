import React, { Component } from "react";
import { toast } from "react-toastify";
import { t, Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import { i18n } from "../App";
import { deleteResource as deleteRequest, updateResource as patchRequest } from "../api";
import { ApiContext } from "../contexts/ApiContext";

class DeleteResourceButton extends Component {
  static contextType = ApiContext;
  static propTypes = {
    url: PropTypes.string.isRequired,
    action: PropTypes.string.isRequired,
    confirm: PropTypes.bool.isRequired,
    onDelete: PropTypes.func.isRequired
  };
  static defaultProps = {
    action: "delete",
    confirm: true
  };

  deleteResource = () => {
    const { confirm, url, action } = this.props;
    let msg;

    switch (action) {
      case "archive":
        msg = i18n._(t`Are you sure you want to archive this item?`);
        break;
      case "restore":
        msg = i18n._(t`Are you sure you want to restore this item?`);
        break;
      default:
        msg = i18n._(t`Are you sure you want to delete this item?`);
        break;
    }

    if (confirm) {
      const confirm = window.confirm(msg);
      if (!confirm) return;
    }

    this.context
      .callApi(() => (action === "delete" ? deleteRequest(url) : patchRequest(url)))
      .then(res => {
        this.props.onDelete();
      })
      .catch(error => {
        if (error.message && typeof error.message === "string") {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to delete item.</Trans>);
      });
  };

  render() {
    const { children, className } = this.props;

    return (
      <span className={className} onClick={this.deleteResource}>
        {children}
      </span>
    );
  }
}

export default DeleteResourceButton;
