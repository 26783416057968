import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
// import { Link } from "react-router-dom";
import { fetchResources as getRequest } from "../api";
import { ApiContext } from "../contexts/ApiContext";
import LoadingWrapper from "../components/LoadingWrapper";
import MediaUpload from "../components/MediaUpload";
import Gallery from "../components/Gallery";

class GalleryPage extends Component {
  static contextType = ApiContext;
  state = {
    images: [],
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchImages();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchImages = () => {
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => getRequest("images"))
      .then(({ data }) => {
        this.safeSetState({
          images: data,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch images.</Trans>);
      });
  };

  handleDelete = data => {
    this.safeSetState({
      images: this.state.images.filter(i => i.id !== data.id)
    });
    toast.success(<Trans>Image deleted!</Trans>);
  };

  handleUpload = data => {
    this.safeSetState({
      images: this.state.images.concat(data)
    });
  };

  render() {
    const { images, isLoading } = this.state;
    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Gallery</Trans>
            </h1>
            <div className="flex justify-between items-center mb-2 text-right">
              <div>
                <MediaUpload accept="image/*" url="/images" onSuccess={this.handleUpload} />
              </div>
            </div>
            <LoadingWrapper isLoading={isLoading}>
              <Gallery
                images={images}
                handleDelete={this.handleDelete}
                handleUpload={this.handleUpload}
                canDelete={true}
              />
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default GalleryPage;
