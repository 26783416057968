import React from "react";
import MedicalConditionForm from "./MedicalConditionForm";

const SkillCreate = props => (
  <div>
    <MedicalConditionForm isCreate={true} {...props} endpoint="skills" model="Skill" />
  </div>
);

export default SkillCreate;
