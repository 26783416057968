import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import Table from "../components/Table";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource as attemptQueryPayment } from "../api";
import Calendar from "../components/Calendar";

class ResourceIndex extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    model: PropTypes.object.isRequired,
    headers: PropTypes.array.isRequired,
    translations: PropTypes.array.isRequired,
    goToEdit: PropTypes.bool.isRequired,
    showAllCalendarEvents: PropTypes.bool.isRequired,
    fileUpload: PropTypes.bool.isRequired,
    canCreate: PropTypes.bool.isRequired,
    canSearch: PropTypes.bool.isRequired,
    download: PropTypes.bool.isRequired,
    hasCalendar: PropTypes.bool.isRequired,
    isComponent: PropTypes.bool.isRequired,
    actions: PropTypes.array.isRequired
  };
  static contextType = ApiContext;
  static defaultProps = {
    actions: ["show", "edit", "delete"],
    goToEdit: false,
    download: false,
    fileUpload: false,
    canCreate: false,
    isComponent: false,
    showAllCalendarEvents: false,
    hasCalendar: false,
    meta: null,
    links: null,
    canSearch: true
  };
  state = {
    items: null,
    pagination: null,
    name: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.endpoint !== this.props.endpoint) {
      this.fetchItems();
    }
  }

  fetchItems = (url = null) => {
    const { endpoint } = this.props;
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources(url ? url : endpoint))
      .then(({ data }) => {
        this.safeSetState({
          items: data.data,
          isLoading: false,
          error: null,
          links: data.links || null,
          meta: data.meta || null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch item.</Trans>);
        }
        toast.error(error);
      });
  };

  queryPayment = id => {
    const { callApi } = this.context;
    callApi(() => attemptQueryPayment(`/query-payment/${id}`))
      .then(({ data }) => {
        console.log("%cdata", "color: #3490dc; font-weight: bold", data);
        toast.success(<Trans>Payment verified!</Trans>);
      })
      .catch(error => {
        toast.error(<Trans>Payment invalid.</Trans>, { autoClose: false });
      });
  };

  render() {
    const {
      endpoint,
      model,
      headers,
      actions,
      goToEdit,
      fileUpload,
      canCreate,
      canSearch,
      isComponent,
      hasCalendar,
      translations,
      showAllCalendarEvents,
      download
    } = this.props;
    const { items, links, meta, isLoading, error } = this.state;

    return (
      <div className={`${isComponent ? "" : "main-page-container"}`}>
        <div className={`${isComponent ? "" : "p-2 md:p-8"}`}>
          <LoadingWrapper
            isLoading={isLoading}
            error={error}
            retry={() => this.fetchItems(endpoint)}>
            {Array.isArray(items) && (
              <>
                <Table
                  isComponent={isComponent}
                  goToEdit={goToEdit}
                  endpoint={endpoint}
                  translations={translations}
                  download={download}
                  canCreate={canCreate}
                  fileUpload={fileUpload}
                  model={model}
                  canSearch={canSearch}
                  items={items}
                  links={links}
                  meta={meta}
                  headers={headers}
                  actions={actions}
                  queryPayment={this.queryPayment}
                  fetch={this.fetchItems}
                />
                {hasCalendar && (
                  <div className="mt-4">
                    <Calendar showAll={showAllCalendarEvents} />
                  </div>
                )}
              </>
            )}
          </LoadingWrapper>
        </div>
      </div>
    );
  }
}

export default ResourceIndex;
