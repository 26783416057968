import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";

class AdminFeedbackShow extends Component {
  static contextType = ApiContext;
  state = {
    closed_at: null,
    created_at: null,
    description: null,
    actual: null,
    expected: null,
    steps: null,
    email: null,
    files: null,
    image: null,
    id: null,
    type: null,
    trip: null,
    order: null,
    platform: null,
    severity: null,
    title: null,
    userable_id: null,
    userable_type: null,
    editTitle: false,
    isSubmitting: false,
    isLoading: false,
    isUpdatingStatus: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchIssue();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchIssue = () => {
    this.setState({ isLoading: true, error: null });
    const { id } = this.props.match.params;

    this.context
      .callApi(() => fetchResources(`feedback/${id}/`))
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          issue: data,
          closed_at: data.closed_at,
          created_at: data.created_at,
          description: data.description,
          actual: data.actual,
          expected: data.expected,
          image: data.image,
          steps: data.steps,
          email: data.email,
          files: data.files,
          id: data.id,
          type: data.type,
          trip: data.trip,
          order: data.order,
          platform: data.platform,
          severity: data.severity,
          title: data.title,
          userable_id: data.userable_id,
          userable_type: data.userable_type.replace("App\\", "").toLowerCase() + "s"
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });
      });
  };

  handleUpdate = e => {
    e.preventDefault();
    const { id } = this.props.match.params;
    const {
      description,
      actual,
      expected,
      steps,
      email,
      files,
      type,
      platform,
      severity,
      title
    } = this.state;
    this.safeSetState({ isSubmitting: true });

    this.context
      .callApi(() =>
        updateResource(`feedback/${id}/`, {
          description,
          actual,
          expected,
          steps,
          email,
          files,
          type,
          platform,
          severity,
          title
        })
      )
      .then(({ data }) => {
        toast.success(<Trans>Feedback updated!</Trans>);
        this.safeSetState({ editTitle: false, isSubmitting: false });
      })
      .catch(error => {
        toast.error(<Trans>Failed to update issue.</Trans>);
        this.safeSetState({ isSubmitting: false, error });
      });
  };

  closeIssue = () => {
    const { id } = this.props.match.params;
    const { closed_at } = this.state;
    this.safeSetState({ isUpdatingStatus: true });
    this.context
      .callApi(() => updateResource(`feedback/status/${id}`))
      .then(({ data }) => {
        this.safeSetState({ isUpdatingStatus: false });
        toast.success(`Issue ${closed_at ? "reopened" : "closed"}!`);
        setTimeout(() => history.push("/admin-feedback"), 1000);
      })
      .catch(error => {
        toast.error(<Trans>Failed to close issue.</Trans>);
        this.safeSetState({
          isUpdatingStatus: false,
          error
        });
      });
  };

  render() {
    const {
      closed_at,
      created_at,
      description,
      actual,
      expected,
      steps,
      email,
      image,
      id,
      type,
      trip,
      platform,
      severity,
      title,
      userable_type,
      userable_id,
      editTitle,
      isSubmitting,
      isLoading,
      isUpdatingStatus
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Feedback Detail</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading}>
              {id && (
                <div className="form-container p-4">
                  <div className="mb-4 border-b">
                    <div className="mb-4 pb-4 border-b">
                      <form method="post" onSubmit={this.handleUpdate}>
                        <div className="flex items-center justify-between mb-2">
                          {editTitle ? (
                            <input
                              className="form-input w-full mr-2"
                              type="text"
                              onChange={e =>
                                this.setState({
                                  title: e.target.value
                                })
                              }
                              value={title}
                            />
                          ) : (
                            <h2 className="font-normal truncate">
                              {title} <span className="text-grey">#{id}</span>
                            </h2>
                          )}
                          {!editTitle ? (
                            <div className="whitespace-no-wrap">
                              <button
                                type="button"
                                onClick={e => {
                                  e.preventDefault();
                                  this.setState({
                                    editTitle: true
                                  });
                                }}
                                className="btn btn-solid-teal mr-2">
                                <Trans>Edit</Trans>
                              </button>
                              <Link to="/feedback" className="btn btn-solid-blue">
                                <Trans>New Issue</Trans>
                              </Link>
                            </div>
                          ) : (
                            <div className="whitespace-no-wrap">
                              <button disabled={isSubmitting} type="submit" className="btn mr-2">
                                {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  this.setState({
                                    editTitle: false
                                  })
                                }
                                disabled={isSubmitting}
                                className="btn btn-outline mr-2">
                                <Trans>Cancel</Trans>
                              </button>
                            </div>
                          )}
                        </div>
                      </form>
                      <div className="mb-2 text-sm text-grey-dark leading-normal">
                        <a
                          className="text-grey-dark no-underline hover:text-grey-darker"
                          href={`mailto:${email}?subject=RE: ${title}`}>
                          {email}
                        </a>{" "}
                        opened this issue {moment(created_at).fromNow()}
                      </div>

                      <div className="text-sm mb-2">
                        <Link className="btn btn-xs mr-2" to={`/${userable_type}/${userable_id}`}>
                          <Trans>View User</Trans>
                        </Link>
                        {trip ? (
                          <Link className="btn btn-blue btn-xs mr-2" to={`/trips/${trip.id}`}>
                            <Trans>View Trip</Trans>
                          </Link>
                        ) : null}
                      </div>
                    </div>

                    <div className="text-sm mb-4">
                      <span
                        className={`inline-block text-white rounded mr-2 p-1 bg-${
                          severity === "high" ? "red" : severity === "medium" ? "orange" : "blue"
                        }`}>
                        {severity}
                      </span>
                      <span
                        className={`inline-block text-white rounded mr-2 p-1 bg-${
                          type === "bug" ? "red" : type === "feature" ? "green" : "pink"
                        }`}>
                        {type}
                      </span>
                      <span className={`inline-block text-white rounded mr-2 p-1 bg-grey`}>
                        {platform}
                      </span>
                    </div>

                    {type === "bug" ? (
                      <div className="pb-4">
                        <div className="mb-4">
                          <label className="block text-xs text-grey-dark mb-1">
                            <Trans>Steps to Reproduce</Trans>
                          </label>
                          <p>{steps}</p>
                        </div>
                        <div className="mb-4">
                          <label className="block text-xs text-grey-dark mb-1">
                            <Trans>Expected Result</Trans>
                          </label>
                          <p>{expected}</p>
                        </div>
                        <div className="mb-4">
                          <label className="block text-xs text-grey-dark mb-1">
                            <Trans>Actual Result</Trans>
                          </label>
                          <p>{actual}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="pb-4">{description}</div>
                    )}

                    {image ? (
                      <div className="p-4">
                        <img
                          src={`${process.env.REACT_APP_API_BASE_URL}/images/${
                            image.id
                          }?token=${localStorage.getItem("INDIER_JWT")}`}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </div>
                  <div>
                    <button
                      disabled={isSubmitting || isUpdatingStatus}
                      className="btn btn-solid-red"
                      onClick={this.closeIssue}>
                      {isUpdatingStatus ? (
                        <Trans>Updating status...</Trans>
                      ) : closed_at ? (
                        <Trans>Reopen Issue</Trans>
                      ) : (
                        <Trans>Close Issue</Trans>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminFeedbackShow;
