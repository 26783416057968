import React, { Component } from "react";
import { Line, Bar } from "react-chartjs-2";
import { Trans } from "@lingui/macro";

class Graph extends Component {
  render() {
    const { title, type, data } = this.props;

    if (type === "bar") {
      return (
        <div className="" style={{ flexBasis: 600 }}>
          <Bar
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              title: {
                display: true,
                text: title
              },
              legend: {
                display: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    }
                  }
                ]
              }
            }}
          />
        </div>
      );
    }

    if (type === "stackedBar") {
      return (
        <div className="m-4 " style={{ flexBasis: 600 }}>
          <Bar
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              title: {
                display: true,
                text: title
              },
              legend: {
                display: false
              },
              scales: {
                xAxes: [
                  {
                    ticks: {
                      autoSkip: false
                    },
                    stacked: true
                  }
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true
                    },
                    stacked: true
                  }
                ]
              }
            }}
          />
        </div>
      );
    }

    if (type === "line") {
      return (
        <div className="" style={{ flexBasis: 600 }}>
          <Line
            data={data}
            options={{
              responsive: true,
              maintainAspectRatio: true,
              title: {
                display: true,
                text: title
              },
              legend: {
                display: false
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: false,
                      precision: 0
                    }
                  }
                ]
              }
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Trans>Invalid graph type.</Trans>
      </div>
    );
  }
}

export default Graph;
