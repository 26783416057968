import React from "react";
import Icon from "./Icon";

const Gender = ({ gender }) =>
  gender ? (
    <span className={`inline-block mr-2 ${gender === "male" ? "text-blue" : "text-pink"}`}>
      <Icon size={16} icon={gender} />
    </span>
  ) : null;

export default Gender;
