import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const AdvertIndex = props => (
  <div>
    <ResourceIndex
      endpoint="adverts"
      canCreate={true}
      model={<Trans>Advert Index</Trans>}
      headers={["title", "trip_name", "published_at"]}
      translations={[<Trans>Title</Trans>, <Trans>Trip Name</Trans>, <Trans>Published</Trans>]}
      {...props}
      actions={["edit", "delete"]}
    />
  </div>
);

export default AdvertIndex;
