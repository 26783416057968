import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { ApiContext } from "../contexts/ApiContext";
import { createResource } from "../api";

class MediaUpload extends Component {
  static contextType = ApiContext;
  static propTypes = {
    onSuccess: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    accept: PropTypes.string.isRequired
  };
  static defaultProps = {
    accept: "*"
  };

  state = {
    progress: 0,
    file: null,
    error: undefined,
    uploading: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleFileChange = e => {
    e.persist();
    if (!e.target.files) return;
    const { onSuccess, url } = this.props;
    const file = e.target.files[0];
    this.safeSetState({ file });
    let data = new FormData();
    data.append("file", file);
    data.append("name", file.name);
    this.safeSetState({ uploading: true });

    this.context
      .callApi(() => createResource(url, data))
      .then(res => {
        this.safeSetState({ uploading: false });
        e.target.value = null;
        onSuccess(res.data, file);
        toast.success(<Trans>Media upload successful!</Trans>);
      })
      .catch(error => {
        e.target.value = null;
        this.safeSetState({ uploading: false, error });
        toast.error(error.message, { autoClose: false });
      });
  };

  render() {
    const { uploading } = this.state;
    const { accept } = this.props;

    return (
      <div>
        <div className="text-sm">
          <input
            className="w-full bg-white rounded text-xs p-2 text-grey-dark no-outline"
            onChange={e => this.handleFileChange(e)}
            disabled={uploading}
            type="file"
            accept={accept}
          />
        </div>

        {uploading && (
          <div className="text-left text-sm bg-teal mt-2 p-2 text-white rounded">
            <Trans>Uploading media...</Trans>
          </div>
        )}
      </div>
    );
  }
}

export default MediaUpload;
