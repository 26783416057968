import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const TeacherIndex = props => (
  <div>
    <ResourceIndex
      endpoint="teachers"
      model={<Trans>Teachers</Trans>}
      headers={["full_name", "email", "phone", "school"]}
      translations={[
        <Trans>Name</Trans>,
        <Trans>Email</Trans>,
        <Trans>Phone</Trans>,
        <Trans>School</Trans>
      ]}
      {...props}
      actions={["show"]}
    />
  </div>
);

export default TeacherIndex;
