import React from "react";
import PropTypes from "prop-types";

const PageHeader = ({ title, body, color }) => (
  <div className={`text-center py-4 md:py-6 bg-${color || "teal"} text-white`}>
    <h1 className={`text-xl md:text-4xl ${body ? "mb-4" : null} font-light`}>{title}</h1>
    {body && <div className="container-text">{body}</div>}
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.object.isRequired,
  body: PropTypes.object
};

export default PageHeader;
