import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const ArchivedStaffIndex = props => (
  <div>
    <ResourceIndex
      endpoint="archived-staffs"
      model={<Trans>Archived Staff</Trans>}
      canCreate={false}
      canSearch={false}
      headers={["avatar", "full_name", "email", "role"]}
      translations={[
        <Trans>Avatar</Trans>,
        <Trans>Name</Trans>,
        <Trans>Email</Trans>,
        <Trans>Role</Trans>
      ]}
      {...props}
      actions={["unarchive"]}
    />
  </div>
);

export default ArchivedStaffIndex;
