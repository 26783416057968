import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";
import LoadingWrapper from "../components/LoadingWrapper";
import Wysiwyg from "../components/Wysiwyg";

const toolbarConfig = {
  options: [
    "inline",
    "blockType",
    "fontSize",
    "list",
    "link",
    "history",
    "colorPicker",
    "textAlign"
  ],
  blockType: {
    inDropdown: true,
    options: ["Normal", "H1", "H2", "H3", "H4", "H5", "H6"]
  },
  inline: {
    inDropdown: false,
    options: ["bold", "italic", "underline"]
  },
  list: {
    inDropdown: false,
    options: ["unordered", "ordered"]
  },
  colorPicker: {
    className: undefined,
    component: undefined,
    popupClassName: undefined,
    colors: [
      "rgb(255,255,255)",
      "rgb(96, 111, 123)",
      "rgb(101, 116, 205)",
      "rgb(77, 192, 181)",
      "rgb(52, 144, 220)",
      "rgb(246, 153, 63)",
      "rgb(227, 52, 47)"
    ]
  },
  textAlign: {
    inDropdown: false,
    className: undefined,
    component: undefined,
    dropdownClassName: undefined,
    options: ["left", "center", "right", "justify"]
  }
};

class Waiver extends Component {
  static contextType = ApiContext;
  state = {
    id: null,
    content: null,
    chinese_content: null,
    error: null,
    isLoading: true,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItems = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoading: true });

    callApi(() => fetchResources("waiver"))
      .then(({ data }) => {
        this.safeSetState({
          id: data.id,
          content: data.content,
          chinese_content: data.chinese_content,
          isLoading: false
        });
      })
      .catch(error => {
        toast.error(<Trans>Failed to fetch waiver.</Trans>);
        this.safeSetState({ error, isLoading: false });
      });
  };

  handleSubmit = e => {
    const { content, chinese_content, id } = this.state;
    const { callApi } = this.context;
    const payload = {
      content,
      chinese_content: chinese_content
    };
    this.safeSetState({ isSubmitting: true });

    callApi(() => updateResource(`waiver/${id}`, payload))
      .then(({ data }) => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
      })
      .catch(error => {
        toast.error(<Trans>Failed to save waiver.</Trans>);
        this.safeSetState({ error, isSubmitting: false });
      });
  };

  render() {
    const { content, chinese_content, error, isLoading, isSubmitting } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <h1 className="page-header">
            <Trans>Waiver</Trans>
          </h1>
          <LoadingWrapper isLoading={isLoading} error={error}>
            <div className="">
              <div className="">
                {content && (
                  <div className="mb-4">
                    <Wysiwyg
                      toolbarConfig={toolbarConfig}
                      content={content}
                      updateContent={content => this.setState({ content })}
                    />
                  </div>
                )}
                {chinese_content && (
                  <div className="mb-4">
                    <Wysiwyg
                      toolbarConfig={toolbarConfig}
                      content={chinese_content}
                      updateContent={chinese_content => this.setState({ chinese_content })}
                    />
                  </div>
                )}
              </div>
              <div className="text-right">
                <button
                  disabled={isSubmitting}
                  className="btn btn-solid-blue w-full md:w-auto"
                  onClick={this.handleSubmit}>
                  {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                </button>
              </div>
            </div>
          </LoadingWrapper>
        </div>
      </div>
    );
  }
}

export default Waiver;
