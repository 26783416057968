import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import Modal from "react-modal";
import { i18n } from "../App";
import moment from "moment";
// import history from "../components/history";
// import DeleteButton from "../components/DeleteButton";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { getPaymentForm, getPaymentSummary } from "../api";
import wechatQr from "../assets/wechat-qr-banner.jpg";

const customModalStyles = {
  overlay: {
    zIndex: 50
  },
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 10,
    bottom: 10,
    left: 10,
    right: 10,
    padding: 10
  }
};

class PaymentPage extends Component {
  static contextType = ApiContext;
  state = {
    trip: null,
    participant: null,
    form: null,
    isLoading: false,
    isSubmitting: false,
    isGeneratingForm: false,
    showWechat: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchFormData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchFormData = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { trip_id, participant_id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => getPaymentSummary(trip_id, participant_id))
      .then(({ data }) => {
        this.safeSetState({
          trip: data.trip,
          participant: data.participant,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  goToPayment = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { trip_id, participant_id }
      }
    } = this.props;
    this.safeSetState({ isGeneratingForm: true });
    callApi(() => getPaymentForm(trip_id, participant_id))
      .then(({ data }) => {
        this.safeSetState({ form: data });
        document.forms["alipaysubmit"].submit();
      })
      .catch(error => {
        this.safeSetState({
          isGeneratingForm: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const {
      trip,
      form,
      participant,
      message,
      isLoading,
      isGeneratingForm,
      showWechat
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Payment</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} message={message}>
              {trip && participant && (
                <div className="form-container form-read-only">
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Participant Details</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{participant.full_name}</span>
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Trip Details</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Trip Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{trip.name}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>School</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{trip.school}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Location</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{trip.location}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Start Date</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {moment.utc(trip.start_date).format("LL")}
                      </span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>End Date</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {moment.utc(trip.end_date).format("LL")}
                      </span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Price</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{trip.price} RMB</span>
                    </div>
                  </div>

                  {trip.paid_at && (
                    <>
                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Receipt</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Trip Name</Trans>
                        </label>
                        <div className="w-full">
                          <span className="w-full form-input">{trip.name}</span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="form-actions">
                    {trip.paid_at ? (
                      <span>
                        <Trans>Payment Successful!</Trans>
                      </span>
                    ) : (
                      <>
                        <button
                          disabled={isGeneratingForm}
                          onClick={this.goToPayment}
                          className="ml-auto btn btn-solid-blue">
                          {isGeneratingForm ? (
                            <Trans>Proceeding to Checkout...</Trans>
                          ) : (
                            <>
                              <Trans>Alipay</Trans> (<Trans>preferred</Trans>)
                            </>
                          )}
                        </button>
                        <button
                          disabled={isGeneratingForm}
                          onClick={() => this.setState({ showWechat: true })}
                          className="ml-2 btn btn-solid-green">
                          <Trans>WeChat</Trans>
                        </button>
                        <button
                          disabled={isGeneratingForm}
                          onClick={() =>
                            alert(
                              i18n._(
                                t`Once payment has been made, a staff member will manually update your payment status.`
                              )
                            )
                          }
                          className="ml-2 btn btn-solid-orange">
                          <Trans>Cash</Trans>
                        </button>
                      </>
                    )}
                  </div>
                </div>
              )}
            </LoadingWrapper>
          </div>
          {form && <div dangerouslySetInnerHTML={{ __html: form }} />}
        </div>

        <Modal isOpen={showWechat} ariaHideApp={false} style={customModalStyles}>
          <div className="fixed flex items-center m-4 text-4xl pin-t pin-r" style={{ height: 20 }}>
            <button className="text-grey-dark" onClick={() => this.setState({ showWechat: false })}>
              &times;
            </button>
          </div>
          <div className="flex flex-col">
            <div className="mx-auto mb-2" style={{ maxWidth: 400 }}>
              <img className="overflow-hidden rounded" src={wechatQr} alt="WeChat QR　Code" />
            </div>
            <div
              className="p-4 text-sm border rounded border-green text-green md:text-base"
              style={{ maxWidth: 600 }}>
              <p className="mb-4 leading-normal">
                <Trans>
                  After making your payment please email us a screenshot of the invoice and we can
                  manually proccess the payment.
                </Trans>
              </p>
              <div>
                {trip ? (
                  <a
                    className="block btn btn-solid-green"
                    href={`mailto:nihao@indier.org?subject=[WeChat Payment] ${trip.name} on behalf of ${participant.name}`}>
                    <Trans>Email Us!</Trans>
                  </a>
                ) : null}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default PaymentPage;
