import React from "react";
import MedicalConditionForm from "./MedicalConditionForm";

const SkillEdit = props => (
  <div>
    <MedicalConditionForm {...props} endpoint="skills" model="Skill" />
  </div>
);

export default SkillEdit;
