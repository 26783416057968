import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import moment from "moment";
import LoadingWrapper from "../components/LoadingWrapper";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import { skill_levels } from "./StaffEditForm";

class StaffEditForm extends Component {
  static contextType = ApiContext;
  state = {
    bio: "",
    dob: "",
    email: "",
    avatar: "",
    gender: "",
    id_expiry: "",
    id_number: "",
    id_type: "",
    job_title: "",
    family_name: "",
    given_names: "",
    full_name: "",
    notes: "",
    phone: "",
    role: "",
    roles: {
      admin: { name: "Admin", trans: "管理员" },
      manager: { name: "Manager", trans: "主管" },
      team_leader: { name: "Team Leader", trans: "小组主管" },
      staff: { name: "Staff", trans: "员工" },
      teacher: { name: "Teacher", trans: "教师" },
      client: { name: "Client", trans: "客户" }
    },
    staff_id: "",
    user_id: "",
    visa_duration: "",
    visa_expires_at: "",
    visa_last_entry: "",
    allergies: "",
    diet: "",
    medication: "",
    additional_medical: "",
    skillLevels: skill_levels,
    medical_conditions: [],
    medical_conditions_list: [],
    skills: [],
    certificates: [],
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_street_address: "",
    emergency_contact_city: "",
    emergency_contact_phone: "",
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });

    callApi(() => fetchResources("staffs/" + id))
      .then(({ data }) => {
        this.safeSetState({
          bio: data.bio,
          dob: data.dob,
          avatar: data.avatar,
          email: data.email,
          gender: data.gender,
          id_expiry: data.id_expiry ? moment.utc(data.id_expiry).format("LL") : "",
          id_number: data.id_number,
          id_type: data.id_type,
          job_title: data.job_title,
          family_name: data.family_name,
          given_names: data.given_names,
          full_name: data.full_name,
          notes: data.notes,
          phone: data.phone,
          role: data.role,
          staff_id: data.staff_id,
          user_id: data.user_id,
          visa_duration: data.visa_duration,
          visa_expires_at: data.visa_expires_at,
          visa_last_entry: data.visa_last_entry,
          allergies: data.allergies,
          diet: data.diet,
          medication: data.medication,
          additional_medical: data.additional_medical,
          medical_conditions: data.medical_conditions,
          medical_conditions_list: data.medical_conditions_list,
          skills: data.skills,
          certificates: data.certificates,
          emergency_contact_name: data.emergency_contact_full_name,
          emergency_contact_relationship: data.emergency_contact_relationship,
          emergency_contact_street_address: data.emergency_contact_street_address,
          emergency_contact_city: data.emergency_contact_city,
          emergency_contact_phone: data.emergency_contact_phone,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        console.warn("%cerror", "color: #f6993f; font-weight: bold", error);
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const { user } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      bio,
      dob,
      email,
      gender,
      id_expiry,
      id_number,
      id_type,
      job_title,
      avatar,
      given_names,
      full_name,
      notes,
      phone,
      role,
      roles,
      visa_duration,
      visa_expires_at,
      visa_last_entry,
      allergies,
      diet,
      medication,
      skillLevels,
      additional_medical,
      medical_conditions,
      medical_conditions_list,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      skills,
      certificates,
      isLoading
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Staff Profile</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} retry={this.fetchItem}>
              {given_names ? (
                <div className="form-container form-read-only">
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Basic Info</Trans>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Avatar</Trans>
                    </label>
                    <div className="w-full">
                      {avatar ? (
                        <div>
                          <img className="profile-avatar" src={avatar} alt="Staff" />
                        </div>
                      ) : (
                        <div className="text-grey">
                          <Icon icon="user-square" size={300} />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{full_name}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Email</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        <a href={`mailto:${email}`}>{email}</a>
                      </span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone Number</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {phone ? <a href={`tel:${phone}`}>{phone}</a> : <Trans>None</Trans>}
                      </span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Date of Birth</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {dob ? (
                          <>
                            {moment.utc(dob).format("LL")} (
                            {moment().diff(moment(dob), "years", false)} <Trans>years old</Trans>)
                          </>
                        ) : null}
                      </span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Gender</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{gender}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Bio</Trans>
                    </label>
                    <div className="w-full">
                      <span
                        className="w-full leading-normal form-input"
                        dangerouslySetInnerHTML={{ __html: bio }}
                      />
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>ID</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Type</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {!id_type ? (
                          <Trans>None</Trans>
                        ) : id_type === "passport" ? (
                          <Trans>Passport</Trans>
                        ) : id_type === "id_card" ? (
                          <Trans>ID Card (Mainland/HK)</Trans>
                        ) : (
                          <Trans>Travel Document (Taiwan)</Trans>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Number</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{id_number}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{id_expiry}</span>
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Visa</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Single Entry Duration (days)</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{visa_duration}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Visa Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {visa_expires_at ? (
                          <>
                            {moment.utc(visa_expires_at).format("LL")}
                            {moment.utc(visa_expires_at).diff(moment(), "days") < 10 ? (
                              <span className="inline-block p-1 ml-2 text-xs text-white rounded bg-red">
                                {moment.utc(visa_expires_at).diff(moment(), "days")}{" "}
                                <Trans>days left</Trans>
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <Trans>None</Trans>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Visa Last Entry</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {visa_last_entry ? (
                          <>
                            {moment.utc(visa_last_entry).format("LL")}
                            {moment
                              .utc(visa_last_entry)
                              .add(visa_duration, "days")
                              .diff(moment(), "days") < 10 ? (
                              <span className="inline-block p-1 ml-2 text-xs text-white rounded bg-red">
                                {moment
                                  .utc(visa_last_entry)
                                  .add(visa_duration, "days")
                                  .diff(moment(), "days")}{" "}
                                <Trans>days left</Trans>
                              </span>
                            ) : null}
                          </>
                        ) : (
                          <Trans>None</Trans>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Medical</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Dietary Requirements</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{diet}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Allergies</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{allergies}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Medication</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{medication}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Additional Medical Info</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{additional_medical}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Medical Conditions</Trans>
                    </label>
                    <div className="w-full">
                      {medical_conditions.map(med => (
                        <div key={med} className="mb-1 text-sm">
                          <span className="w-full form-input">
                            {user.locale === "en"
                              ? medical_conditions_list.filter(m => m.id === med)[0].name
                              : medical_conditions_list.filter(m => m.id === med)[0].translation}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Emergency Contact</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{emergency_contact_name}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Relationship</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{emergency_contact_relationship}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Street Address</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{emergency_contact_street_address}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>City</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">{emergency_contact_city}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {emergency_contact_phone ? (
                          <a href={`tel:${emergency_contact_phone}`}>{emergency_contact_phone}</a>
                        ) : (
                          <Trans>None</Trans>
                        )}
                      </span>
                    </div>
                  </div>

                  {(user.role === "manager" || user.role === "admin") && (
                    <>
                      <div className="text-white form-section-header border-pink-dark bg-pink">
                        <Trans>Viewable by Managers Only</Trans>
                      </div>
                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Roles &amp; Permissions</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Job Title</Trans>
                        </label>
                        <div className="w-full">
                          <span className="w-full form-input">{job_title}</span>
                        </div>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Role (permissions)</Trans>
                        </label>
                        <div className="w-full">
                          <span className="w-full form-input">
                            {user.locale === "en" ? roles[role].name : roles[role].trans}
                          </span>
                        </div>
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Certificates</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Certificates</Trans>
                        </label>
                        <div className="w-full">
                          {!certificates.length && <div>No certificates uploaded</div>}
                          {certificates.map(cert => (
                            <div key={cert.id} className="mb-1 text-sm">
                              <label className="cursor-pointer">
                                <a
                                  download
                                  className="mx-2 mr-2 no-underline no-external text-grey hover:text-blue"
                                  href={`${process.env.REACT_APP_API_BASE_URL}/certificates/${
                                    cert.id
                                  }?token=${localStorage.getItem("INDIER_JWT")}`}>
                                  <span className="inline-block mr-1">
                                    {cert.name ? cert.name : cert.filename}
                                  </span>
                                  <Icon icon="download" size={16} />
                                </a>
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Skills</Trans>
                      </div>
                      <div className="p-4 text-sm border-b">
                        <p>
                          In order to reach any level you must have a wilderness aid qualification
                          as well as been signed off by one of Indier co-ordinator team.
                        </p>

                        <p className="mt-2">
                          <strong>
                            Level 1 – Can assist with group management during activity
                          </strong>
                        </p>
                        <p>
                          Guide must display an awareness of your group as well as be able to give
                          instruction to students if they are misbehaving or taking risks
                        </p>

                        <p className="mt-2">
                          <strong>Level 2 – Can assist on activity</strong>
                        </p>
                        <p>
                          Guide must display an understanding of the activity as well as the hazards
                          that involved, and show a competency in the skills required in the
                          activity
                        </p>

                        <p className="mt-2">
                          <strong>Level 3 – Can assist with activity set up</strong>
                        </p>
                        <p>
                          Guide must have an intimate knowledge and required skills to perform the
                          necessary tasks required
                        </p>

                        <p className="mt-2">
                          <strong>Level 4 – Can lead activity</strong>
                        </p>
                        <p>
                          Guide not only has the required knowledge and skills but also has enough
                          experience and confidence to explain to the participants how the activity
                          will be run
                        </p>

                        <p className="mt-2">
                          <strong>Level 5 – Can teach and qualify others on leveling</strong>
                        </p>
                        <p>
                          Guide intimately understands the activity, through constant experience is
                          able to teach other guides how to do the activity and as such can also
                          grade them.
                        </p>
                      </div>
                      {skills.map(skill => (
                        <div key={skill.id} className="form-input-group">
                          <label className="form-label">
                            {user.locale === "en" ? skill.name : skill.translation}
                          </label>
                          <div className="w-full">
                            <span className="w-full form-input">
                              {user.locale === "en"
                                ? skillLevels[skill.level].name
                                : skillLevels[skill.level].trans}
                            </span>
                          </div>
                        </div>
                      ))}

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Notes</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Notes</Trans>
                        </label>
                        <div className="w-full">
                          <span
                            className="w-full form-input"
                            dangerouslySetInnerHTML={{ __html: notes }}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-actions">
                    <Link className="block ml-auto btn btn-solid-blue" to={`/staffs/${id}/edit`}>
                      <Trans>Edit</Trans>
                    </Link>
                  </div>
                </div>
              ) : null}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffEditForm;
