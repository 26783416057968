import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import { fetchResources as getRequest } from "../api";
import { ApiContext } from "../contexts/ApiContext";
// import history from "../components/history";
import Icon from "../components/Icon";
import Graph from "../components/Graph";
import LoadingWrapper from "../components/LoadingWrapper";

class Dashboard extends Component {
  static contextType = ApiContext;
  state = {
    items: [],
    paymentData: null,
    paymentRawData: null,
    registrationData: null,
    registrationRawData: null,
    isLoading: false,
    offset: 30,
    isLoadingPayments: false,
    isLoadingRegistrations: false,
    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    activeUsers: [],
    isFetchingMore: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
    if (this.context.user.role === "manager" || this.context.user.role === "admin") {
      this.fetchPaymentData();
      this.fetchRegistrationData();
    }
    if (this.context.user.role === "admin") {
      this.fetchActiveUsers();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date
    ) {
      this.fetchPaymentData();
      this.fetchRegistrationData();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchActiveUsers = () => {
    this.safeSetState({ isLoadingActiveUsers: true });
    this.context
      .callApi(() => getRequest("active-users"))
      .then(({ data }) => {
        this.safeSetState({
          activeUsers: data,
          isLoadingActiveUsers: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingActiveUsers: false, error });
        toast.error(<Trans>Failed to fetch active users.</Trans>);
      });
  };

  fetchItem = () => {
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => getRequest("dashboard"))
      .then(({ data }) => {
        this.safeSetState({
          items: data,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchPaymentData = () => {
    const { start_date, end_date } = this.state;
    this.safeSetState({ isLoadingPayments: true });
    this.context
      .callApi(() => getRequest(`recent-payments?start_date=${start_date}&end_date=${end_date}`))
      .then(({ data }) => {
        this.safeSetState({
          paymentRawData: data.data,
          paymentData: this.parseGraphData(data, "77, 192, 181", "Total Payments"),
          isLoadingPayments: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingPayments: false, error });
        toast.error(<Trans>Failed to fetch data.</Trans>);
      });
  };

  fetchRegistrationData = () => {
    const { callApi } = this.context;
    const { start_date, end_date } = this.state;
    this.safeSetState({ isLoadingRegistrations: true });
    callApi(() => getRequest(`recent-registrations?start_date=${start_date}&end_date=${end_date}`))
      .then(({ data }) => {
        this.safeSetState({
          registrationRawData: data.data,
          registrationData: this.parseGraphData(data, "52, 144, 220", "New Registrations"),
          isLoadingRegistrations: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingRegistrations: false, error });
        toast.error(<Trans>Failed to fetch data.</Trans>);
      });
  };

  updateRecent = (period, amount) => {
    this.setState({
      start_date: moment().subtract(amount, period).format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    });
  };

  parseGraphData = (response, color, title) => {
    let chartData = {
      labels: response.labels,
      datasets: [
        {
          label: title,
          data: response.data,
          fill: true,
          lineTension: 0,
          pointBackgroundColor: `rgb(${color}, .3)`,
          borderColor: `rgb(${color}, .3)`,
          backgroundColor: `rgb(${color}, .8)`
        }
      ]
    };
    return chartData;
  };

  fetchMoreActivity = () => {
    this.safeSetState({ isFetchingMore: true });
    this.context
      .callApi(() => getRequest("dashboard?offset=" + this.state.offset))
      .then(({ data }) => {
        this.safeSetState({
          items: data,
          offset: this.state.offset + 30,
          isFetchingMore: false,
          error: null
        });
        const offset = document.getElementById("recent-activity").offsetTop;
        window.scrollTo({ top: offset - 60, left: 0, behaviour: "smooth" });
      })
      .catch(error => {
        this.safeSetState({ isFetchingMore: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const {
      items,
      isLoading,
      paymentRawData,
      registrationRawData,
      isLoadingActiveUsers,
      isLoadingPayments,
      isLoadingRegistrations,
      registrationData,
      activeUsers,
      start_date,
      end_date,
      isFetchingMore,
      paymentData
    } = this.state;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Dashboard</Trans>
            </h1>

            {user.role === "admin" ? (
              <div
                className={`text-sm mb-4 px-4 pt-4 bg-grey-lighter shadow rounded ${
                  !activeUsers.length ? "pb-4" : ""
                }`}>
                <LoadingWrapper isLoading={isLoadingActiveUsers}>
                  <h3 className={`text-base ${!activeUsers.length ? "" : "mb-2"}`}>
                    {activeUsers.length} <Trans>active users</Trans>
                  </h3>
                  {activeUsers.map((user, index) => (
                    <div className="flex pb-4" key={index}>
                      <div className="flex items-center flex-col justify-center mr-4">
                        <div
                          className={`text-white rounded-full bg-${
                            colors[user.userable_type]
                          } p-2`}>
                          <Icon icon={user.userable_type.toLowerCase()} />
                        </div>
                      </div>
                      <div className="bg-white leading-normal rounded shadow p-4 flex-1 flex items-center">
                        <Link className="mr-1" to={user.user_url}>
                          {user.full_name}
                        </Link>
                        <Trans>last seen</Trans> {moment.utc(user.last_seen).fromNow()}.
                      </div>
                    </div>
                  ))}
                </LoadingWrapper>
              </div>
            ) : null}

            {(user.role === "manager" || user.role === "admin") && (
              <div className="bg-grey-lighter rounded shadow mb-4">
                <div className="flex lg:justify-between flex-col lg:flex-row text-xs p-4 bg-white rounded-t">
                  <div className="flex mb-4 lg:mb-0">
                    <div className="flex items-center mr-4 lg:mr-6">
                      <span className="mr-2">
                        <Trans>From</Trans>
                      </span>
                      <span className="flex items-center flex-1 lg:flex-auto">
                        <input
                          onChange={e =>
                            this.setState({
                              start_date: e.target.value
                            })
                          }
                          value={start_date}
                          type="date"
                          className="shadow text-xs appearance-none border rounded p-1 text-grey-darker bg-white leading-tight block"
                        />
                      </span>
                    </div>

                    <div className="flex items-center">
                      <span className="mr-2">
                        <Trans>To</Trans>
                      </span>
                      <span className="flex flex-1 lg:flex-auto items-center lg:mr-2">
                        <input
                          onChange={e => this.setState({ end_date: e.target.value })}
                          value={end_date}
                          type="date"
                          className="shadow text-xs appearance-none border rounded p-1 text-grey-darker bg-white leading-tight block"
                        />
                      </span>
                    </div>
                  </div>

                  <div className="flex flex-wrap">
                    <button
                      className="btn btn-xs mr-2"
                      onClick={() => this.updateRecent("days", 1)}>
                      <Trans>24 hours</Trans>
                    </button>

                    <button
                      className="btn btn-xs mr-2"
                      onClick={() => this.updateRecent("days", 7)}>
                      <Trans>Week</Trans>
                    </button>

                    <button
                      className="btn btn-xs mr-2"
                      onClick={() => this.updateRecent("months", 1)}>
                      <Trans>Month</Trans>
                    </button>

                    <button
                      className="btn btn-xs mr-2"
                      onClick={() => this.updateRecent("months", 3)}>
                      <Trans>Quarter</Trans>
                    </button>
                  </div>
                </div>
                <div className="p-4">
                  <LoadingWrapper isLoading={isLoadingPayments} minHeight={400}>
                    {paymentRawData ? (
                      <div className="rounded bg-white p-4 mb-4">
                        {paymentRawData ? (
                          <div className="flex">
                            <strong className="Trans">Total Received</strong>:{" "}
                            {paymentRawData.reduce((acc, current) => acc + current)} CNY
                          </div>
                        ) : null}
                        <Graph
                          type="bar"
                          title={`${user.locale === "en" ? "Recent Payments" : "最近付款数据"}`}
                          data={paymentData}
                        />
                      </div>
                    ) : null}
                  </LoadingWrapper>
                  <LoadingWrapper isLoading={isLoadingRegistrations} minHeight={400}>
                    {registrationData ? (
                      <div className="rounded bg-white p-4">
                        {registrationRawData ? (
                          <div className="flex">
                            <strong className="Trans">Total Registrations</strong>:{" "}
                            {registrationRawData.reduce((acc, current) => acc + current)}{" "}
                          </div>
                        ) : null}
                        <Graph
                          type="bar"
                          title={`${user.locale === "en" ? "Recent Registrations" : "最近报名"}`}
                          data={registrationData}
                        />
                      </div>
                    ) : null}
                  </LoadingWrapper>
                </div>
              </div>
            )}
            <LoadingWrapper isLoading={isLoading}>
              {user.type === "Staff" && (
                <RecentActivity
                  isFetchingMore={isFetchingMore}
                  fetchMoreActivity={this.fetchMoreActivity}
                  activity={items}
                />
              )}
              {/* {user.type === "Client" && <ParticipantList participants={items} />} */}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

const colors = {
  deleted: "red",
  created: "green",
  updated: "orange",
  login: "blue",
  Staff: "blue",
  Client: "green",
  Teacher: "orange"
};

const RecentActivity = ({ activity, fetchMoreActivity, isFetchingMore }) => (
  <div className="" id="recent-activity">
    {!activity.length && (
      <div className="bg-white rounded shadow text-sm p-4 mb-4">
        <Trans>No recent activity.</Trans>
      </div>
    )}
    {activity.map((date, index) => (
      <div key={index} className="text-sm mb-4 px-4 pt-4 bg-grey-lighter shadow rounded">
        <h3 className="text-sm mb-2">{moment.utc(date[0].date).local().format("DD MMM, YYYY")}</h3>
        <div className="">
          {date.map(a => (
            <div key={a.id} className="flex pb-4">
              <div className="text-xxs leading-normal rounded mr-4 flex items-center flex-col justify-center mb-2">
                  <div className="mb-1">{moment.utc(a.created_at).local().format("HH:mm")}</div>
                <div className={`text-white rounded-full bg-${colors[a.description]} p-2`}>
                  <Icon icon={a.description} />
                </div>
              </div>
              <div className="bg-white leading-normal rounded shadow p-4 flex-1 flex items-center">
                {a.description === "login" ? (
                  <div className="mr-2">
                    <Link to={a.user_url}>{a.causer}</Link> <Trans>logged in.</Trans>
                  </div>
                ) : (
                  <div className="mr-2">
                    <Link to={a.user_url}>{a.causer}</Link> {a.description} a{" "}
                    {a.model_url ? (
                      <Link to={a.model_url}>
                        {a.model_type}
                        {a.model_name && ` (${a.model_name})`}
                      </Link>
                    ) : (
                      <>
                        {a.model_type}
                        {a.model_name && ` (${a.model_name})`}
                      </>
                    )}
                    .
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    ))}
    <button
      disabled={isFetchingMore}
      className="btn w-full btn-solid"
      onClick={() => fetchMoreActivity()}>
      {isFetchingMore ? <Trans>Loading...</Trans> : <Trans>Older</Trans>}
    </button>
  </div>
);

export default Dashboard;
