import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Link, Redirect } from "react-router-dom";
import { ApiContext } from "../../contexts/ApiContext";
import { login as attemptLogin, me } from "../../api";
import logo from "../../assets/logo-sm.png";
import VerifyEmailNotification from "../../components/VerifyEmailNotification";
import PasswordInput from "../../components/PasswordInput";

class Login extends Component {
  static contextType = ApiContext;

  state = { email: "", password: "", isLoading: false };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  login = e => {
    e.preventDefault();
    const { callApi, setApiState, setLanguage } = this.context;
    this.safeSetState({ isLoading: true });
    callApi(() => attemptLogin(this.state))
      .then(res => {
        localStorage.setItem("INDIER_JWT", res.data.token);
        localStorage.removeItem("INDIER_VERIFY_EMAIL");

        callApi(me)
          .then(res => {
            setApiState("user", res.data);
            setLanguage(res.data.locale ? res.data.locale : "en");
            this.safeSetState({ isLoading: false });
          })
          .catch(e => {
            toast.error(e);
            this.safeSetState({ isLoading: false });
          });
      })
      .catch(e => {
        console.log("%cLogin error", "color: #3490dc; font-weight: bold", e);
        let msg = <Trans>Login error.</Trans>;

        if (e.response && e.response.data && e.response.data.error) {
          msg = e.response.data.error;
        }

        if (typeof e === "string") {
          msg = e;
        }

        this.safeSetState({ isLoading: false });
        toast.error(msg);
      });
  };

  render() {
    const { email, password, isLoading } = this.state;
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { user } = this.context;

    if (user && localStorage.getItem("INDIER_JWT")) {
      return <Redirect to={from} />;
    }

    return (
      <div className="full-screen-form-container">
        <div className="full-screen-form">
          <div className="w-full text-center">
            <img className="mb-8" src={logo} alt="" style={{ maxWidth: 200 }} />
          </div>
          <div className="form-no-flex">
            <form
              method="POST"
              onSubmit={this.login}
              className="pb-4 mb-4 border-b border-grey-darker">
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Email</Trans>
                </label>
                <input
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  type="email"
                  className="w-full form-input"
                  autoComplete="email"
                  disabled={isLoading}
                />
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Password</Trans>
                </label>
                <PasswordInput
                  value={password}
                  onChange={e => this.setState({ password: e.target.value })}
                  autoComplete="current-password"
                  className="w-full form-input"
                  disabled={isLoading}
                />
              </div>
              <button disabled={isLoading} type="submit" className="w-full mb-2 btn btn-blue">
                {isLoading ? <Trans>Logging In...</Trans> : <Trans>Login</Trans>}
              </button>
              <div className="text-sm text-right">
                <Link to="/forgot" className="text-grey">
                  <Trans>Forgotten your password?</Trans>
                </Link>
              </div>
            </form>

            <div className="flex">
              <Link className="flex-1 btn" to="/register">
                <Trans>Register</Trans>
              </Link>
            </div>
          </div>
        </div>
        <VerifyEmailNotification
          email={email}
          show={localStorage.getItem("INDIER_VERIFY_EMAIL") ? true : false}
        />
      </div>
    );
  }
}

export default Login;
