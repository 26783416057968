import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import moment from "moment";
import LoadingWrapper from "../components/LoadingWrapper";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, createResource } from "../api";
import history from "../components/history";

class ParticipantCreateForm extends Component {
  static contextType = ApiContext;
  state = {
    schools: [],
    schoolId: "",
    id: null,
    isLoadingSchools: false,
    showChangeUserForm: false,
    full_name: "",
    email: "",
    phone: "",
    message: "",
    verified_at: null,
    isVerifyingEmail: false,
    participants: [],
    trips: [],
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchClient();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchClient();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchSchools = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingSchools: true });
    callApi(() => fetchResources("schools?all"))
      .then(({ data }) => {
        this.safeSetState({
          schools: data,
          isLoadingSchools: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoadingSchools: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchClient = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources("clients/" + id))
      .then(({ data }) => {
        this.safeSetState({
          id,
          full_name: data.full_name,
          email: data.email,
          phone: data.phone,
          verified_at: data.verified_at,
          participants: data.participants,
          trips: data.trips,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  verifyEmail = () => {
    this.safeSetState({ isVerifyingEmail: true });
    this.context
      .callApi(() => createResource("manually-verify", { email: this.state.email }))
      .then(({ data }) => {
        this.safeSetState({ isVerifyingEmail: false, verified_at: moment().toISOString() });
        toast.success(<Trans>Email verified.</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isVerifyingEmail: false, error });
        toast.error(<Trans>Failed to verify email.</Trans>);
      });
  };

  handleMakeTeacher = () => {
    const { id, schoolId } = this.state;

    if (!schoolId) {
      return toast.error("Please select a school.");
    }

    this.safeSetState({ isMakingTeacher: true });
    this.context
      .callApi(() => createResource("make-teacher", { client_id: id, school_slug: schoolId }))
      .then(({ data: { data } }) => {
        this.safeSetState({ isMakingTeacher: false });
        toast.success(<Trans>Email verified.</Trans>);
        history.push(`/teachers/${data.id}`);
      })
      .catch(error => {
        this.safeSetState({ isMakingTeacher: false, error });
        toast.error(<Trans>Failed to make teacher.</Trans>);
      });
  };

  render() {
    const {
      isLoadingSchools,
      schoolId,
      schools,
      full_name,
      showChangeUserForm,
      email,
      phone,
      verified_at,
      participants,
      isLoading,
      error,
      message,
      isVerifyingEmail,
      trips
    } = this.state;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Client Details</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} message={message}>
              <div className="form-container form-read-only">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Info</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{full_name}</span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Email</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Phone</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">
                      {phone ? <a href={`tel:${phone}`}>{phone}</a> : <Trans>None</Trans>}
                    </span>
                  </div>
                </div>
                {this.context.user.role === "admin" ? (
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Email Verified</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full form-input">
                        {verified_at ? moment(verified_at).fromNow() : <Trans>Unverified</Trans>}
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Participants</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">
                      {!participants.length && (
                        <div>
                          <Trans>None</Trans>
                        </div>
                      )}
                      {participants.map(p => (
                        <div key={p.id} className="mb-1">
                          <Link to={`/participants/${p.id}`}>{p.full_name}</Link>
                        </div>
                      ))}
                    </span>
                  </div>
                </div>

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Registered Trips</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Trip Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">
                      {!trips.length && (
                        <div>
                          <Trans>None</Trans>
                        </div>
                      )}
                      {trips.map((trip, index) => (
                        <div key={index} className="mb-1">
                          <Link to={`/trips/${trip.id}`} className="mr-2">
                            {trip.trip_name}
                          </Link>{" "}
                          (
                          <Link to={`/participants/${trip.participant_id}`}>
                            {trip.participant_full_name}
                          </Link>
                          )
                          {trip.payment_status !== "unpaid" ? (
                            <span
                              className={`ml-2 text-${
                                trip.payment_status === "cash" || trip.payment_status === "prepaid"
                                  ? "orange"
                                  : trip.payment_status === "alipay"
                                  ? "blue"
                                  : "green"
                              }`}>
                              <Icon icon={trip.payment_status} />
                              <br />
                              <span className="text-grey-darker">
                                (
                                {trip.payment_status === "prepaid" ? (
                                  <Trans>Prepaid</Trans>
                                ) : (
                                  moment(trip.payment_timestamp).format("LLL")
                                )}
                                )
                              </span>
                            </span>
                          ) : (
                            <span className="ml-2 text-red">
                              <Icon icon="exclamation" />
                            </span>
                          )}
                        </div>
                      ))}
                    </span>
                  </div>
                </div>

                {user.role === "admin" || user.role === "manager" ? (
                  <div className="flex items-center p-4">
                    {verified_at === null ? (
                      <button
                        disabled={isVerifyingEmail}
                        className="block ml-auto btn btn-solid-blue"
                        onClick={() => this.verifyEmail()}>
                        {isVerifyingEmail ? (
                          <Trans>Verifying email...</Trans>
                        ) : (
                          <Trans>Manually Verify Email</Trans>
                        )}
                      </button>
                    ) : null}

                    {showChangeUserForm ? null : (
                      <button
                        type="button"
                        onClick={e =>
                          this.setState({ showChangeUserForm: true }, this.fetchSchools)
                        }
                        className="btn btn-solid-teal">
                        Change User Type
                      </button>
                    )}
                    {showChangeUserForm ? (
                      <div className="flex flex-col items-baseline w-full sm:flex-row">
                        <LoadingWrapper isLoading={isLoadingSchools}>
                          <select
                            value={schoolId}
                            onChange={e => this.setState({ schoolId: e.target.value })}
                            className="flex-1 block w-full p-2 text-sm leading-tight bg-white border rounded shadow appearance-none text-grey-darker sm:w-auto">
                            <option value="" disabled>
                              Select School
                            </option>
                            {schools.map(school => (
                              <option value={school.id} key={school.id}>
                                {school.name}
                              </option>
                            ))}
                          </select>
                          <button
                            type="button"
                            onClick={e => this.setState({ showChangeUserForm: false })}
                            className="w-full mt-2 sm:ml-2 sm:w-auto sm:mt-0 btn btn-solid-red">
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={this.handleMakeTeacher}
                            className="block w-full mt-2 sm:w-auto sm:ml-2 sm:mt-0 btn btn-solid-teal">
                            Make Teacher
                          </button>
                        </LoadingWrapper>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ParticipantCreateForm;
