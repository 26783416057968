import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const SubscriberIndex = props => (
  <div>
    <ResourceIndex
      endpoint="newsletters"
      model={<Trans>Subscribers</Trans>}
      headers={["full_name", "email"]}
      translations={[<Trans>Name</Trans>, <Trans>Email</Trans>]}
      actions={[]}
      canSearch={false}
      {...props}
    />
  </div>
);

export default SubscriberIndex;
