import React from "react";
import ResourceEdit from "./ResourceEdit";

const SchoolCreate = props => (
  <div>
    <ResourceEdit isCreate={true} {...props} endpoint="schools" model="School" />
  </div>
);

export default SchoolCreate;
