import React, { Component } from "react";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
// import moment from "moment";
import history from "../components/history";
// import DeleteButton from "../components/DeleteButton";
// import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { updateResource } from "../api";
import Link from "../../node_modules/react-router-dom/Link";

class PaymentConfirm extends Component {
  static contextType = ApiContext;
  state = {
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.confirmPayment();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  confirmPayment = () => {
    const { callApi } = this.context;
    const params = this.props.location.search.substring(1);
    let payload = null;

    console.log(params);

    try {
      payload = JSON.parse(
        '{"' + params.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
        function (key, value) {
          return key === "" ? value : decodeURIComponent(value);
        }
      );
    } catch (error) {
      this.safeSetState({ error });
      toast.error(<Trans>Failed to confirm payment</Trans>);
      return;
    }

    callApi(() => updateResource(`/verify-payment/${payload.out_trade_no}`, payload))
      .then(({ data }) => {
        history.push(`/my-payments`);
        toast.success(<Trans>Payment verified!</Trans>);
      })
      .catch(error => {
        this.safeSetState({
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        history.push(`/my-payments`);
        // toast.error(<Trans>Payment failed.</Trans>);
      });
  };

  render() {
    if (this.state.error) {
      return (
        <div className="main-page-container">
          <div className="p-2 md:p-8">
            <div className="p-2 text-xl font-light text-white rounded opacity-75 bg-red">
              <Trans>Unable to confirm payment.</Trans>
              <div className="mt-2 text-sm">
                <Link to="/my-payments" className="text-white hover:text-red-darkest">
                  <Trans>
                    Click to view your payments and click &apos;Query&apos; to attempt payment
                    confirmation.
                  </Trans>
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <h1 className="p-2 mb-2 text-xl font-light text-white rounded opacity-75 bg-teal">
            <Trans>Verifying payment...</Trans>
          </h1>
        </div>
      </div>
    );
  }
}

export default PaymentConfirm;
