import React from "react";
import { Route, Redirect } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";

class TeamLeaderOnlyRoute extends React.Component {
  static contextType = ApiContext;

  render() {
    const { component: Component, ...rest } = this.props;
    const { user } = this.context;

    if (
      !user ||
      (user &&
        (user.role !== "manager") & (user.role !== "team_leader") &&
        user.role !== "admin") ||
      !localStorage.getItem("INDIER_JWT")
    ) {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location }
              }}
            />
          )}
        />
      );
    }

    if (!Component) return <Route {...rest} />;

    return <Route {...rest} render={props => <Component {...props} />} />;
  }
}

export default TeamLeaderOnlyRoute;
