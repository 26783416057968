import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import Icon from "../components/Icon";
import DeleteButton from "../components/DeleteButton";

class Gallery extends Component {
  static propTypes = {
    images: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        filename: PropTypes.string.isRequired
      })
    ).isRequired,
    canDelete: PropTypes.bool.isRequired,
    handleDelete: PropTypes.func.isRequired
  };

  static defaultProps = {
    canDelete: false,
    handleDelete: () => {}
  };

  state = {
    currentImage: null
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  nextImage = () => {
    const { currentImage } = this.state;
    const { images } = this.props;
    const currentIndex = images.findIndex(i => i.id === currentImage.id);
    if (currentIndex === images.length - 1) {
      return this.setState({ currentImage: images[0] });
    }
    return this.setState({ currentImage: images[currentIndex + 1] });
  };

  prevImage = () => {
    const { currentImage } = this.state;
    const { images } = this.props;
    const currentIndex = images.findIndex(i => i.id === currentImage.id);
    if (currentIndex === 0) {
      return this.setState({ currentImage: images[images.length - 1] });
    }
    return this.setState({ currentImage: images[currentIndex - 1] });
  };

  showGallery = image => {
    document.addEventListener("keydown", this.handleKeyPress, false);
    this.setState({ currentImage: image });
  };

  closeGallery = () => {
    document.removeEventListener("keydown", this.handleKeyPress, false);
    this.setState({ currentImage: null });
  };

  handleKeyPress = e => {
    switch (e.keyCode) {
      case 27: // Escape
        this.closeGallery();
        break;
      case 39: // Right Arrow
        this.nextImage();
        break;
      case 37: // Left Arrow
        this.prevImage();
        break;
      default:
        break;
    }
  };

  render() {
    const { currentImage } = this.state;
    const { canDelete, images, handleDelete } = this.props;
    return (
      <div className="flex flex-wrap items-center justify-start">
        {!images.length ? (
          <div className="p-2 bg-white w-full rounded text-sm">
            <Trans>No images.</Trans>
          </div>
        ) : null}
        {images.map(image => (
          <div
            key={image.id}
            className="gallery-image"
            style={{
              height: 100,
              width: 100,
              backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/images/${
                image.id
              }?thumb&token=${localStorage.getItem("INDIER_JWT")})`
            }}>
            {canDelete ? (
              <div className="pin-r pin-t absolute p-2 text-red cursor-pointer hover:text-red-dark transition">
                <DeleteButton onDelete={() => handleDelete(image)} url={`images/${image.id}`}>
                  <Icon icon="delete" />
                </DeleteButton>
              </div>
            ) : null}
            <span
              onClick={() => this.showGallery(image)}
              className="transition opacity-50 cursor-pointer hover:opacity-75 text-teal">
              <Icon icon="play" size={40} />
            </span>
          </div>
        ))}
        {currentImage ? (
          <div className="z-20 fixed pin" style={{ backgroundColor: "rgba(0,0,0,.8)" }}>
            <div
              className="fixed pin m-16 bg-contain bg-center bg-no-repeat"
              style={{
                backgroundImage: `url(${process.env.REACT_APP_API_BASE_URL}/images/${
                  currentImage.id
                }?token=${localStorage.getItem("INDIER_JWT")})`
              }}
            />

            <span
              className="cursor-pointer pin-t pin-r flex items-center justify-center absolute p-4 text-white hover:text-grey-light"
              onClick={this.closeGallery}>
              <Icon size={24} icon="close" />
            </span>

            {images.length > 1 ? (
              <>
                <span
                  onClick={this.nextImage}
                  className="mt-16 cursor-pointer pin-y flex items-center justify-center absolute pin-r p-4 text-white hover:text-grey-light">
                  <Icon size={40} icon="chevron-right" />
                </span>
                <span
                  onClick={this.prevImage}
                  className="mt-16 cursor-pointer pin-y flex items-center justify-center absolute pin-l p-4 text-white hover:text-grey-light">
                  <Icon size={40} icon="chevron-left" />
                </span>
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default Gallery;
