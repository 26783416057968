import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";
import Icon from "../components/Icon";

class ResourceShow extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    isCreate: PropTypes.bool.isRequired
  };
  static contextType = ApiContext;
  static defaultProps = { isCreate: false };
  state = {
    item: null,
    risk_assessments: [],
    name: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.isCreate) return;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    const { callApi } = this.context;
    const {
      endpoint,
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources(endpoint + "/" + id))
      .then(({ data }) => {
        this.safeSetState({
          name: data.name,
          risk_assessments: data.risk_assessments ? data.risk_assessments : [],
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const {
      endpoint,
      match: {
        params: { id }
      },
      model
    } = this.props;
    const { user } = this.context;
    const { name, isLoading, risk_assessments } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {model} <Trans>Details</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} retry={() => this.fetchItem(endpoint)}>
              <div className="form-container form-read-only">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Info</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full leading-normal">{name}</span>
                  </div>
                </div>
                {endpoint === "locations" ? (
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Risk Assessments</Trans>
                    </label>
                    <div className="w-full">
                      {risk_assessments.length ? (
                        <table>
                          <tbody>
                            {risk_assessments.map(risk => (
                              <tr key={risk.id}>
                                <td className="pb-2 pr-4">
                                  {user.locale !== "en" && risk.zh_name ? risk.zh_name : risk.name}
                                </td>
                                <td className="pb-2 pr-2">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="no-underline no-external text-grey hover:text-blue"
                                    href={`${process.env.REACT_APP_API_BASE_URL}/risk_assessments/${
                                      risk.id
                                    }/pdf?token=${localStorage.getItem("INDIER_JWT")}`}>
                                    <Icon icon="download" size={16} /> (EN)
                                  </a>
                                </td>
                                <td className="pb-2 pr-2">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="no-underline no-external text-grey hover:text-blue"
                                    href={`${process.env.REACT_APP_API_BASE_URL}/risk_assessments/${
                                      risk.id
                                    }/pdf?lang=zh&token=${localStorage.getItem("INDIER_JWT")}`}>
                                    <Icon icon="download" size={16} /> (中文)
                                  </a>
                                </td>
                                <td className="pb-2 pr-2">
                                  <Link
                                    className="no-underline text-grey hover:text-teal mr-2"
                                    to={`/risk_assessments/${risk.id}/edit`}>
                                    <Icon icon="edit" size={16} />
                                  </Link>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <span className="form-input w-full leading-normal">
                          <Trans>No risk assessments for this location.</Trans>
                        </span>
                      )}
                    </div>
                  </div>
                ) : null}
                <div className="form-actions">
                  <Link className="btn btn-solid-blue block ml-auto" to={`/${endpoint}/${id}/edit`}>
                    <Trans>Edit</Trans>
                  </Link>
                </div>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ResourceShow;
