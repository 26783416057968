import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import moment from "moment";
import Icon from "../components/Icon";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class ParticipantCreateForm extends Component {
  static contextType = ApiContext;
  state = {
    family_name: "",
    given_names: "",
    full_name: "",
    message: "",
    physical_status: 0,
    gender: "",
    country_name: "",
    grade: "",
    id_number: "",
    id_expiry: "",
    id_type: "",
    physicals: [
      { name: "poor", trans: "差" },
      { name: "fair", trans: "一般" },
      { name: "good", trans: "良好" },
      { name: "excellent", trans: "非常好" }
    ],
    dob: null,
    allergies: "",
    diet: "",
    medication: "",
    additional_medical: "",
    trips: [],
    medical_conditions: [],
    medical_conditions_list: [],
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_street_address: "",
    emergency_contact_city: "",
    emergency_contact_phone: "",
    isLoading: false,
    isSubmitting: false,
    account_full_name: null,
    account_url: null,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchParticipant();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchParticipant();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchParticipant = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources("participants/" + id))
      .then(({ data }) => {
        this.safeSetState({
          family_name: data.family_name,
          given_names: data.given_names,
          full_name: data.full_name,
          country_name: data.country_name,
          physical_status: data.physical_status,
          error: data.error,
          dob: data.dob,
          id_expiry: data.id_expiry ? moment.utc(data.id_expiry).format("LL") : "",
          id_type: data.id_type,
          account_full_name: data.account_full_name,
          account_url: data.account_url,
          allergies: data.allergies,
          diet: data.diet,
          trips: data.trips,
          medication: data.medication,
          additional_medical: data.additional_medical,
          medical_conditions: data.medical_conditions,
          medical_conditions_list: data.medical_conditions_list,
          emergency_contact_name: data.emergency_contact_full_name,
          emergency_contact_relationship: data.emergency_contact_relationship,
          emergency_contact_street_address: data.emergency_contact_street_address,
          emergency_contact_city: data.emergency_contact_city,
          emergency_contact_phone: data.emergency_contact_phone,
          gender: data.gender,
          grade: data.grade,
          id_number: data.id_number,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const {
      given_names,
      full_name,
      physical_status,
      physicals,
      error,
      dob,
      id_type,
      country_name,
      id_expiry,
      gender,
      grade,
      id_number,
      allergies,
      diet,
      medication,
      additional_medical,
      medical_conditions,
      medical_conditions_list,
      message,
      isLoading,
      trips,
      account_url,
      account_full_name,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Participant Details</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} error={error} message={message}>
              {given_names ? (
                <div className="form-container form-read-only">
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Basic Info</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{full_name}</span>
                    </div>
                  </div>
                  {account_url && account_full_name ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Account</Trans>
                      </label>
                      <div className="w-full">
                        <span className="form-input w-full">
                          <Link to={account_url}>{account_full_name}</Link>
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Gender</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{gender}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Date of Birth</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">
                        {moment.utc(dob).format("LL")} ({moment().diff(moment(dob), "years", false)}{" "}
                        <Trans>years old</Trans>)
                      </span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Nationality</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{country_name}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Type</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">
                        {!id_type ? (
                          <Trans>None</Trans>
                        ) : id_type === "passport" ? (
                          <Trans>Passport</Trans>
                        ) : id_type === "id_card" ? (
                          <Trans>ID Card (Chinese Mainland)</Trans>
                        ) : (
                          <Trans>Travel Document (Hong Kong/Macau/Taiwan)</Trans>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Number</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{id_number}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{id_expiry}</span>
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Class or Home Room</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{grade}</span>
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Medical</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Physical Condition</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">
                        {user.locale === "en"
                          ? physicals[physical_status].name
                          : physicals[physical_status].trans}
                      </span>
                    </div>
                  </div>
                  {diet ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Dietary Requirements</Trans>
                      </label>
                      <div className="w-full">
                        <span className="form-input w-full">{diet}</span>
                      </div>
                    </div>
                  ) : null}

                  {allergies ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Allergies</Trans>
                      </label>
                      <div className="w-full">
                        <span className="form-input w-full">{allergies}</span>
                      </div>
                    </div>
                  ) : null}

                  {medication ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Medication</Trans>
                      </label>
                      <div className="w-full">
                        <span className="form-input w-full">{medication}</span>
                      </div>
                    </div>
                  ) : null}

                  {additional_medical ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Additional Medical Info</Trans>
                      </label>
                      <div className="w-full">
                        <span className="form-input w-full">{additional_medical}</span>
                      </div>
                    </div>
                  ) : null}

                  {medical_conditions.length ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Medical Conditions</Trans>
                      </label>
                      <div className="w-full">
                        {medical_conditions.map(med => (
                          <div key={med} className="text-sm mb-1">
                            <span className="form-input w-full">
                              {user.locale === "en"
                                ? medical_conditions_list.filter(m => m.id === med)[0].name
                                : medical_conditions_list.filter(m => m.id === med)[0].translation}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Emergency Contact</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{emergency_contact_name}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Relationship</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{emergency_contact_relationship}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Street Address</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{emergency_contact_street_address}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>City</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">{emergency_contact_city}</span>
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">
                        <a href={`tel:${emergency_contact_phone}`}>{emergency_contact_phone}</a>
                      </span>
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Registered Trips</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Trip Name</Trans>
                    </label>
                    <div className="w-full">
                      <span className="form-input w-full">
                        {!trips.length && (
                          <div>
                            <Trans>None</Trans>
                          </div>
                        )}
                        {trips.map((trip, index) => (
                          <div key={index} className="mb-1">
                            <Link className="mr-2" to={`/trips/${trip.id}`}>
                              {trip.trip_name}
                            </Link>
                            (
                            <a
                              target="_blank"
                              className="no-external"
                              rel="noopener noreferrer"
                              download
                              href={`${process.env.REACT_APP_API_BASE_URL}/waiver/${
                                trip.id
                              }/${id}?token=${localStorage.getItem("INDIER_JWT")}`}>
                              <Trans>View Waiver</Trans>
                            </a>
                            )
                            {trip.payment_status !== "unpaid" ? (
                              <span
                                className={`ml-2 text-${
                                  trip.payment_status === "cash" ||
                                  trip.payment_status === "prepaid"
                                    ? "orange"
                                    : trip.payment_status === "alipay"
                                    ? "blue"
                                    : "green"
                                }`}>
                                <Icon icon={trip.payment_status} />

                                <br />
                                <span className="text-grey-darker">
                                  (
                                  {trip.payment_status === "prepaid" ? (
                                    <Trans>Prepaid</Trans>
                                  ) : (
                                    moment(trip.payment_timestamp).format("LLL")
                                  )}
                                  )
                                </span>
                              </span>
                            ) : (
                              <span className="text-red ml-2">
                                <Icon icon="exclamation" />
                              </span>
                            )}
                          </div>
                        ))}
                      </span>
                    </div>
                  </div>

                  {user.role === "manager" || user.role === "admin" || user.type === "Client" ? (
                    <div className="form-actions">
                      <Link
                        className="btn btn-solid-blue block ml-auto"
                        to={`/participants/${id}/edit`}>
                        <Trans>Edit</Trans>
                      </Link>
                    </div>
                  ) : null}
                </div>
              ) : null}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ParticipantCreateForm;
