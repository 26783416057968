import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import LoadingWrapper from "../components/LoadingWrapper";
import history from "../components/history";
import { createResource, fetchResources, updateResource } from "../api";
import { i18n } from "../App";

class HazardCreate extends Component {
  static contextType = ApiContext;
  state = {
    risk_level: 1,
    severity: 1,
    name: "",
    staff_mitigation: "",
    equipment_mitigation: "",
    environment_mitigation: "",
    zh_name: "",
    zh_staff_mitigation: "",
    zh_equipment_mitigation: "",
    zh_environment_mitigation: "",
    isLoading: false,
    isSubmitting: false,
    tab: 0
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      this.fetchHazard();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchHazard = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources(`hazards/${id}`))
      .then(({ data }) => {
        this.safeSetState({
          risk_level: data.risk_level,
          severity: data.severity,
          name: data.name,
          staff_mitigation: data.staff_mitigation,
          equipment_mitigation: data.equipment_mitigation,
          environment_mitigation: data.environment_mitigation,
          zh_name: data.zh_name,
          zh_staff_mitigation: data.zh_staff_mitigation,
          zh_equipment_mitigation: data.zh_equipment_mitigation,
          zh_environment_mitigation: data.zh_environment_mitigation,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch hazard.</Trans>);
        }
        toast.error(error);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      return this.handleUpdate();
    }
    let {
      risk_level,
      severity,
      name,
      staff_mitigation,
      equipment_mitigation,
      environment_mitigation,
      zh_name,
      zh_staff_mitigation,
      zh_equipment_mitigation,
      zh_environment_mitigation
    } = this.state;

    this.safeSetState({ isSubmitting: true, error: null });

    let data = {
      name,
      risk_level,
      severity,
      staff_mitigation,
      equipment_mitigation,
      environment_mitigation,
      zh_name,
      zh_staff_mitigation,
      zh_equipment_mitigation,
      zh_environment_mitigation
    };

    this.context
      .callApi(() => createResource("/hazards", data))
      .then(({ data }) => {
        this.safeSetState({ isSubmitting: false });
        history.push(`/hazards/${data.id}/edit`);
        toast.success(<Trans>Hazard created!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create hazard.</Trans>);
      });
  };

  handleUpdate = () => {
    let {
      name,
      risk_level,
      severity,
      staff_mitigation,
      equipment_mitigation,
      environment_mitigation,
      zh_name,
      zh_staff_mitigation,
      zh_equipment_mitigation,
      zh_environment_mitigation
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    let data = {
      name,
      risk_level,
      severity,
      staff_mitigation,
      equipment_mitigation,
      environment_mitigation,
      zh_name,
      zh_staff_mitigation,
      zh_equipment_mitigation,
      zh_environment_mitigation
    };
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.context
      .callApi(() => updateResource("/hazards/" + id, data))
      .then(() => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Hazard updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create hazard.</Trans>);
      });
  };

  render() {
    let {
      name,
      tab,
      risk_level,
      severity,
      staff_mitigation,
      equipment_mitigation,
      environment_mitigation,
      zh_name,
      zh_staff_mitigation,
      zh_equipment_mitigation,
      zh_environment_mitigation,
      isLoading,
      isSubmitting,
      error
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {id ? <Trans>Update Hazard</Trans> : <Trans>Create a Hazard</Trans>}
            </h1>
            <LoadingWrapper isLoading={isLoading}>
              <>
                <div className="flex">
                  <div
                    onClick={() => this.setState({ tab: 0 })}
                    className={`${
                      tab === 0 ? "bg-white hover:text-orange" : "bg-grey-light hover:text-orange"
                    } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                    English
                  </div>
                  <div
                    onClick={() => this.setState({ tab: 1 })}
                    className={`${
                      tab === 1 ? "bg-white hover:text-orange" : "bg-grey-light hover:text-orange"
                    } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                    中文
                  </div>
                </div>
                <div className="form-container">
                  <form action="#" onSubmit={this.handleSubmit}>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Name</Trans>
                      </label>
                      {tab === 0 ? (
                        <div className="w-full">
                          <input
                            type="text"
                            disabled={isSubmitting}
                            className={`${
                              error && error.name ? "border-red" : ""
                            } form-input w-full`}
                            value={name}
                            onChange={e =>
                              this.safeSetState({
                                name: e.target.value
                              })
                            }
                          />
                          {error && error.name && (
                            <span className="text-red text-xs block mt-1">{error.name}</span>
                          )}
                        </div>
                      ) : (
                        <div className="w-full">
                          <input
                            type="text"
                            disabled={isSubmitting}
                            className={`${
                              error && error.zh_name ? "border-red" : ""
                            } form-input w-full`}
                            value={zh_name || ""}
                            onChange={e =>
                              this.safeSetState({
                                zh_name: e.target.value
                              })
                            }
                          />
                          {error && error.zh_name && (
                            <span className="text-red text-xs block mt-1">{error.zh_name}</span>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Risk Level (1-10)</Trans>
                      </label>
                      <div className="w-full">
                        <input
                          type="number"
                          min={1}
                          max={10}
                          step={1}
                          disabled={isSubmitting}
                          className={`${
                            error && error.risk_level ? "border-red" : ""
                          } form-input w-full`}
                          value={risk_level}
                          onChange={e =>
                            this.safeSetState({
                              risk_level: e.target.value
                            })
                          }
                        />
                        {error && error.risk_level && (
                          <span className="text-red text-xs block mt-1">{error.risk_level}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Severity</Trans>
                      </label>
                      <div className="w-full">
                        <select
                          disabled={isSubmitting}
                          value={severity}
                          className={`${
                            error && error.severity ? "border-red" : ""
                          } form-input w-full`}
                          onChange={e =>
                            this.safeSetState({
                              severity: parseInt(e.target.value, 10)
                            })
                          }>
                          <option value="1">{i18n._(t`Low`)}</option>
                          <option value="2">{i18n._(t`Medium`)}</option>
                          <option value="3">{i18n._(t`High`)}</option>
                        </select>
                        {error && error.severity && (
                          <span className="text-red text-xs block mt-1">{error.severity}</span>
                        )}
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Staff Mitigation</Trans>
                      </label>
                      {tab === 0 ? (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.staff_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                staff_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={staff_mitigation}
                          />
                          {error && error.staff_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.staff_mitigation}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.zh_staff_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                zh_staff_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={zh_staff_mitigation || ""}
                          />
                          {error && error.zh_staff_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.zh_staff_mitigation}
                            </span>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Equipment Mitigation</Trans>
                      </label>
                      {tab === 0 ? (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.equipment_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                equipment_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={equipment_mitigation}
                          />
                          {error && error.equipment_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.equipment_mitigation}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.zh_equipment_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                zh_equipment_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={zh_equipment_mitigation || ""}
                          />
                          {error && error.zh_equipment_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.zh_equipment_mitigation}
                            </span>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Environment Mitigation</Trans>
                      </label>
                      {tab === 0 ? (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.environment_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                environment_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={environment_mitigation}
                          />
                          {error && error.environment_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.environment_mitigation}
                            </span>
                          )}
                        </div>
                      ) : (
                        <div className="w-full">
                          <textarea
                            rows="5"
                            className={`${
                              error && error.zh_environment_mitigation ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                zh_environment_mitigation: e.target.value
                              })
                            }
                            disabled={isSubmitting}
                            value={zh_environment_mitigation || ""}
                          />
                          {error && error.zh_environment_mitigation && (
                            <span className="text-red text-xs block mt-1">
                              {error.zh_environment_mitigation}
                            </span>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="form-actions border-t">
                      <Link
                        to="/risk_assessments/hazards"
                        style={{ minWidth: "150px" }}
                        type="submit"
                        className="btn w-full btn-solid-red">
                        <Trans>Cancel</Trans>
                      </Link>
                      {process.env.NODE_ENV === "development" ? (
                        <span
                          onClick={() =>
                            this.setState({
                              name: "New Hazard",
                              zh_name: "ZH - New Hazard",
                              risk_level: 5,
                              severity: 2,
                              staff_mitigation: "STAFF MITIGATION",
                              equipment_mitigation: "EQUIPMENT MITIGATION",
                              environment_mitigation: "ENVIRONMENT MITIGATION"
                            })
                          }
                          className="btn mx-auto">
                          <Trans>Generate</Trans>
                        </span>
                      ) : null}
                      <button
                        style={{ minWidth: "150px" }}
                        type="submit"
                        className="ml-auto w-full btn btn-solid-blue"
                        disabled={isSubmitting}>
                        {isSubmitting ? (
                          id ? (
                            <Trans>Updating...</Trans>
                          ) : (
                            <Trans>Creating...</Trans>
                          )
                        ) : id ? (
                          <Trans>Update Hazard</Trans>
                        ) : (
                          <Trans>Create Hazard</Trans>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default HazardCreate;
