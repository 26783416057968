import React from "react";
import { ApiConsumer } from "../contexts/ApiContext";
import StaffEditForm from "../pages/StaffEditForm";
import ClientTeacherProfile from "../pages/ClientTeacherProfile";

const Profile = props => (
  <ApiConsumer>
    {({ user }) => {
      if (user.type === "Staff") {
        return <StaffEditForm {...props} isProfile={true} />;
      }
      return <ClientTeacherProfile {...props} />;
    }}
  </ApiConsumer>
);

export default Profile;
