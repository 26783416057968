import React, { Component } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/zh-cn";
import history from "../components/history";
import { updateLocale } from "../api";

let ApiContext;
const { Consumer, Provider } = (ApiContext = React.createContext());

class ApiProvider extends Component {
  state = {
    isLoading: false,
    error: null,
    cookiesDisabled: false,
    user: null,
    language: "en"
  };

  setApiState = (key, value) => {
    this.setState({ [key]: value });
  };

  setLanguage = lang => {
    if (!this.state.cookiesDisabled) {
      localStorage.setItem("INDIER_LANG", lang);
    }
    this.setApiState("language", lang);
    if (lang && lang !== "en") {
      moment.locale("zh-cn");
      moment.updateLocale("zh-cn", {
        longDateFormat: {
          L: "YYYY年MM月",
          LLL: "YYYY年MM月D日 H:m:s"
        }
      });
    } else {
      moment.locale("en");
      moment.updateLocale("en", {
        longDateFormat: {
          L: "MMMM YYYY",
          LLL: "D, MMMM YYYY H:m:s"
        }
      });
    }
    if (this.state.user) {
      this.setState(prevState => ({
        user: { ...prevState.user, locale: lang }
      }));
      if (this.state.user.locale !== lang) {
        this.callApi(() => updateLocale(lang));
      }
    }
  };

  logout = () => {
    localStorage.removeItem("INDIER_JWT");
    this.setState({ user: null }, () => history.push("/login"));
  };

  callApi = api => {
    toast.dismiss();
    this.setState({ error: null });
    return api()
      .then(res => {
        process.env.NODE_ENV === "development" &&
          console.log(
            "%cAPI success",
            "color: #fff; font-weight: bold; background: #F6993F; padding: 2px 4px; border-radius: 2px;",
            res
          );
        this.setState({ error: null });
        return res;
      })
      .catch(error => {
        process.env.NODE_ENV === "development" &&
          console.warn("%cAPI failed", "color: #f6993f; font-weight: bold", error);
        this.setState({ error });

        if (error.error) {
          throw error.error;
        }

        throw error;
      });
  };

  render() {
    const { isLoading, error, user, language, cookiesDisabled } = this.state;

    return (
      <Provider
        value={{
          isLoading,
          language,
          setLanguage: this.setLanguage,
          error,
          user,
          cookiesDisabled,
          callApi: this.callApi,
          setApiState: this.setApiState,
          logout: this.logout
        }}>
        {this.props.children}
      </Provider>
    );
  }
}

export { ApiContext, ApiProvider, Consumer as ApiConsumer };
