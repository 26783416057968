import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import logo from "../../assets/logo-sm.png";
import { verifyEmail as attemptVerifyEmail } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import history from "../../components/history";

class VerifyEmail extends Component {
  static contextType = ApiContext;
  state = { token: "", email: "", error: null, isLoading: false };

  componentDidMount() {
    this._isMounted = true;
    const { token, email } = this.props.match.params;
    if (token && email) {
      this.safeSetState({ token, email });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  verifyEmail = e => {
    e.preventDefault();
    const { callApi } = this.context;
    const { token, email } = this.state;
    this.safeSetState({ error: null, isLoading: true });
    callApi(() => attemptVerifyEmail({ token, email }))
      .then(res => {
        this.safeSetState({ isLoading: false });
        localStorage.removeItem("INDIER_VERIFY_EMAIL");
        toast.success(res.data.message);
        history.push("/login");
      })
      .catch(e => {
        this.safeSetState({ error: e, isLoading: false });
        let msg = e;
        if (typeof e === "object") {
          msg = e[Object.keys(e)[0]];
        }
        toast.error(msg);
      });
  };

  render() {
    const { email, token, error, isLoading } = this.state;

    return (
      <div className="full-screen-form-container">
        <div className="full-screen-form">
          <div className="text-center w-full">
            <img className="mb-8" src={logo} alt="" style={{ maxWidth: 200 }} />
          </div>
          <div className="form-no-flex">
            <form method="POST" onSubmit={this.verifyEmail} className="">
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Verification Token</Trans>
                </label>
                <input
                  value={token}
                  onChange={e => this.setState({ token: e.target.value })}
                  type="text"
                  disabled={isLoading}
                  className="form-input w-full"
                />
                {error && error.errors && error.errors.token && (
                  <p className="text-red text-sm mt-2">{error.errors.token[0]}</p>
                )}
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Email</Trans>
                </label>
                <input
                  value={email}
                  onChange={e => this.setState({ email: e.target.value })}
                  type="email"
                  disabled={isLoading}
                  className="form-input w-full"
                />
                {error && error.errors && error.errors.email && (
                  <p className="text-red text-sm mt-2">{error.errors.email[0]}</p>
                )}
              </div>
              <button disabled={isLoading} type="submit" className="btn btn-teal w-full mb-2">
                {isLoading ? <Trans>Verifying Email...</Trans> : <Trans>Verify Email</Trans>}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
