import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
// import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
// import history from "../components/history";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class FeedbackShow extends Component {
  static contextType = ApiContext;
  state = {
    created_at: null,
    description: null,
    email: null,
    image: null,
    full_name: null,
    id: null,
    title: null,
    userable_id: null,
    userable_type: null,
    isLoading: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchIssue();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchIssue = () => {
    this.setState({ isLoading: true, error: null });
    const { id } = this.props.match.params;

    this.context
      .callApi(() => fetchResources(`feedback/${id}/`))
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          issue: data,
          closed_at: data.closed_at,
          created_at: data.created_at,
          description: data.description,
          email: data.email,
          image: data.image,
          full_name: data.full_name,
          id: data.id,
          trip: data.trip,
          title: data.title,
          userable_id: data.userable_id,
          userable_type: data.userable_type
            ? data.userable_type.replace("App\\", "").toLowerCase() + "s"
            : null
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });
      });
  };

  render() {
    const {
      created_at,
      description,
      email,
      id,
      trip,
      title,
      image,
      full_name,
      userable_type,
      userable_id,
      isLoading
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Feedback Detail</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading}>
              {id && (
                <div className="form-container p-4">
                  <div className="">
                    <div className="mb-4 pb-4 border-b">
                      <div className="mb-2 text-sm text-grey-dark leading-normal">
                        <h2>{full_name}</h2>
                        <a
                          className="text-grey-dark no-underline hover:text-grey-darker"
                          href={`mailto:${email}?subject=RE: ${title}`}>
                          {email}
                        </a>{" "}
                        opened this issue {moment(created_at).fromNow()}
                      </div>

                      <div className="text-sm mb-2">
                        <Link className="btn btn-xs mr-2 btn-grey" to={`/feedback`}>
                          <Trans>Back to Index</Trans>
                        </Link>
                        <Link className="btn btn-xs mr-2" to={`/${userable_type}/${userable_id}`}>
                          <Trans>View User</Trans>
                        </Link>
                        {trip ? (
                          <Link className="btn btn-blue btn-xs mr-2" to={`/trips/${trip.id}`}>
                            <Trans>View Trip</Trans>
                          </Link>
                        ) : null}
                      </div>
                    </div>
                    <div className="pb-4 leading-tight">{description}</div>

                    {image ? (
                      <div className="">
                        <img
                          className="rounded"
                          src={`${process.env.REACT_APP_API_BASE_URL}/images/${
                            image.id
                          }?token=${localStorage.getItem("INDIER_JWT")}`}
                          alt=""
                        />
                      </div>
                    ) : null}
                  </div>
                </div>
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default FeedbackShow;
