import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import moment from "moment";
import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
// import { i18n } from "../App";
import DeleteButton from "../components/DeleteButton";
import MediaUpload from "../components/MediaUpload";
import Wysiwyg from "../components/Wysiwyg";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource, deleteResource } from "../api";

export const skill_levels = [
  {
    name: "Level 1 – Can assist with group management during activity",
    trans: "1 级 – 可以在活动期间协助小组管理"
  },
  { name: "Level 2 – Can assist on activity", trans: "2 级 – 可以协助活动" },
  { name: "Level 3 – Can assist with activity set up", trans: "3 级 – 可以协助安排活动" },
  { name: "Level 4 – Can lead activity", trans: "4 级 – 可以领导活动" },
  {
    name: "Level 5 – Can teach and qualify others on leveling",
    trans: "5 级 – 可以教授其他人并使其具备升级资格"
  }
];

class StaffEditForm extends Component {
  static contextType = ApiContext;
  state = {
    bio: "",
    dob: "",
    email: "",
    avatar: "",
    gender: "",
    id_expiry: "",
    id_number: "",
    id_type: "",
    job_title: "",
    family_name: "",
    given_names: "",
    notes: "",
    phone: "",
    role: "",
    staff_id: "",
    user_id: "",
    visa_duration: "",
    visa_expires_at: "",
    visa_last_entry: "",
    skillLevels: skill_levels,
    roles: {
      admin: { name: "Admin", trans: "管理员" },
      manager: { name: "Manager", trans: "主管" },
      team_leader: { name: "Team Leader", trans: "小组主管" },
      staff: { name: "Staff", trans: "员工" },
      teacher: { name: "Teacher", trans: "教师" },
      client: { name: "Client", trans: "客户" }
    },
    allergies: "",
    diet: "",
    medication: "",
    additional_medical: "",
    medical_conditions: [],
    medical_conditions_list: [],
    skills: [],
    certificates: [],
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_street_address: "",
    emergency_contact_city: "",
    emergency_contact_phone: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleCertificateUpload = data => {
    console.debug("handling cert upload", data);

    this.safeSetState(prevState => ({
      certificates: prevState.certificates.concat(data)
    }));
  };

  handleImageUpload = data => {
    this.safeSetState({ avatar: `${data}?${new Date().getTime()}` });
    if (parseInt(this.context.user.userable_id, 10) === parseInt(this.props.match.params.id, 10)) {
      this.context.setApiState("user", {
        ...this.context.user,
        avatar: `${data}?${new Date().getTime()}`
      });
    }
  };

  fetchItem = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      },
      isProfile
    } = this.props;
    this.safeSetState({ isLoading: true });
    const url = isProfile ? "profile" : "staffs/" + id;
    callApi(() => fetchResources(url))
      .then(({ data }) => {
        this.safeSetState({
          bio: data.bio,
          dob: moment.utc(data.dob).format("YYYY-MM-DD"),
          avatar: data.avatar ? `${data.avatar}?${new Date().getTime()}` : null,
          email: data.email,
          gender: data.gender,
          id_expiry: data.id_expiry ? moment.utc(data.id_expiry).format("YYYY-MM-DD") : "",
          id_number: data.id_number,
          id_type: data.id_type,
          job_title: data.job_title,
          family_name: data.family_name || "",
          given_names: data.given_names,
          notes: data.notes,
          phone: data.phone,
          role: data.role,
          staff_id: data.staff_id,
          user_id: data.user_id,
          visa_duration: data.visa_duration,
          visa_expires_at: data.visa_expires_at
            ? moment.utc(data.visa_expires_at).format("YYYY-MM-DD")
            : "",
          visa_last_entry: data.visa_last_entry
            ? moment.utc(data.visa_last_entry).format("YYYY-MM-DD")
            : "",
          allergies: data.allergies,
          diet: data.diet,
          medication: data.medication,
          additional_medical: data.additional_medical,
          medical_conditions: data.medical_conditions,
          medical_conditions_list: data.medical_conditions_list,
          skills: data.skills,
          certificates: data.certificates,
          emergency_contact_name: data.emergency_contact_name,
          emergency_contact_relationship: data.emergency_contact_relationship,
          emergency_contact_street_address: data.emergency_contact_street_address,
          emergency_contact_city: data.emergency_contact_city,
          emergency_contact_phone: data.emergency_contact_phone,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleMedicalUpdate = id => {
    this.safeSetState(prevState => ({
      medical_conditions: prevState.medical_conditions.includes(id)
        ? prevState.medical_conditions.filter(med => med !== id)
        : prevState.medical_conditions.concat(id)
    }));
  };

  handleUpdate = e => {
    e.preventDefault();
    const { callApi } = this.context;
    const {
      bio,
      dob,
      email,
      avatar,
      gender,
      id_expiry,
      id_number,
      id_type,
      job_title,
      family_name,
      given_names,
      notes,
      phone,
      role,
      staff_id,
      visa_duration,
      visa_expires_at,
      visa_last_entry,
      allergies,
      diet,
      medication,
      additional_medical,
      medical_conditions,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      skills,
      certificates
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    callApi(() =>
      updateResource(`/staffs/${staff_id}`, {
        bio,
        dob,
        email,
        gender,
        avatar,
        id_expiry,
        id_number,
        id_type,
        job_title,
        family_name,
        given_names,
        notes,
        phone,
        role,
        visa_duration,
        visa_expires_at,
        visa_last_entry,
        allergies,
        diet,
        medication,
        additional_medical,
        medical_conditions,
        emergency_contact_name,
        emergency_contact_relationship,
        emergency_contact_street_address,
        emergency_contact_city,
        emergency_contact_phone,
        skills,
        certificates
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleSkillChange = (e, skill) => {
    e.persist();
    this.setState(prevState => ({
      skills: prevState.skills.map(s => {
        if (s.id === skill.id) {
          return {
            ...s,
            level: e.target.value
          };
        }
        return s;
      })
    }));
  };

  handleDelete = () => {
    history.push("/staffs");
  };

  deleteCertificate = id => {
    var confirm = window.confirm("Are you sure you want to delete this certificate?");
    if (!confirm) return;

    console.debug("deleting certificate.", id);

    deleteResource(`/certificates/${id}`)
      .then(res => {
        console.debug("cert delete response", res);
        this.safeSetState(prevState => ({
          certificates: prevState.certificates.filter(cert => cert.id !== id)
        }));
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { user } = this.context;
    const {
      bio,
      dob,
      email,
      gender,
      id_expiry,
      id_number,
      id_type,
      skillLevels,
      job_title,
      avatar,
      family_name,
      given_names,
      notes,
      phone,
      role,
      staff_id,
      user_id,
      visa_duration,
      visa_expires_at,
      visa_last_entry,
      error,
      isSubmitting,
      roles,
      allergies,
      diet,
      medication,
      additional_medical,
      medical_conditions,
      medical_conditions_list,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      skills,
      certificates,
      isLoading
    } = this.state;
    const { locale } = this.context.user;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Profile</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} retry={this.fetchItem}>
              <div className="form-container">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Info</Trans>
                </div>

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Avatar</Trans>
                  </label>
                  <div className="w-full">
                    {avatar ? (
                      <div>
                        <img className="profile-avatar" src={avatar} alt="Staff" />
                      </div>
                    ) : (
                      <div className="mb-4 text-grey">
                        <Icon icon="user-square" size={300} />
                      </div>
                    )}
                    <MediaUpload
                      onSuccess={this.handleImageUpload}
                      url={`/avatar/${user_id}`}
                      accept="image/*"
                    />
                    {error && error.avatar && (
                      <span className="text-xs text-red">{error.avatar}</span>
                    )}
                  </div>
                </div>
                <form method="POST" onSubmit={this.handleUpdate}>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Family Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={family_name}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            family_name: e.target.value
                          })
                        }
                      />
                      {error && error.family_name && (
                        <span className="text-xs text-red">{error.family_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Given Names</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={given_names}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            given_names: e.target.value
                          })
                        }
                      />
                      {error && error.given_names && (
                        <span className="text-xs text-red">{error.given_names}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Email</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={email}
                        disabled
                        onChange={e => this.safeSetState({ email: e.target.value })}
                      />
                      {error && error.email && (
                        <span className="text-xs text-red">{error.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone Number</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        value={phone}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e => this.safeSetState({ phone: e.target.value })}
                      />
                      {error && error.phone && (
                        <span className="text-xs text-red">{error.phone}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Date of Birth</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="date"
                        value={dob}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e => this.safeSetState({ dob: e.target.value })}
                      />
                      {error && error.dob && <span className="text-xs text-red">{error.dob}</span>}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Gender</Trans>
                    </label>
                    <div className="w-full">
                      <label className="cursor-pointer">
                        <Trans>Male</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="gender"
                          checked={gender === "male"}
                          value="male"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              gender: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="cursor-pointer">
                        <Trans>Female</Trans>
                        <input
                          className="ml-2"
                          type="radio"
                          name="gender"
                          checked={gender === "female"}
                          value="female"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              gender: e.target.value
                            })
                          }
                        />
                      </label>
                      {error && error.gender && (
                        <span className="text-xs text-red">{error.gender}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Bio</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        rows={5}
                        className="w-full p-2 form-input"
                        disabled={isSubmitting}
                        value={bio}
                        onChange={e => this.setState({ bio: e.target.value })}
                      />
                      {error && error.bio && <span className="text-xs text-red">{error.bio}</span>}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Certificates</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Upload Certificate</Trans>
                    </label>
                    <MediaUpload
                      url={`staff/${user.userable_id}/certificates`}
                      onSuccess={this.handleCertificateUpload}
                    />
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Current Certificates</Trans>
                    </label>
                    <div className="w-full">
                      {!certificates.length && <div>No certificates uploaded</div>}
                      {certificates.map(cert => (
                        <div key={cert.id} className="mb-1 text-sm">
                          <label className="cursor-pointer">
                            <a
                              download
                              className="mx-2 mr-2 no-underline no-external text-grey hover:text-blue"
                              href={`${process.env.REACT_APP_API_BASE_URL}/certificates/${
                                cert.id
                              }?token=${localStorage.getItem("INDIER_JWT")}`}>
                              <span className="inline-block mr-1">
                                {cert.name ? cert.name : cert.filename}
                              </span>
                              <Icon icon="download" size={16} />
                            </a>
                            <button
                              onClick={() => this.deleteCertificate(cert.id)}
                              type="button"
                              className="text-red">
                              <Icon icon="trash" size={16} />
                            </button>
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>ID</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Type</Trans>
                    </label>
                    <div className="w-full">
                      <label className="block mb-2 text-sm cursor-pointer md:inline-block md:mb-0">
                        <Trans>Passport</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="id_type"
                          checked={id_type === "passport"}
                          value="passport"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="block mb-2 text-sm cursor-pointer md:inline-block md:mb-0">
                        <Trans>ID Card (Mainland/HK)</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="id_type"
                          checked={id_type === "id_card"}
                          value="id_card"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="block mb-2 text-sm cursor-pointer md:inline-block md:mb-0">
                        <Trans>Travel Document (Taiwan)</Trans>
                        <input
                          className="ml-2"
                          type="radio"
                          name="id_type"
                          checked={id_type === "travel_document"}
                          value="travel_document"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      {error && error.id_type && (
                        <span className="block mt-1 text-xs text-red">{error.id_type}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Number</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        value={id_number}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e => this.safeSetState({ id_number: e.target.value })}
                      />
                      {error && error.id_number && (
                        <span className="text-xs text-red">{error.id_number}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="date"
                        value={id_expiry}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e => this.safeSetState({ id_expiry: e.target.value })}
                      />
                      {error && error.id_expiry && (
                        <span className="text-xs text-red">{error.id_expiry}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Visa</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Single Entry Duration (days)</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="number"
                        step={1}
                        min={0}
                        value={visa_duration}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            visa_duration: e.target.value
                          })
                        }
                      />
                      {error && error.visa_duration && (
                        <span className="text-xs text-red">{error.visa_duration}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Visa Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="date"
                        value={visa_expires_at}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            visa_expires_at: e.target.value
                          })
                        }
                      />
                      {error && error.visa_expires_at && (
                        <span className="text-xs text-red">{error.visa_expires_at}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Visa Last Entry</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="date"
                        value={visa_last_entry}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            visa_last_entry: e.target.value
                          })
                        }
                      />
                      {error && error.visa_last_entry && (
                        <span className="text-xs text-red">{error.visa_last_entry}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Medical</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Dietary Requirements</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        value={diet}
                        rows={5}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            diet: e.target.value
                          })
                        }
                      />
                      {error && error.diet && (
                        <span className="text-xs text-red">{error.diet}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Allergies</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        value={allergies}
                        rows={5}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            allergies: e.target.value
                          })
                        }
                      />
                      {error && error.allergies && (
                        <span className="text-xs text-red">{error.allergies}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Medication</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        value={medication}
                        rows={5}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            medication: e.target.value
                          })
                        }
                      />
                      {error && error.medication && (
                        <span className="text-xs text-red">{error.medication}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Additional Medical Info</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        value={additional_medical}
                        rows={5}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            additional_medical: e.target.value
                          })
                        }
                      />
                      {error && error.additional_medical && (
                        <span className="text-xs text-red">{error.additional_medical}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Medical Conditions</Trans>
                    </label>
                    <div className="w-full">
                      {medical_conditions_list.map(med => (
                        <div key={med.id} className="mb-1 text-sm">
                          <label className="cursor-pointer">
                            <input
                              type="checkbox"
                              onChange={() => this.handleMedicalUpdate(med.id)}
                              checked={medical_conditions.includes(med.id)}
                              className="mr-2"
                            />
                            {this.context.user.locale === "en" ? med.name : med.translation}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Emergency Contact</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={emergency_contact_name}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_name: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Relationship</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={emergency_contact_relationship}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_relationship: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Street Address</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={emergency_contact_street_address}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_street_address: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>City</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={emergency_contact_city}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_city: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={emergency_contact_phone}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_phone: e.target.value
                          })
                        }
                      />
                    </div>
                  </div>

                  {(user.role === "manager" || user.role === "admin") && (
                    <>
                      <div className="text-white form-section-header border-pink-dark bg-pink">
                        <Trans>Viewable by Managers Only</Trans>
                      </div>
                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Roles &amp; Permissions</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Job Title</Trans>
                        </label>
                        <div className="w-full">
                          <input
                            className="w-full form-input"
                            type="text"
                            value={job_title}
                            disabled={isSubmitting}
                            onChange={e =>
                              this.safeSetState({
                                job_title: e.target.value
                              })
                            }
                          />
                          {error && error.job_title && (
                            <span className="text-xs text-red">{error.job_title}</span>
                          )}
                        </div>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Role (permissions)</Trans>
                        </label>
                        <div className="w-full">
                          <select
                            value={role}
                            disabled={isSubmitting}
                            className="w-full form-input"
                            onChange={e =>
                              this.safeSetState({
                                role: e.target.value
                              })
                            }>
                            {Object.keys(roles).map((role, index) => (
                              <option key={index} value={role}>
                                {locale === "en" ? roles[role].name : roles[role].trans}
                              </option>
                            ))}
                          </select>
                          {error && error.role && (
                            <span className="text-xs text-red">{error.role}</span>
                          )}
                        </div>
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Skills</Trans>
                      </div>
                      <div className="p-4 text-sm border-b">
                        <p>
                          In order to reach any level you must have a wilderness aid qualification
                          as well as been signed off by one of Indier co-ordinator team.
                        </p>

                        <p className="mt-2">
                          <strong>
                            Level 1 – Can assist with group management during activity
                          </strong>
                        </p>
                        <p>
                          Guide must display an awareness of your group as well as be able to give
                          instruction to students if they are misbehaving or taking risks
                        </p>

                        <p className="mt-2">
                          <strong>Level 2 – Can assist on activity</strong>
                        </p>
                        <p>
                          Guide must display an understanding of the activity as well as the hazards
                          that involved, and show a competency in the skills required in the
                          activity
                        </p>

                        <p className="mt-2">
                          <strong>Level 3 – Can assist with activity set up</strong>
                        </p>
                        <p>
                          Guide must have an intimate knowledge and required skills to perform the
                          necessary tasks required
                        </p>

                        <p className="mt-2">
                          <strong>Level 4 – Can lead activity</strong>
                        </p>
                        <p>
                          Guide not only has the required knowledge and skills but also has enough
                          experience and confidence to explain to the participants how the activity
                          will be run
                        </p>

                        <p className="mt-2">
                          <strong>Level 5 – Can teach and qualify others on leveling</strong>
                        </p>
                        <p>
                          Guide intimately understands the activity, through constant experience is
                          able to teach other guides how to do the activity and as such can also
                          grade them.
                        </p>
                      </div>
                      {skills.map(skill => (
                        <div key={skill.id} className="form-input-group">
                          <label className="form-label">{skill.name}</label>
                          <div className="w-full">
                            <select
                              value={skill.level}
                              disabled={isSubmitting}
                              className="w-full form-input"
                              onChange={e => this.handleSkillChange(e, skill)}>
                              {skillLevels.map((level, index) => (
                                <option value={index} key={index}>
                                  {locale === "en" ? level.name : level.trans}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      ))}

                      <div className="form-section-header bg-grey-lighter">Notes</div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Notes</Trans>
                        </label>
                        <div className="w-full">
                          <Wysiwyg
                            classes="p-2"
                            toolbarConfig={{
                              options: ["inline", "list", "history"],
                              inline: {
                                inDropdown: false,
                                options: ["bold", "italic", "underline"]
                              },
                              list: {
                                inDropdown: false,
                                options: ["unordered", "ordered"]
                              }
                            }}
                            disabled={isSubmitting}
                            content={notes}
                            updateContent={notes => this.setState({ notes })}
                          />
                          {error && error.notes && (
                            <span className="text-xs text-red">{error.notes}</span>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-actions">
                    <DeleteButton url={`staffs/${staff_id}`} onDelete={this.handleDelete}>
                      <button type="button" className="btn btn-solid-red">
                        <Trans>Delete</Trans>
                      </button>
                    </DeleteButton>
                    <Link className="btn btn-solid-teal" to={`/staffs/${staff_id}`}>
                      <Trans>View</Trans>
                    </Link>
                    <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                      {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                    </button>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default StaffEditForm;
