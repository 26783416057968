import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PageHeader from "../components/PageHeader";

class Terms extends Component {
  render() {
    return (
      <div>
        <PageHeader title={<Trans>Terms &amp; Conditions</Trans>} />
        <div className="container py-8">
          <h1>Coming soon!</h1>
        </div>
      </div>
    );
  }
}

export default Terms;
