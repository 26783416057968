import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import Icon from "../components/Icon";
import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
import { createResource, fetchResources, updateResource } from "../api";
import { i18n } from "../App";

class RiskAssessment extends Component {
  static contextType = ApiContext;
  state = {
    locations: [],
    hazard_list: [],
    hazards: [],
    name: "",
    specific_intro: "",
    additional_intro: "",
    special_weather: "",
    emergency_action_plan: "",
    zh_name: "",
    zh_specific_intro: "",
    zh_additional_intro: "",
    zh_special_weather: "",
    zh_emergency_action_plan: "",
    location_id: "",
    isLoadingLocations: false,
    isLoadingHazards: false,
    isLoading: false,
    tab: 0,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { id, copy_id }
      }
    } = this.props;
    console.log("copy id", copy_id);
    if (id || copy_id) {
      this.fetchRiskAssessment();
    }
    this.fetchLocations();
    this.fetchHazards();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id === undefined && this.props.match.params.id !== undefined) {
      this.fetchRiskAssessment();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchRiskAssessment = () => {
    const {
      match: {
        params: { id, copy_id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources(`risk_assessments/${id||copy_id}`))
      .then(({ data: { data } }) => {
        this.safeSetState({
          name: data.name,
          additional_intro: data.additional_intro,
          specific_intro: data.specific_intro,
          special_weather: data.special_weather,
          emergency_action_plan: data.emergency_action_plan,
          zh_name: data.zh_name,
          zh_specific_intro: data.zh_specific_intro,
          zh_additional_intro: data.zh_additional_intro,
          zh_special_weather: data.zh_special_weather,
          zh_emergency_action_plan: data.zh_emergency_action_plan,
          hazards: data.hazards,
          location_id: data.location_id,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch risk assessment.</Trans>);
        }
        toast.error(error);
      });
  };

  fetchLocations = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingLocations: true });
    callApi(() => fetchResources("locations/?all"))
      .then(({ data }) => {
        this.safeSetState({
          locations: data,
          isLoadingLocations: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingLocations: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchHazards = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingHazards: true });
    callApi(() => fetchResources("hazards/?all"))
      .then(({ data }) => {
        this.safeSetState({
          hazard_list: data,
          isLoadingHazards: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingHazards: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleHazardUpdate = haz => {
    this.setState(prevState => ({
      hazards: prevState.hazards.filter(hazard => hazard.id === haz.id).length
        ? prevState.hazards.filter(hazard => hazard.id !== haz.id)
        : prevState.hazards.concat(haz)
    }));
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      return this.handleUpdate();
    }
    let {
      name,
      special_weather,
      emergency_action_plan,
      specific_intro,
      additional_intro,
      zh_name,
      zh_specific_intro,
      zh_special_weather,
      zh_emergency_action_plan,
      zh_additional_intro,
      location_id,
      hazards
    } = this.state;

    this.safeSetState({ isSubmitting: true, error: null });

    let data = {
      name,
      special_weather,
      emergency_action_plan,
      additional_intro,
      specific_intro,
      zh_name,
      zh_special_weather,
      zh_specific_intro,
      zh_emergency_action_plan,
      zh_additional_intro,
      location_id,
      hazards
    };

    this.context
      .callApi(() => createResource("/risk_assessments", data))
      .then(
        ({
          data: {
            data: { id }
          }
        }) => {
          this.safeSetState({ isSubmitting: false });
          history.push(`/risk_assessments/${id}/edit`);
          toast.success(<Trans>Risk assessment created!</Trans>);
        }
      )
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create risk assessment.</Trans>);
      });
  };

  handleUpdateHazrdDetail = (id, type, value) => {
    this.setState(prevState => ({
      hazards: prevState.hazards.map(h => {
        if (id === h.id) {
          return { ...h, [type]: value };
        }
        return h;
      })
    }));
  };

  handleUpdate = () => {
    let {
      name,
      special_weather,
      specific_intro,
      emergency_action_plan,
      additional_intro,
      zh_name,
      zh_specific_intro,
      zh_special_weather,
      zh_emergency_action_plan,
      zh_additional_intro,
      location_id,
      hazards
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    let data = {
      name,
      specific_intro,
      special_weather,
      emergency_action_plan,
      additional_intro,
      zh_name,
      zh_specific_intro,
      zh_special_weather,
      zh_emergency_action_plan,
      zh_additional_intro,
      location_id,
      hazards
    };
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.context
      .callApi(() => updateResource("/risk_assessments/" + id, data))
      .then(() => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Risk assessment updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create risk assessment.</Trans>);
      });
  };

  render() {
    let {
      name,
      tab,
      locations,
      additional_intro,
      specific_intro,
      special_weather,
      emergency_action_plan,
      zh_name,
      zh_special_weather,
      zh_specific_intro,
      zh_emergency_action_plan,
      zh_additional_intro,
      location_id,
      hazards,
      hazard_list,
      isLoading,
      isLoadingHazards,
      isLoadingLocations,
      isSubmitting,
      error
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {id ? <Trans>Update Risk Assessment</Trans> : <Trans>Create a Risk Assessment</Trans>}
            </h1>

            <LoadingWrapper isLoading={isLoading || isLoadingLocations || isLoadingHazards}>
              {!isLoading && !isLoadingHazards ? (
                <>
                  {" "}
                  <div className="flex">
                    <div
                      onClick={() => this.setState({ tab: 0 })}
                      className={`${
                        tab === 0 ? "bg-white hover:text-orange" : "bg-grey-light hover:text-orange"
                      } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                      English
                    </div>
                    <div
                      onClick={() => this.setState({ tab: 1 })}
                      className={`${
                        tab === 1 ? "bg-white hover:text-orange" : "bg-grey-light hover:text-orange"
                      } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                      中文
                    </div>
                  </div>
                  <div
                    className="form-container"
                    style={{ borderRadius: "0 .25rem .25rem .25rem " }}>
                    <form action="#" onSubmit={this.handleSubmit}>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Name</Trans>
                        </label>
                        {tab === 0 ? (
                          <div className="w-full">
                            <input
                              type="text"
                              disabled={isSubmitting}
                              className={`${
                                error && error.name ? "border-red" : ""
                              } form-input w-full`}
                              value={name}
                              onChange={e =>
                                this.safeSetState({
                                  name: e.target.value
                                })
                              }
                            />
                            {error && error.name && (
                              <span className="block mt-1 text-xs text-red">{error.name}</span>
                            )}
                          </div>
                        ) : (
                          <div className="w-full">
                            <input
                              type="text"
                              disabled={isSubmitting}
                              className={`${
                                error && error.zh_name ? "border-red" : ""
                              } form-input w-full`}
                              value={zh_name}
                              onChange={e =>
                                this.safeSetState({
                                  zh_name: e.target.value
                                })
                              }
                            />
                            {error && error.zh_name && (
                              <span className="block mt-1 text-xs text-red">{error.zh_name}</span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Location</Trans>
                        </label>
                        <div className="w-full">
                          <select
                            disabled={!locations.length || isSubmitting}
                            value={location_id}
                            className={`${
                              error && error.risk_level ? "border-red" : ""
                            } form-input w-full`}
                            onChange={e =>
                              this.safeSetState({
                                location_id: parseInt(e.target.value, 10)
                              })
                            }>
                            <option value="">-- {i18n._(t`Select a Location`)} --</option>
                            {locations.map((location, index) => (
                              <option key={index} value={location.id}>
                                {location.name}
                              </option>
                            ))}
                          </select>
                          {error && error.location_id && (
                            <span className="block mt-1 text-xs text-red">{error.location_id}</span>
                          )}
                        </div>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>
                            Specific Introduction
                            <br />
                            (Overrides general intro)
                          </Trans>
                          <br />(<Trans>Optional</Trans>)
                        </label>
                        {tab === 0 ? (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.specific_intro ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  specific_intro: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={specific_intro || ""}
                            />
                            {error && error.specific_intro && (
                              <span className="block mt-1 text-xs text-red">
                                {error.specific_intro}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.zh_specific_intro ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  zh_specific_intro: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={zh_specific_intro || ""}
                            />
                            {error && error.zh_specific_intro && (
                              <span className="block mt-1 text-xs text-red">
                                {error.zh_specific_intro}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Introductory Text</Trans>
                          <br />(<Trans>Optional</Trans>)
                        </label>
                        {tab === 0 ? (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.additional_intro ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  additional_intro: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={additional_intro || ""}
                            />
                            {error && error.additional_intro && (
                              <span className="block mt-1 text-xs text-red">
                                {error.additional_intro}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.zh_additional_intro ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  zh_additional_intro: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={zh_additional_intro || ""}
                            />
                            {error && error.zh_additional_intro && (
                              <span className="block mt-1 text-xs text-red">
                                {error.zh_additional_intro}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Special Weather Concerns</Trans>
                          <br />(<Trans>Optional</Trans>)
                        </label>
                        {tab === 0 ? (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.special_weather ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  special_weather: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={special_weather || ""}
                            />
                            {error && error.special_weather && (
                              <span className="block mt-1 text-xs text-red">
                                {error.special_weather}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.zh_special_weather ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  zh_special_weather: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={zh_special_weather || ""}
                            />
                            {error && error.zh_special_weather && (
                              <span className="block mt-1 text-xs text-red">
                                {error.zh_special_weather}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Emergency Action Plan</Trans>
                          <br />(<Trans>Optional</Trans>)
                        </label>
                        {tab === 0 ? (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.emergency_action_plan ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  emergency_action_plan: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={emergency_action_plan || ""}
                            />
                            {error && error.emergency_action_plan && (
                              <span className="block mt-1 text-xs text-red">
                                {error.emergency_action_plan}
                              </span>
                            )}
                          </div>
                        ) : (
                          <div className="w-full">
                            <textarea
                              rows="5"
                              className={`${
                                error && error.zh_emergency_action_plan ? "border-red" : ""
                              } form-input w-full`}
                              onChange={e =>
                                this.safeSetState({
                                  zh_emergency_action_plan: e.target.value
                                })
                              }
                              disabled={isSubmitting}
                              value={zh_emergency_action_plan || ""}
                            />
                            {error && error.zh_emergency_action_plan && (
                              <span className="block mt-1 text-xs text-red">
                                {error.zh_emergency_action_plan}
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Hazards</Trans>
                        </label>
                        <div className="w-full">
                          {hazard_list.map(hazard => (
                            <div key={hazard.id} className="mb-1 text-sm">
                              <label className="cursor-pointer">
                                <input
                                  type="checkbox"
                                  onChange={() => this.handleHazardUpdate(hazard)}
                                  checked={hazards.filter(h => h.id === hazard.id).length}
                                  className="mr-2"
                                />
                                {tab === 0 ? hazard.name : hazard.zh_name}
                              </label>
                            </div>
                          ))}
                          {error && error.hazards && (
                            <span className="block mt-1 text-xs text-red">{error.hazards}</span>
                          )}
                        </div>
                      </div>

                      {hazards.length ? (
                        <div className="form-input-group">
                          <label className="form-label">
                            <Trans>Site-specific Hazards</Trans>
                          </label>
                          <div className="w-full">
                            {hazards.map(hazard => (
                              <div
                                key={hazard.id}
                                className="mb-4 text-sm border rounded bg-grey-lighter">
                                <h3 className="p-4 border-b">
                                  {tab === 0 ? hazard.name : hazard.zh_name}
                                </h3>

                                <div className="flex p-4 border-b">
                                  <div className="flex-1 mr-2">
                                    <label className="block mb-1 text-sm font-bold">
                                      <Trans>Risk Level (1-10)</Trans>
                                    </label>
                                    <input
                                      type="number"
                                      min={1}
                                      value={hazard.risk_level}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "risk_level",
                                          e.target.value
                                        )
                                      }
                                      max={10}
                                      step={1}
                                      className="w-full form-input"
                                    />
                                  </div>
                                  <div className="flex-1">
                                    <label className="block mb-1 text-sm font-bold">
                                      <Trans>Severity</Trans>
                                    </label>
                                    <select
                                      value={hazard.severity}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "severity",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input">
                                      <option value={1}>{i18n._(t`Low`)}</option>
                                      <option value={2}>{i18n._(t`Medium`)}</option>
                                      <option value={3}>{i18n._(t`High`)}</option>
                                    </select>
                                  </div>
                                </div>

                                <div className="p-4 border-b">
                                  <label className="block mb-1 text-sm font-bold">
                                    <Trans>Staff</Trans>
                                  </label>
                                  <p className="mb-4 leading-tight">
                                    {tab === 0
                                      ? hazard_list.filter(h => h.id === hazard.id)[0]
                                          .staff_mitigation
                                      : hazard_list.filter(h => h.id === hazard.id)[0]
                                          .zh_staff_mitigation}
                                  </p>
                                  <label className="block mb-1 text-xs font-bold">
                                    <Trans>Additional</Trans> (<Trans>Optional</Trans>)
                                  </label>
                                  {tab === 0 ? (
                                    <textarea
                                      rows={5}
                                      value={hazard.additional_staff_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "additional_staff_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  ) : (
                                    <textarea
                                      rows={5}
                                      value={hazard.zh_additional_staff_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "zh_additional_staff_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  )}
                                </div>

                                <div className="p-4 border-b">
                                  <label className="block mb-1 text-sm font-bold">
                                    <Trans>Equipment</Trans>
                                  </label>
                                  <p className="mb-4 leading-tight">
                                    {tab === 0
                                      ? hazard_list.filter(h => h.id === hazard.id)[0]
                                          .equipment_mitigation
                                      : hazard_list.filter(h => h.id === hazard.id)[0]
                                          .zh_equipment_mitigation}
                                  </p>
                                  <label className="block mb-1 text-xs font-bold">
                                    <Trans>Additional</Trans> (<Trans>Optional</Trans>)
                                  </label>
                                  {tab === 0 ? (
                                    <textarea
                                      rows={5}
                                      value={hazard.additional_equipment_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "additional_equipment_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  ) : (
                                    <textarea
                                      rows={5}
                                      value={hazard.zh_additional_equipment_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "zh_additional_equipment_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  )}
                                </div>

                                <div className="p-4">
                                  <label className="block mb-1 text-sm font-bold">
                                    <Trans>Environment</Trans>
                                  </label>
                                  <p className="mb-4 leading-tight">
                                    {tab === 0
                                      ? hazard_list.filter(h => h.id === hazard.id)[0]
                                          .environment_mitigation
                                      : hazard_list.filter(h => h.id === hazard.id)[0]
                                          .zh_environment_mitigation}
                                  </p>
                                  <label className="block mb-1 text-xs font-bold">
                                    <Trans>Additional</Trans> (<Trans>Optional</Trans>)
                                  </label>
                                  {tab === 0 ? (
                                    <textarea
                                      rows={5}
                                      value={hazard.additional_environment_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "additional_environment_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  ) : (
                                    <textarea
                                      rows={5}
                                      value={hazard.zh_additional_environment_mitigation || ""}
                                      onChange={e =>
                                        this.handleUpdateHazrdDetail(
                                          hazard.id,
                                          "zh_additional_environment_mitigation",
                                          e.target.value
                                        )
                                      }
                                      className="w-full form-input"
                                    />
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ) : null}

                      <div className="border-t form-actions">
                        <Link
                          to="/risk_assessments"
                          style={{ minWidth: "150px" }}
                          type="submit"
                          className="w-full btn btn-solid-red">
                          <Trans>Cancel</Trans>
                        </Link>
                        {id ? (
                          <>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-2 mr-2 text-sm text-center no-underline border rounded btn-solid-pink no-external text-grey hover:text-white transition"
                              href={`${
                                process.env.REACT_APP_API_BASE_URL
                              }/risk_assessments/${id}/pdf?token=${localStorage.getItem(
                                "INDIER_JWT"
                              )}`}>
                              <Icon icon="download" size={16} /> (EN)
                            </a>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              className="p-2 mr-2 text-sm text-center no-underline border rounded btn-solid-pink no-external text-grey hover:text-white transition"
                              href={`${
                                process.env.REACT_APP_API_BASE_URL
                              }/risk_assessments/${id}/pdf?lang=zh&token=${localStorage.getItem(
                                "INDIER_JWT"
                              )}`}>
                              <Icon icon="download" size={16} /> (中文)
                            </a>
                          </>
                        ) : null}
                        {process.env.NODE_ENV === "development" ? (
                          <span
                            onClick={() =>
                              this.setState({
                                name: "New Risk Assessment",
                                additional_intro: "ADDITIONAL INTRO",
                                special_weather: "SPECIAL WEATHER",
                                emergency_action_plan: "EMERGECNY ACTION PLAN",
                                location_id: 1
                              })
                            }
                            className="mx-auto btn">
                            <Trans>Generate</Trans>
                          </span>
                        ) : null}
                        <button
                          style={{ minWidth: "150px" }}
                          type="submit"
                          className="w-full ml-auto btn btn-solid-blue"
                          disabled={isSubmitting}>
                          {isSubmitting ? (
                            id ? (
                              <Trans>Updating...</Trans>
                            ) : (
                              <Trans>Creating...</Trans>
                            )
                          ) : id ? (
                            <Trans>Update Risk Assessment</Trans>
                          ) : (
                            <Trans>Create Risk Assessment</Trans>
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </>
              ) : null}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default RiskAssessment;
