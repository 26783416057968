import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import HazardIndex from "../components/HazardIndex";
import RiskAssessmentIndex from "../components/RiskAssessmentIndex";
import RiskAssessmentTemplate from "../components/RiskAssessmentTemplate";
import { ApiConsumer } from "../contexts/ApiContext";

const RiskAssessment = ({ match: { path } }) => (
  <div className="main-page-container">
    <div className="p-2 md:p-8">
      <div>
        <h1 className="page-header">
          <Trans>Risk Assessments</Trans>
        </h1>
      </div>
      <ApiConsumer>
        {({ user }) => (
          <div className="">
            {user.role === "admin" || user.role === "manager" ? (
              <div className="flex">
                <Link
                  to="/risk_assessments"
                  className={`${
                    path === "/risk_assessments"
                      ? "bg-white hover:text-orange"
                      : "bg-grey-light hover:text-orange"
                  } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                  <Trans>Index</Trans>
                </Link>
                <Link
                  to="/risk_assessments/template"
                  className={`${
                    path === "/risk_assessments/template"
                      ? "bg-white hover:text-orange"
                      : "bg-grey-light hover:text-orange"
                  } transition no-underline mr-2 cursor-pointer rounded-t p-2 text-sm`}>
                  <Trans>Template</Trans>
                </Link>
                <Link
                  to="/risk_assessments/hazards"
                  className={`${
                    path === "/risk_assessments/hazards"
                      ? "bg-white hover:text-orange"
                      : "bg-grey-light hover:text-orange"
                  } transition no-underline cursor-pointer rounded-t p-2 text-sm`}>
                  <Trans>Hazards</Trans>
                </Link>
              </div>
            ) : null}
            <div
              className={`${
                user.role === "admin" || user.role === "manager"
                  ? "p-4 rounded-b rounded-tr bg-white"
                  : ""
              }`}>
              {path === "/risk_assessments" ? (
                <RiskAssessmentIndex
                  actions={
                    user.role === "admin" || user.role === "manager"
                      ? ["edit", "delete", "risk_assessment"]
                      : ["risk_assessment"]
                  }
                  canCreate={user.role === "admin" || user.role === "manager"}
                />
              ) : null}
              {path === "/risk_assessments/template" ? <RiskAssessmentTemplate /> : null}
              {path === "/risk_assessments/hazards" ? <HazardIndex /> : null}
            </div>
          </div>
        )}
      </ApiConsumer>
    </div>
  </div>
);

export default RiskAssessment;
