import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import moment from "moment";
import history from "../components/history";
import DeleteButton from "../components/DeleteButton";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, fetchResources, updateResource } from "../api";
import { i18n } from "../App";

class ParticipantCreateForm extends Component {
  static contextType = ApiContext;
  state = {
    family_name: "",
    given_names: "",
    message: "",
    physical_status: 3,
    is_self: false,
    gender: "",
    countries: [],
    grade: "",
    id_number: "",
    country_id: "",
    id_expiry: "",
    id_type: "",
    physicals: {
      poor: { name: "poor", trans: "差" },
      fair: { name: "fair", trans: "一般" },
      good: { name: "good", trans: "良好" },
      excellent: { name: "excellent", trans: "非常好" }
    },
    dob: "",
    allergies: "",
    diet: "",
    medication: "",
    additional_medical: "",
    medical_conditions: [],
    medical_conditions_list: [],
    emergency_contact_name: "",
    emergency_contact_relationship: "",
    emergency_contact_street_address: "",
    emergency_contact_city: "",
    emergency_contact_phone: "",
    isLoading: false,
    isSubmitting: false,
    isFetchingMedicals: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.fetchCountries();
    if (id) {
      this.fetchParticipant();
    } else {
      this.fetchMedicalConditions();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchParticipant = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources("participants/" + id))
      .then(({ data }) => {
        this.safeSetState({
          family_name: data.family_name || "",
          given_names: data.given_names,
          country_id: data.country_id,
          physical_status: data.physical_status,
          error: data.error,
          dob: data.dob ? moment.utc(data.dob).format("YYYY-MM-DD") : "",
          id_expiry: data.id_expiry ? moment.utc(data.id_expiry).format("YYYY-MM-DD") : "",
          id_type: data.id_type,
          grade: data.grade,
          gender: data.gender,
          is_self: data.is_self,
          allergies: data.allergies,
          diet: data.diet,
          medication: data.medication,
          additional_medical: data.additional_medical,
          medical_conditions: data.medical_conditions,
          medical_conditions_list: data.medical_conditions_list,
          id_number: data.id_number,
          emergency_contact_name: data.emergency_contact_name,
          emergency_contact_relationship: data.emergency_contact_relationship,
          emergency_contact_street_address: data.emergency_contact_street_address,
          emergency_contact_city: data.emergency_contact_city,
          emergency_contact_phone: data.emergency_contact_phone,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchMedicalConditions = () => {
    const { callApi } = this.context;
    this.safeSetState({ isFetchingMedicals: true });
    callApi(() => fetchResources("medical_conditions/?simple"))
      .then(({ data }) => {
        this.safeSetState({
          medical_conditions_list: data,
          isFetchingMedicals: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isFetchingMedicals: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch medical conditions.</Trans>);
      });
  };

  fetchCountries = () => {
    const { callApi } = this.context;
    this.safeSetState({ isFetchingCountries: true });
    callApi(() => fetchResources("countries/?simple"))
      .then(({ data }) => {
        this.safeSetState({
          countries: data,
          isFetchingCountries: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isFetchingCountries: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch countries.</Trans>);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (!id) {
      return this.handleCreate();
    }
    const { callApi } = this.context;
    const {
      family_name,
      given_names,
      physical_status,
      error,
      country_id,
      dob,
      id_expiry,
      is_self,
      gender,
      grade,
      id_number,
      id_type,
      allergies,
      diet,
      medication,
      additional_medical,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      medical_conditions
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    callApi(() =>
      updateResource(`/participants/${id}`, {
        family_name,
        given_names,
        physical_status,
        error,
        dob,
        country_id,
        grade,
        id_expiry,
        allergies,
        id_type,
        is_self,
        diet,
        medication,
        additional_medical,
        medical_conditions,
        emergency_contact_name,
        emergency_contact_relationship,
        emergency_contact_street_address,
        emergency_contact_city,
        emergency_contact_phone,
        gender,
        id_number
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
        history.push(`/participants/${res.data.id}/edit`);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(<Trans>Failed to update.</Trans>);
      });
  };

  handleCreate = _ => {
    const { callApi } = this.context;
    const {
      family_name,
      given_names,
      physical_status,
      country_id,
      error,
      dob,
      id_expiry,
      gender,
      grade,
      id_number,
      is_self,
      id_type,
      allergies,
      diet,
      medication,
      additional_medical,
      emergency_contact_name,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      medical_conditions
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    callApi(() =>
      createResource(`/participants`, {
        family_name,
        given_names,
        physical_status,
        error,
        country_id,
        dob,
        id_expiry,
        is_self,
        id_type,
        allergies,
        diet,
        medication,
        additional_medical,
        medical_conditions,
        emergency_contact_name,
        emergency_contact_relationship,
        emergency_contact_street_address,
        emergency_contact_city,
        emergency_contact_phone,
        grade,
        gender,
        id_number
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
        history.push(`/participants/${res.data.id}/edit`);
      })
      .catch(error => {
        console.warn("%cerror", "color: #f6993f; font-weight: bold", error);
        this.safeSetState({ isSubmitting: false, error: error.errors });
        let msg =
          typeof error.message === "object" ? "Failed to create participant" : error.message;
        toast.error(msg);
      });
  };

  handleMedicalUpdate = id => {
    this.safeSetState(prevState => ({
      medical_conditions: prevState.medical_conditions.includes(id)
        ? prevState.medical_conditions.filter(med => med !== id)
        : prevState.medical_conditions.concat(id)
    }));
  };

  generateParticipant = () => {
    this.safeSetState({
      family_name: "Williams",
      given_names: "John",
      physical_status: 1,
      dob: moment().subtract(16, "years").format("YYYY-MM-DD"),
      id_expiry: moment().format("YYYY-MM-DD"),
      id_number: Math.random().toString(32),
      id_type: "passport",
      gender: "male",
      grade: "1",
      allergies: "Satsumas",
      diet: "No veg.",
      country_id: 1,
      medication: "Aspirin",
      additional_medical: "Nothing here.",
      medical_conditions: [1, 2],
      emergency_contact_name: "Jim",
      emergency_contact_relationship: "Father",
      emergency_contact_street_address: "1 Acacia Road",
      emergency_contact_city: "Evesham",
      emergency_contact_phone: "+44 1745 5412357"
    });
  };

  render() {
    const {
      family_name,
      given_names,
      physical_status,
      country_id,
      countries,
      physicals,
      error,
      isSubmitting,
      dob,
      id_expiry,
      gender,
      grade,
      id_number,
      id_type,
      allergies,
      diet,
      medication,
      additional_medical,
      medical_conditions,
      medical_conditions_list,
      emergency_contact_name,
      isFetchingCountries,
      emergency_contact_relationship,
      emergency_contact_street_address,
      emergency_contact_city,
      emergency_contact_phone,
      message,
      is_self,
      isFetchingMedicals,
      isLoading
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    const {
      user: { locale },
      user
    } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {id ? <Trans>Edit Participant</Trans> : <Trans>Create Participant</Trans>}
            </h1>
            <LoadingWrapper
              isLoading={isLoading || isFetchingMedicals || isFetchingCountries}
              message={message}>
              <div className="form-container">
                <form method="POST" onSubmit={this.handleSubmit}>
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Basic Info</Trans>
                  </div>
                  {user.type === "Teacher" ? (
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Participant Type</Trans>
                      </label>
                      <div className="w-full">
                        <label className="cursor-pointer">
                          <input
                            type="checkbox"
                            onChange={e => {
                              e.persist();
                              this.setState(prevState => ({
                                is_self: e.target.checked,
                                family_name: user.family_name,
                                given_names: user.given_names
                              }));
                            }}
                            checked={is_self}
                            className="mr-2"
                          />
                          <Trans>Please check this box if you are the participant.</Trans>
                        </label>
                      </div>
                    </div>
                  ) : null}
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Family Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={family_name}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            family_name: e.target.value
                          })
                        }
                      />
                      {error && error.family_name && (
                        <span className="text-red text-xs">{error.family_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Given Names</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={given_names}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            given_names: e.target.value
                          })
                        }
                      />
                      {error && error.given_names && (
                        <span className="text-red text-xs">{error.given_names}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Gender</Trans>
                    </label>
                    <div className="w-full">
                      <label className="cursor-pointer text-sm">
                        <Trans>Male</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="gender"
                          checked={gender === "male"}
                          value="male"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              gender: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="cursor-pointer text-sm">
                        <Trans>Female</Trans>
                        <input
                          className="ml-2"
                          type="radio"
                          name="gender"
                          checked={gender === "female"}
                          value="female"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              gender: e.target.value
                            })
                          }
                        />
                      </label>
                      {error && error.gender && (
                        <span className="block mt-1 text-red text-xs">{error.gender}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Date of Birth</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="date"
                        value={dob}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ dob: e.target.value })}
                      />
                      {error && error.dob && <span className="text-red text-xs">{error.dob}</span>}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Nationality</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        className="form-input w-full"
                        value={country_id}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            country_id: e.target.value
                          })
                        }>
                        <option value="">-- {i18n._(t`Select a Country`)} --</option>
                        {countries.map(country => (
                          <option key={country.id} value={country.id}>
                            {country.name}
                          </option>
                        ))}
                      </select>
                      {error && error.country_id && (
                        <span className="text-red text-xs">{error.country_id}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Type</Trans>
                    </label>
                    <div className="w-full">
                      <label className="block md:inline-block mb-2 md:mb-0 cursor-pointer text-sm">
                        <Trans>Passport</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="id_type"
                          checked={id_type === "passport"}
                          value="passport"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="block md:inline-block mb-2 md:mb-0 cursor-pointer text-sm">
                        <Trans>ID Card (Chinese Citizen)</Trans>
                        <input
                          className="ml-2 mr-4"
                          type="radio"
                          name="id_type"
                          checked={id_type === "id_card"}
                          value="id_card"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      <label className="block md:inline-block mb-2 md:mb-0 cursor-pointer text-sm">
                        <Trans>Mainland Travel Permit (Hong Kong/Macau/Taiwan)</Trans>
                        <input
                          className="ml-2"
                          type="radio"
                          name="id_type"
                          checked={id_type === "travel_document"}
                          value="travel_document"
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              id_type: e.target.value
                            })
                          }
                        />
                      </label>
                      {error && error.id_type && (
                        <span className="block mt-1 text-red text-xs">{error.id_type}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label leading-normal">
                      <Trans>ID Number</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={id_number}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ id_number: e.target.value })}
                      />
                      {error && error.id_number && (
                        <span className="text-red text-xs">{error.id_number}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>ID Expiry</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="date"
                        value={id_expiry}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ id_expiry: e.target.value })}
                      />
                      {error && error.id_expiry && (
                        <span className="text-red text-xs">{error.id_expiry}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Class or Home Room</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={grade}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ grade: e.target.value })}
                      />
                      {error && error.grade && (
                        <span className="text-red text-xs">{error.grade}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Medical Info</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Physical Condition</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        value={physical_status}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            physical_status: e.target.value
                          })
                        }>
                        {Object.keys(physicals).map((physical, index) => (
                          <option key={index} value={index}>
                            {locale === "en" ? physicals[physical].name : physicals[physical].trans}
                          </option>
                        ))}
                      </select>
                      {error && error.physical_status && (
                        <span className="text-red text-xs">{error.physical_status}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label leading-normal">
                      <Trans>Dietary Requirements</Trans>
                      <br />(<Trans>Leave blank if none</Trans>)
                    </label>
                    <div className="w-full">
                      <textarea
                        value={diet}
                        rows={5}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            diet: e.target.value
                          })
                        }
                      />
                      {error && error.diet && (
                        <span className="text-red text-xs">{error.diet}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label leading-normal">
                      <Trans>Allergies</Trans>
                      <br />(<Trans>Leave blank if none</Trans>)
                    </label>
                    <div className="w-full">
                      <textarea
                        value={allergies}
                        rows={5}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            allergies: e.target.value
                          })
                        }
                      />
                      {error && error.allergies && (
                        <span className="text-red text-xs">{error.allergies}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label leading-normal">
                      <Trans>Medication</Trans>
                      <br />(<Trans>Leave blank if none</Trans>)
                    </label>
                    <div className="w-full">
                      <textarea
                        value={medication}
                        rows={5}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            medication: e.target.value
                          })
                        }
                      />
                      {error && error.medication && (
                        <span className="text-red text-xs">{error.medication}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label leading-normal">
                      <Trans>Additional Medical Info</Trans>
                      <br />(<Trans>Leave blank if none</Trans>)
                    </label>
                    <div className="w-full">
                      <textarea
                        value={additional_medical}
                        rows={5}
                        disabled={isSubmitting}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            additional_medical: e.target.value
                          })
                        }
                      />
                      {error && error.additional_medical && (
                        <span className="text-red text-xs">{error.additional_medical}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Medical Conditions</Trans>
                    </label>
                    <div className="w-full">
                      {medical_conditions_list.map(med => (
                        <div key={med.id} className="text-sm mb-1">
                          <label className="cursor-pointer">
                            <input
                              type="checkbox"
                              onChange={() => this.handleMedicalUpdate(med.id)}
                              checked={medical_conditions.includes(med.id)}
                              className="mr-2"
                            />
                            {med.name}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Emergency Contact</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={emergency_contact_name}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_name: e.target.value
                          })
                        }
                      />
                      {error && error.emergency_contact_name && (
                        <span className="text-red text-xs">{error.emergency_contact_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Relationship</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={emergency_contact_relationship}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_relationship: e.target.value
                          })
                        }
                      />
                      {error && error.emergency_contact_relationship && (
                        <span className="text-red text-xs">
                          {error.emergency_contact_relationship}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Street Address</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={emergency_contact_street_address}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_street_address: e.target.value
                          })
                        }
                      />
                      {error && error.emergency_contact_street_address && (
                        <span className="text-red text-xs">
                          {error.emergency_contact_street_address}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>City</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={emergency_contact_city}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_city: e.target.value
                          })
                        }
                      />
                      {error && error.emergency_contact_city && (
                        <span className="text-red text-xs">{error.emergency_contact_city}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Phone</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="form-input w-full"
                        type="text"
                        value={emergency_contact_phone}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            emergency_contact_phone: e.target.value
                          })
                        }
                      />
                      {error && error.emergency_contact_phone && (
                        <span className="text-red text-xs">{error.emergency_contact_phone}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-actions">
                    {id ? (
                      <DeleteButton url={`participants/${id}`} onDelete={() => history.push("/")}>
                        <button type="button" className="btn btn-solid-red">
                          <Trans>Delete</Trans>
                        </button>
                      </DeleteButton>
                    ) : (
                      <Link className="btn btn-solid-red" to={`/`}>
                        <Trans>Cancel</Trans>
                      </Link>
                    )}
                    {id && (
                      <Link className="btn btn-solid-teal" to={`/participants/${id}`}>
                        <Trans>View</Trans>
                      </Link>
                    )}
                    {process.env.NODE_ENV === "development" && (
                      <button type="button" className="btn" onClick={this.generateParticipant}>
                        Generate
                      </button>
                    )}
                    <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                      {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                    </button>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ParticipantCreateForm;
