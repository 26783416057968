import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const StaffIndex = props => (
  <div>
    <ResourceIndex
      endpoint="staffs"
      model={<Trans>Staff</Trans>}
      canCreate={true}
      headers={["avatar", "full_name", "email", "role"]}
      translations={[
        <Trans>Avatar</Trans>,
        <Trans>Name</Trans>,
        <Trans>Email</Trans>,
        <Trans>Role</Trans>
      ]}
      {...props}
    />
  </div>
);

export default StaffIndex;
