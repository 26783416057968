import React from "react";
import { NavLink } from "react-router-dom";
import { version } from "../../package.json";
import { ApiConsumer } from "../contexts/ApiContext";
import UnreadFeedback from "../components/UnreadFeedback";
import history from "../components/history";
import Icon from "../components/Icon";
import { Trans } from "@lingui/macro";
import icon from "../assets/icon.png";

const Header = ({ toggleMenu }) => (
  <ApiConsumer>
    {({ logout, user, setLanguage, language }) => (
      <nav className="main-nav z-20">
        <div className="mb-8">
          <div
            onClick={toggleMenu}
            style={{ minWidth: 32, width: 32, height: 32 }}
            className="m-2 cursor-pointer rounded overflow-hidden fixed pin-t pin-l">
            <img width="32" height="32" src={icon} alt="Indier Logo" />
          </div>
          <div className="ml-10 flex items-center">
            <h2 className="text-white text-base font-hairline text-white truncate">
              <Trans>Booking System</Trans>
            </h2>
            <span className="ml-2 text-grey-darker uppercase inline-block p-1 text-xs border border-grey-darker rounded">
              beta
            </span>
          </div>
        </div>

        {user && (
          <div className="overflow-y-auto custom-scroll mb-8">
            <div className="">
              <div className="nav-group">
                <label className="nav-label">
                  <Trans>Basics</Trans>
                </label>
                <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/">
                  {user.type !== "Staff" ? (
                    <>
                      <Icon icon="participants" />
                      <Trans>My Participants</Trans>
                    </>
                  ) : (
                    <>
                      <Icon icon="dashboard" />
                      <Trans>Dashboard</Trans>
                    </>
                  )}
                </NavLink>
                {user.type === "Client" || user.type === "Teacher" ? (
                  <NavLink
                    onClick={toggleMenu}
                    exact={true}
                    className="nav-item"
                    to="/trip-registration">
                    <Icon icon="school" />
                    <Trans>School Trip Registration</Trans>
                  </NavLink>
                ) : null}
                {user.type === "Client" || user.type === "Teacher" ? (
                  <NavLink
                    onClick={toggleMenu}
                    exact={true}
                    className="nav-item"
                    to="/summer-camp-registration">
                    <Icon icon="summer" />
                    <Trans>Summer Camp Registration</Trans>
                  </NavLink>
                ) : null}
                <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/my-trips">
                  <Icon icon="tent" />
                  <Trans>My Trips</Trans>
                </NavLink>
                {user.type === "Client" || user.type === "Teacher" ? (
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/my-payments">
                    <Icon icon="payment" />
                    <Trans>My Payments</Trans>
                  </NavLink>
                ) : null}
                <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/profile">
                  <Icon icon="profile" />
                  <Trans>My Profile</Trans>
                </NavLink>
                {user.type === "Staff" && (
                  <NavLink
                    onClick={toggleMenu}
                    exact={true}
                    className="nav-item"
                    to="/my-timesheet">
                    <Icon icon="timesheet" />
                    <Trans>My Timesheet</Trans>
                  </NavLink>
                )}
                <NavLink
                  onClick={toggleMenu}
                  exact={true}
                  className="nav-item"
                  to="/change-password">
                  <Icon icon="password" />
                  <Trans>Change Password</Trans>
                </NavLink>
              </div>
              <div className="nav-group">
                <label className="nav-label">
                  <Trans>Feedback</Trans>
                </label>
                <NavLink
                  onClick={toggleMenu}
                  exact={true}
                  className="nav-item"
                  to="/submit-feedback">
                  <Icon icon="feedback" />
                  <Trans>Submit Feedback</Trans>
                </NavLink>
                {user.role === "admin" ? <UnreadFeedback toggleMenu={toggleMenu} /> : null}
                {user.role === "manager" || user.role === "admin" ? (
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/feedback">
                    <Icon icon="feedback" />
                    <Trans>View Trip Feedback</Trans>
                  </NavLink>
                ) : null}
                {user.role === "admin" ? (
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/impersonate">
                    <Icon icon="user" />
                    <Trans>Impersonate</Trans>
                  </NavLink>
                ) : null}
              </div>

              {(user.role === "manager" ||
                user.role === "admin" ||
                user.role === "team_leader") && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Trip Management</Trans>
                  </label>
                  {/* <NavLink */}
                  {/*     onClick={toggleMenu} */}
                  {/*     className="nav-item" */}
                  {/*     to="/overview"> */}
                  {/*     <Trans>Overview</Trans> */}
                  {/*     <Notice text="TODO" color="grey" /> */}
                  {/* </NavLink> */}
                  <NavLink exact onClick={toggleMenu} className="nav-item" to="/trips">
                    <Icon icon="tent" />
                    <Trans>Trips</Trans>
                  </NavLink>
                  {(user.role === "manager" || user.role === "admin") && (
                    <>
                      <NavLink exact onClick={toggleMenu} className="nav-item" to="/archived-trips">
                        <Icon icon="archive" />
                        <Trans>Archived Trips</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/payments">
                        <Icon icon="payment" />
                        <Trans>Payments</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/schools">
                        <Icon icon="school" />
                        <Trans>Schools</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/locations">
                        <Icon icon="location" />
                        <Trans>Locations</Trans>
                      </NavLink>
                    </>
                  )}
                </div>
              )}

              {user.type === "Staff" && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Office</Trans>
                  </label>
                  <NavLink onClick={toggleMenu} className="nav-item" to="/attachments">
                    <Icon icon="attachment" />
                    <Trans>Attachments</Trans>
                  </NavLink>
                  <NavLink onClick={toggleMenu} className="nav-item" to="/gallery">
                    <Icon icon="camera" />
                    <Trans>Gallery</Trans>
                  </NavLink>
                  {(user.role === "manager" || user.role === "admin") && (
                    <>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/timesheets">
                        <Icon icon="timesheet" />
                        <Trans>Timesheets</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/medical_conditions">
                        <Icon icon="medical" />
                        <Trans>Medical Conditions</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/skills">
                        <Icon icon="skill" />
                        <Trans>Staff Skills</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/waiver">
                        <Icon icon="document" />
                        <Trans>Waiver</Trans>
                      </NavLink>
                      <NavLink onClick={toggleMenu} className="nav-item" to="/adverts">
                        <Icon icon="advert" />
                        <Trans>Adverts</Trans>
                      </NavLink>
                    </>
                  )}
                  {user.type === "Staff" ? (
                    <NavLink onClick={toggleMenu} className="nav-item" to="/risk_assessments">
                      <Icon icon="risk" />
                      <Trans>Risk Assessments</Trans>
                    </NavLink>
                  ) : null}
                </div>
              )}

              {(user.role === "manager" || user.role === "admin") && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>User Management</Trans>
                  </label>
                  <NavLink onClick={toggleMenu} className="nav-item" to="/staffs">
                    <Icon icon="staff" />
                    <Trans>Staff</Trans>
                  </NavLink>
                  <NavLink onClick={toggleMenu} className="nav-item" to="/archived-staffs">
                    <Icon icon="user" />
                    <Trans>Archived Staff</Trans>
                  </NavLink>
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/teachers">
                    <Icon icon="teacher" />
                    <Trans>Teachers</Trans>
                  </NavLink>
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/clients">
                    <Icon icon="client" />
                    <Trans>Clients</Trans>
                  </NavLink>
                  <NavLink
                    onClick={toggleMenu}
                    exact={true}
                    className="nav-item"
                    to="/participants">
                    <Icon icon="participants" />
                    <Trans>Participants</Trans>
                  </NavLink>
                  <NavLink onClick={toggleMenu} exact={true} className="nav-item" to="/subscribers">
                    <Icon icon="envelope" />
                    <Trans>Subscribers</Trans>
                  </NavLink>
                </div>
              )}
            </div>
          </div>
        )}

        <div className="mt-auto">
          {!user ? (
            <div className="mb-4">
              <NavLink onClick={toggleMenu} className="nav-item" to="/login">
                <Icon icon="login" />
                <Trans>Login</Trans>
              </NavLink>
            </div>
          ) : (
            <div className="mb-4">
              <span
                onClick={() => {
                  toggleMenu();
                  logout();
                }}
                className="nav-item">
                <Icon icon="logout" />
                <Trans>Logout</Trans>
              </span>
            </div>
          )}
          <div className="">
            <div className="flex mb-4">
              <button
                className={`nav-item ${language === "en" ? "active" : ""}`}
                onClick={() => setLanguage("en")}>
                EN
              </button>
              <button
                className={`nav-item ${language === "zh" ? "active" : ""}`}
                onClick={() => setLanguage("zh")}>
                中文
              </button>
            </div>

            <div className="text-xxs text-white text-right">
              {user && user.type === "Staff" ? (
                <span
                  onClick={() => {
                    toggleMenu();
                    history.push("/whats-new");
                  }}
                  className="underline transition cursor-pointer hover:text-orange text-white">
                  <Trans>Version</Trans> {version}
                </span>
              ) : (
                <>
                  <Trans>Version</Trans> {version}
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
    )}
  </ApiConsumer>
);

export default Header;
