import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const TripIndex = props => (
  <div>
    <ResourceIndex
      {...props}
      endpoint="archived-trips"
      model={<Trans>Archived Trips</Trans>}
      headers={["trip_name", "location_name", "school_name", "start_date", "end_date"]}
      translations={[
        <Trans>Trip Name</Trans>,
        <Trans>Location</Trans>,
        <Trans>School</Trans>,
        <Trans>Start Date</Trans>,
        <Trans>End Date</Trans>
      ]}
      actions={["show", "edit", "unarchive", "delete"]}
    />
  </div>
);

export default TripIndex;
