import React, { Component } from "react";
import debounce from "lodash.debounce";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class GlobalSearch extends Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    goToEdit: PropTypes.bool.isRequired,
    onSelect: PropTypes.func
  };
  static contextType = ApiContext;
  static defaultProps = {
    goToEdit: false
  };
  state = {
    loading: false,
    error: null,
    items: [],
    isOpen: false,
    inputValue: ""
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  onChange = e => {
    this.safeSetState({ inputValue: e.target.value }, this.fetchResults);
  };

  resetState = () => {
    this.safeSetState({
      loading: false,
      error: null,
      items: [],
      isOpen: false,
      inputValue: ""
    });
  };

  fetchResults = debounce(async () => {
    const { inputValue } = this.state;
    const { url } = this.props;

    if (!inputValue) {
      return this.safeSetState({ isOpen: false });
    }

    this.safeSetState({ loading: true });
    this.context
      .callApi(() => fetchResources(`${url}?q=${inputValue}`))
      .then(res => {
        this.safeSetState({
          loading: false,
          items: res.data,
          isOpen: true
        });
      })
      .catch(error => {
        this.safeSetState({ loading: false, error });
      });
  }, 350);

  render() {
    const { placeholder, url, goToEdit, onSelect, border, download } = this.props;
    const { loading, inputValue, items, isOpen } = this.state;
    return (
      <div className="relative w-full">
        <input
          className={`${
            border ? "border" : ""
          } w-full rounded text-sm p-2 text-grey-dark no-outline ${loading ? "loading" : ""}`}
          onChange={e => this.onChange(e)}
          value={inputValue}
          type="search"
          placeholder={placeholder}
          style={{ minWidth: "220px" }}
        />
        {(isOpen || inputValue !== "") && (
          <span
            className="absolute block p-2 mr-1 text-base cursor-pointer pin-y pin-r"
            onClick={() => this.setState({ inputValue: "", isOpen: false })}>
            &times;
          </span>
        )}
        {isOpen && (
          <div className="drop-down">
            {Array.isArray(items) ? (
              !items.length ? (
                <div className="border-b">
                  <span className="block p-2 text-sm text-grey">
                    <Trans>No results</Trans>
                  </span>
                </div>
              ) : (
                items.map(item => (
                  <div key={item.id} className="border-b hover:bg-grey-lightest">
                    {onSelect ? (
                      <button
                        onClick={() => {
                          onSelect(item);
                          this.setState({
                            isOpen: false,
                            inputValue: ""
                          });
                        }}
                        className="block w-full p-2 text-sm text-left no-underline truncate hover:text-teal">
                        {item.name}
                      </button>
                    ) : download ? (
                      <a
                        onClick={() =>
                          this.setState({
                            isOpen: false,
                            inputValue: ""
                          })
                        }
                        rel="noopener noreferrer"
                        className="block p-2 text-sm text-left no-underline truncate hover:text-teal"
                        href={`${process.env.REACT_APP_API_BASE_URL}/attachments/${
                          item.id
                        }?token=${localStorage.getItem("INDIER_JWT")}`}>
                        {item.name}
                      </a>
                    ) : (
                      <Link
                        onClick={() =>
                          this.setState({
                            isOpen: false,
                            inputValue: ""
                          })
                        }
                        className="block w-full p-2 text-sm text-left no-underline truncate hover:text-teal"
                        to={`/${url}/${item.id}${goToEdit ? "/edit" : ""}`}>
                        {item.name}
                      </Link>
                    )}
                  </div>
                ))
              )
            ) : (
              Object.keys(items).map((cat, index) => (
                <div key={index} className="">
                  <h5 className="p-2 text-xs font-normal uppercase bg-grey-light text-grey-dark">
                    {cat}
                  </h5>
                  {!items[cat].length && (
                    <div className="border-b">
                      <span className="block p-2 text-sm text-grey-dark">
                        <Trans>No results</Trans>
                      </span>
                    </div>
                  )}
                  {items[cat].map((item, index) => (
                    <div key={index} className="border-b hover:bg-grey-lightest">
                      {cat === "attachments" ? (
                        <a
                          onClick={() =>
                            this.setState({
                              isOpen: false,
                              inputValue: ""
                            })
                          }
                          rel="noopener noreferrer"
                          className="block p-2 text-sm no-underline truncate hover:text-teal"
                          href={`${process.env.REACT_APP_API_BASE_URL}/attachments/${
                            item.id
                          }?token=${localStorage.getItem("INDIER_JWT")}`}>
                          {item.name}
                        </a>
                      ) : (
                        <Link
                          onClick={() =>
                            this.setState({
                              isOpen: false,
                              inputValue: ""
                            })
                          }
                          className="block p-2 text-sm no-underline truncate hover:text-teal"
                          to={`/${cat === "users" ? item.id : `${cat}/${item.id}`}`}>
                          {item.name}
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    );
  }
}

export default GlobalSearch;
