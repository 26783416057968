import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const SkillIndex = props => (
  <div>
    <ResourceIndex
      {...props}
      endpoint="skills"
      model={<Trans>Skills</Trans>}
      canCreate={true}
      goToEdit={true}
      headers={["name"]}
      translations={[<Trans>Skill</Trans>]}
      actions={["edit", "delete"]}
    />
  </div>
);

export default SkillIndex;
