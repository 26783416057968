import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "../pages/ResourceIndex";

const HazardIndex = props => (
  <div>
    <ResourceIndex
      endpoint="hazards"
      canCreate={true}
      canSearch={false}
      isComponent={true}
      model={<Trans>Hazard Index</Trans>}
      headers={["name"]}
      translations={[<Trans>Name</Trans>]}
      {...props}
      actions={["edit", "delete"]}
    />
  </div>
);

export default HazardIndex;
