import React, { Component } from "react";
import { toast } from "react-toastify";
import GlobalSearch from "../components/GlobalSearch";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import Icon from "../components/Icon";
import MediaUpload from "../components/MediaUpload";
import Gallery from "../components/Gallery";
import _ from "lodash";
import { ApiContext } from "../contexts/ApiContext";
import {
  fetchResources,
  notifyTeachers,
  paymentReminder,
  createResource,
  deleteResource
} from "../api";
import moment from "moment";
import { i18n } from "../App";

class TripShow extends Component {
  static contextType = ApiContext;
  state = {
    trip_name: "",
    location_name: "",
    school_name: "",
    images: [],
    showAddTeacherForm: false,
    showClients: false,
    trip_leader_name: "",
    trip_leader_phone: "",
    teacher_email: "",
    teacher_given_names: "",
    teacher_family_name: "",
    isCreatingTeacher: false,
    trip_leader_avatar: "",
    teacher_is_admin: false,
    price: 0,
    price_int: 0,
    code: "",
    freelancers: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    expected_participants: 0,
    open_registration: false,
    prepaid: false,
    lead_teachers: [],
    teachers: [],
    participants: [],
    allocated_staff: [],
    trip_attachments: [],
    submittingPaymentId: null,
    isNotifyingTeachers: false,
    submittingPaymentType: null,
    deletingParticipantId: null,
    deletingTeacherId: null,
    isLoading: false,
    trip_id: null,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchTrip();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchTrip();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  notifyTeachers = () => {
    const { trip_id } = this.state;
    const confirm = window.confirm(i18n._(t`Are you sure you want to send the email(s)?`));

    if (!confirm) {
      return;
    }

    this.safeSetState({ isNotifyingTeachers: true });
    this.context
      .callApi(() => notifyTeachers(trip_id))
      .then(({ data }) => {
        toast.success(<Trans>Email(s) sent!</Trans>);
        this.safeSetState({ isNotifyingTeachers: false });
      })
      .catch(error => {
        toast.error(<Trans>Failed to sent email(s).</Trans>);
        this.safeSetState({ isNotifyingTeachers: false, error });
      });
  };

  sendPaymentReminder = () => {
    const { trip_id } = this.state;
    const confirm = window.confirm(i18n._(t`Are you sure you want to send the email(s)?`));

    if (!confirm) {
      return;
    }

    this.safeSetState({ isSendingPaymentReminder: true });
    this.context
      .callApi(() => paymentReminder(trip_id))
      .then(({ data }) => {
        toast.success(<Trans>Email(s) sent!</Trans>);
        this.safeSetState({ isSendingPaymentReminder: false });
      })
      .catch(error => {
        toast.error(<Trans>Failed to sent email(s).</Trans>);
        this.safeSetState({ isSendingPaymentReminder: false, error });
      });
  };

  manualPayment = (participant, payment_type) => {
    const { trip_id, price_int } = this.state;

    const confirm = window.confirm(i18n._(t`Are you sure you want to make this payment?`));

    if (!confirm) return;

    this.safeSetState({
      submittingPaymentId: participant.id,
      submittingPaymentType: payment_type
    });

    this.context
      .callApi(() =>
        createResource(`manual-payment/${trip_id}/${participant.id}`, {
          payment_type,
          amount: price_int
        })
      )
      .then(({ data }) => {
        toast.success(<Trans>Payment success!</Trans>);
        this.safeSetState({
          submittingPaymentId: null,
          participants: this.state.participants.map(p => {
            if (p.id === participant.id) {
              return {
                ...p,
                payment_status: payment_type
              };
            }

            return p;
          })
        });
      })
      .catch(error => {
        toast.error(<Trans>Failed to make payment.</Trans>);
        this.safeSetState({ submittingPaymentId: null, error });
      });
  };

  removeParticipant = participant => {
    const { trip_id } = this.state;

    const confirm = window.confirm(i18n._(t`Are you sure you want to remove this participant?`));

    if (!confirm) return;

    this.safeSetState({
      deletingParticipantId: participant.id
    });

    this.context
      .callApi(() => deleteResource(`remove-participant/${trip_id}/${participant.id}`))
      .then(({ data }) => {
        toast.success(<Trans>Removed participant!</Trans>);
        this.safeSetState({
          deletingParticipantId: null,
          participants: this.state.participants.filter(p => p.id !== participant.id)
        });
      })
      .catch(error => {
        toast.error(<Trans>Failed to remove participant.</Trans>);
        this.safeSetState({ deletingParticipantId: null, error });
      });
  };

  removeTeacher = teacher => {
    const { trip_id } = this.state;

    const confirm = window.confirm(i18n._(t`Are you sure you want to remove this teacher?`));

    if (!confirm) return;

    this.safeSetState({
      deletingTeacherId: teacher.id
    });

    this.context
      .callApi(() => deleteResource(`remove-participant/${trip_id}/${teacher.participant_id}`))
      .then(({ data }) => {
        toast.success(<Trans>Removed teacher!</Trans>);
        this.safeSetState({
          deletingTeacherId: null,
          teachers: this.state.teachers.filter(p => p.id !== teacher.id)
        });
      })
      .catch(error => {
        toast.error(<Trans>Failed to remove teacher.</Trans>);
        this.safeSetState({ deletingTeacherId: null, error });
      });
  };

  fetchTrip = () => {
    const { id } = this.props.match.params;
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources("trips/" + id))
      .then(({ data }) => {
        const sortedParticipants = _.orderBy(data.participants, p => {
          switch (p.payment_status) {
            case "unpaid":
              return 0;
            case "alipay":
              return 1;
            case "cash":
              return 2;
            case "wechat":
              return 3;
            default:
              return 4;
          }
        });
        this.safeSetState({
          trip_name: data.trip_name,
          school_name: data.school_name,
          location_name: data.location_name,
          teachers: data.teachers,
          images: data.images || [],
          lead_teachers: data.lead_teachers,
          participants: sortedParticipants,
          trip_leader_phone: data.trip_leader_phone,
          trip_leader_id: data.trip_leader_id,
          trip_leader_avatar: data.trip_leader_avatar,
          trip_leader_name: data.trip_leader_name,
          price: data.price,
          price_int: data.price_int,
          code: data.code,
          trip_id: data.id,
          freelancers: data.freelancers,
          school_id: data.school_id,
          allocated_staff: data.allocated_staff,
          trip_attachments: data.trip_attachments,
          start_date: data.start_date,
          end_date: data.end_date,
          expected_participants: data.expected_participants,
          open_registration: data.open_registration,
          prepaid: data.prepaid,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleTeacherCreate = () => {
    const {
      teacher_family_name,
      teacher_given_names,
      teacher_email,
      trip_id,
      teacher_is_admin
    } = this.state;
    if (!teacher_given_names || !teacher_family_name || !teacher_email) {
      return toast.error(<Trans>Please fill in all fields.</Trans>);
    }

    this.safeSetState({ isCreatingTeacher: true, error: null });

    this.context
      .callApi(() =>
        createResource(`/lead-teachers/${trip_id}`, {
          family_name: teacher_family_name,
          given_names: teacher_given_names,
          email: teacher_email,
          is_admin: teacher_is_admin
        })
      )
      .then(res => {
        teacher_is_admin
          ? toast.success(<Trans>Teacher added as an admin, email sent!</Trans>)
          : toast.success(<Trans>Teacher confirmation email sent!</Trans>);

        this.safeSetState({
          isCreatingTeacher: false,
          teacher_family_name: "",
          teacher_given_names: "",
          teacher_email: "",
          teacher_is_admin: false
        });
        // this.safeSetState({
        //     teachers: this.state.teachers.concat(res.data),
        // });
      })
      .catch(error => {
        this.safeSetState({ isCreatingTeacher: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleAttachmentUpload = data => {
    console.debug("handling attachment", data);
    this.context
      .callApi(() => createResource(`/trip-attachment/${this.state.trip_id}`, { id: data.id }))
      .then(res => {
        console.log("attachment success", res);
        this.safeSetState({ trip_attachments: this.state.trip_attachments.concat(res.data) });
        toast.success(<Trans>Attachment added!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ error: error.errors });
        toast.error(error.message);
      });
  };

  handleUpload = data => {
    this.context
      .callApi(() => createResource(`/trips/${this.state.trip_id}/images`, { image_id: data.id }))
      .then(res => {
        this.safeSetState({ images: this.state.images.concat(data) });
        toast.success(<Trans>Image added!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ error: error.errors });
        toast.error(error.message);
      });
  };

  render() {
    const { id } = this.props.match.params;
    const {
      trip_name,
      images,
      school_name,
      location_name,
      showAddTeacherForm,
      teacher_is_admin,
      teachers,
      lead_teachers,
      participants,
      trip_leader_name,
      trip_leader_phone,
      trip_leader_id,
      freelancers,
      trip_leader_avatar,
      isNotifyingTeachers,
      isSendingPaymentReminder,
      price,
      code,
      start_date,
      end_date,
      expected_participants,
      open_registration,
      prepaid,
      trip_attachments,
      submittingPaymentId,
      isLoading,
      isSubmitting,
      teacher_email,
      isCreatingTeacher,
      teacher_family_name,
      teacher_given_names,
      submittingPaymentType,
      deletingParticipantId,
      deletingTeacherId,
      showClients,
      allocated_staff
    } = this.state;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Trip Details</Trans>
            </h1>
            {user.type === "Staff" ? (
              <div className="flex items-center justify-between mb-2 text-right">
                <div>
                  <label
                    className="text-left form-label"
                    style={{ marginBottom: ".25rem", color: "#464b52" }}>
                    Upload Image to Trip Gallery
                  </label>
                  <MediaUpload accept="image/*" url="/images" onSuccess={this.handleUpload} />
                </div>
              </div>
            ) : null}
            <LoadingWrapper isLoading={isLoading}>
              <div className="form-container form-read-only">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Details</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{trip_name}</span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>School</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{school_name}</span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Location</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{location_name}</span>
                  </div>
                </div>

                {user.role === "manager" || user.role === "admin" ? (
                  <>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Price (per person)</Trans>
                      </label>
                      <div className="w-full">
                        <span className="w-full form-input">{price}</span>
                      </div>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Code</Trans>
                      </label>
                      <div className="w-full">
                        <span className="w-full form-input">{code}</span>
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Start Date</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{moment.utc(start_date).format("LL")}</span>
                  </div>
                </div>

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>End Date</Trans>
                  </label>
                  <div className="w-full">
                    <span className="w-full form-input">{moment.utc(end_date).format("LL")}</span>
                  </div>
                </div>

                {user.type === "Client" ? null : (
                  <>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans># of Expected Participants</Trans>
                      </label>
                      <div className="w-full">
                        <span className="w-full form-input">{expected_participants}</span>
                      </div>
                    </div>

                    {user.type === "Staff" && (
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Open Registration</Trans>
                          <br />(<Trans>Summer Camp</Trans>)
                        </label>
                        <div className="w-full">
                          <span className="w-full form-input">
                            {open_registration ? "true" : "false"}
                          </span>
                        </div>
                      </div>
                    )}

                    {user.type === "Staff" && (
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Prepaid</Trans>
                        </label>
                        <div className="w-full">
                          <span className="w-full form-input">{prepaid ? "true" : "false"}</span>
                        </div>
                      </div>
                    )}

                    <div className="form-section-header bg-grey-lighter">
                      <Trans>Staff</Trans>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Trip Leader</Trans>
                      </label>
                      <div className="w-full">
                        <div className="w-full form-input">
                          <div className="flex items-center">
                            <div className="mr-2">
                              {trip_leader_avatar ? (
                                <img
                                  src={trip_leader_avatar}
                                  alt={trip_leader_name}
                                  className="nav-avatar"
                                />
                              ) : (
                                <Icon icon="user" size={28} />
                              )}
                            </div>
                            <span className="w-full form-input">
                              {trip_leader_name ? (
                                <>
                                  {user.role === "manager" || user.role === "admin" ? (
                                    <Link to={`/staffs/${trip_leader_id}`}>{trip_leader_name}</Link>
                                  ) : (
                                    trip_leader_name
                                  )}{" "}
                                  {trip_leader_phone && (
                                    <>
                                      (<a href={`tel:${trip_leader_phone}`}>{trip_leader_phone}</a>)
                                    </>
                                  )}
                                </>
                              ) : (
                                <Trans>None selected</Trans>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {user.type === "Staff" && (
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Staff</Trans>
                        </label>
                        <div className="w-full">
                          {!allocated_staff.length && (
                            <span className="w-full form-input">
                              <Trans>None</Trans>
                            </span>
                          )}
                          {allocated_staff.map(staff => (
                            <div key={staff.id} className="flex items-center mb-2 text-sm">
                              <div className="mr-2">
                                {staff.avatar ? (
                                  <img
                                    src={staff.avatar}
                                    alt={staff.full_name}
                                    className="nav-avatar"
                                  />
                                ) : (
                                  <Icon icon="user" size={28} />
                                )}
                              </div>
                              <span className="w-full form-input">
                                {user.role === "manager" || user.role === "admin" ? (
                                  <Link to={`/staffs/${staff.id}`}>{staff.full_name}</Link>
                                ) : (
                                  staff.full_name
                                )}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}

                {user.type === "Staff" && (
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Freelancers</Trans>
                    </label>
                    <div className="w-full">
                      <span className="w-full whitespace-pre form-input">{freelancers}</span>
                    </div>
                  </div>
                )}

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Attachments</Trans>
                </div>

                {["admin", "manager", "team_leader"].includes(user.role) && (
                  <>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Upload A New File</Trans>
                      </label>
                      <MediaUpload url="attachments" onSuccess={this.handleAttachmentUpload} />
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Search Existing Files</Trans>
                      </label>
                      <GlobalSearch
                        border
                        url="attachments"
                        placeholder={i18n._(t`Type to search attachments`)}
                        onSelect={this.handleAttachmentUpload}
                      />
                    </div>
                  </>
                )}

                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Current Files</Trans>
                  </label>
                  {!trip_attachments.length ? (
                    <span className="text-sm">
                      <Trans>No attachments</Trans>
                    </span>
                  ) : (
                    <table>
                      <tbody>
                        {trip_attachments.map(attachment => (
                          <tr key={attachment.id} className="mb-1 text-sm">
                            <td>{attachment.filename}</td>
                            <td>
                              <a
                                rel="noopener noreferrer"
                                className="mx-2 mr-2 no-underline text-grey hover:text-blue"
                                href={`${process.env.REACT_APP_API_BASE_URL}/attachments/${
                                  attachment.id
                                }?token=${localStorage.getItem("INDIER_JWT")}`}>
                                <Icon icon="download" size={16} />
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>

                {user.type === "Client" ? null : (
                  <>
                    {user.type === "Teacher" &&
                    lead_teachers.filter(t => t.id === user.userable_id).length ? (
                      <>
                        <div className="flex items-center justify-between form-section-header bg-grey-lighter">
                          <div>
                            <Trans>Add Teachers</Trans>
                          </div>
                          <div>
                            <button
                              className="underline hover:text-grey-darker text-grey-dark"
                              onClick={() =>
                                this.setState({
                                  showAddTeacherForm: !this.state.showAddTeacherForm
                                })
                              }>
                              {showAddTeacherForm ? (
                                <Trans>Hide Form</Trans>
                              ) : (
                                <Trans>Show Form</Trans>
                              )}
                            </button>
                          </div>
                        </div>
                        {showAddTeacherForm ? (
                          <>
                            <div className="form-input-group">
                              <div className="w-full p-4 text-sm leading-normal border rounded border-teal text-teal">
                                <Trans>
                                  Below you can create an account for your colleagues. If the
                                  teacher is only attending the trip, and does not need extra
                                  permissions (e.g. viewing and editing participants details,
                                  accepting cash payments) then fill in their name and email
                                  address, and they will receive an email with further instructions.
                                  If they do need extra permissions, please check the "Lead Teacher"
                                  box.
                                </Trans>
                              </div>
                            </div>
                            <div className="form-input-group">
                              <label className="form-label">
                                <Trans>Teacher Family Name</Trans>
                              </label>
                              <input
                                className="w-full form-input"
                                style={{
                                  border: "1px solid #dae1e7",
                                  padding: ".5rem"
                                }}
                                type="text"
                                value={teacher_family_name}
                                disabled={isSubmitting}
                                onChange={e =>
                                  this.setState({
                                    teacher_family_name: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="form-input-group">
                              <label className="form-label">
                                <Trans>Teacher Given Names</Trans>
                              </label>
                              <input
                                className="w-full form-input"
                                style={{
                                  border: "1px solid #dae1e7",
                                  padding: ".5rem"
                                }}
                                type="text"
                                value={teacher_given_names}
                                disabled={isSubmitting}
                                onChange={e =>
                                  this.setState({
                                    teacher_given_names: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="form-input-group">
                              <label className="form-label">
                                <Trans>Teacher Email</Trans>
                              </label>
                              <input
                                className="w-full form-input"
                                style={{
                                  border: "1px solid #dae1e7",
                                  padding: ".5rem"
                                }}
                                type="email"
                                value={teacher_email}
                                disabled={isSubmitting}
                                onChange={e =>
                                  this.setState({
                                    teacher_email: e.target.value
                                  })
                                }
                              />
                            </div>
                            <div className="form-input-group">
                              <label className="form-label">
                                <Trans>Lead Teacher</Trans>
                              </label>
                              <div className="w-full">
                                <label className="cursor-pointer">
                                  <span className="mr-2 text-sm">
                                    <Trans>
                                      Check this box to give the teacher admin rights for this trip.
                                    </Trans>
                                  </span>
                                  <input
                                    type="checkbox"
                                    checked={teacher_is_admin}
                                    disabled={isSubmitting}
                                    onChange={e =>
                                      this.setState(prevState => ({
                                        teacher_is_admin: !prevState.teacher_is_admin
                                      }))
                                    }
                                  />
                                </label>
                              </div>
                            </div>
                            <div className="form-input-group">
                              {process.env.NODE_ENV === "development" ? (
                                <button
                                  type="button"
                                  className="w-full mr-2 btn btn-grey"
                                  onClick={() =>
                                    this.setState({
                                      teacher_email: "abc@123.com",
                                      teacher_family_name: "Williams",
                                      teacher_given_names: "Bob"
                                    })
                                  }>
                                  Generate Teacher
                                </button>
                              ) : null}
                              <button
                                type="button"
                                onClick={this.handleTeacherCreate}
                                className="w-full btn">
                                {isCreatingTeacher ? (
                                  <Trans>Adding Teacher...</Trans>
                                ) : (
                                  <Trans>Add Teacher</Trans>
                                )}
                              </button>
                            </div>
                          </>
                        ) : null}
                      </>
                    ) : null}

                    <div className="flex items-center justify-between form-section-header bg-grey-lighter">
                      <div>
                        <Trans>Clients</Trans>{" "}
                      </div>
                      <div>
                        <Trans>Registered</Trans>: {participants.length}/{expected_participants} |{" "}
                        <Trans>Paid</Trans>:{" "}
                        {participants.filter(p => p.payment_status !== "unpaid").length}/
                        {expected_participants}
                        <button
                          className="ml-2 underline hover:text-grey-darker text-grey-dark"
                          onClick={() =>
                            this.setState({
                              showClients: !this.state.showClients
                            })
                          }>
                          {showClients ? <Trans>Hide</Trans> : <Trans>Show</Trans>}
                        </button>
                      </div>
                    </div>
                    <div className={`form-input-group ${showClients ? "" : "always-hidden"}`}>
                      <label className="form-label">
                        <Trans>Lead Teachers</Trans>
                      </label>
                      <div className="w-full">
                        {!lead_teachers.length && (
                          <span className="w-full form-input">
                            <Trans>None</Trans>
                          </span>
                        )}
                        {lead_teachers.map(teacher => (
                          <div key={teacher.id} className="mb-1 text-sm">
                            <span className="w-full form-input">
                              {user.role === "manager" || user.role === "admin" ? (
                                <>
                                  <Link to={`/teachers/${teacher.id}`}>{teacher.full_name}</Link> (
                                  <a href={`mailto:${teacher.email}`}>{teacher.email}</a>){" "}
                                </>
                              ) : (
                                teacher.full_name
                              )}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className={`form-input-group ${showClients ? "" : "always-hidden"}`}>
                      <label className="form-label">
                        <Trans>Attending Teachers</Trans>
                      </label>
                      <div className="w-full">
                        {!teachers.length && (
                          <span className="w-full form-input">
                            <Trans>None</Trans>
                          </span>
                        )}
                        {teachers.map(teacher => (
                          <div key={teacher.id} className="mb-1 text-sm">
                            {user.role === "manager" || user.role === "admin" ? (
                              <>
                                <Link to={`/teachers/${teacher.id}`}>{teacher.full_name}</Link> (
                                <a href={`mailto:${teacher.email}`}>{teacher.email}</a>)
                                {user.role === "admin" || user.role === "manager" ? (
                                  <button
                                    disabled={deletingTeacherId === teacher.participant_id}
                                    onClick={() => this.removeTeacher(teacher)}
                                    className="ml-2 border rounded text-red border-red hover:bg-red hover:text-white"
                                    style={{
                                      height: 23,
                                      width: 23
                                    }}>
                                    <Icon size={10} icon="close" />
                                  </button>
                                ) : null}
                              </>
                            ) : (
                              teacher.full_name
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className={`form-input-group ${showClients ? "" : "always-hidden"}`}>
                      <label className="form-label">
                        <Trans>Participants</Trans>
                      </label>
                      <div className="w-full">
                        {!participants.length && (
                          <span className="w-full form-input">
                            <Trans>None</Trans>
                          </span>
                        )}
                        {participants.map(participant => (
                          <div key={participant.id} className="pb-2 mb-2 text-sm border-b">
                            <span className="flex items-center w-full form-input">
                              {user.role === "admin" ||
                              user.role === "manager" ||
                              lead_teachers.filter(t => t.id === user.userable_id).length ? (
                                <>
                                  {participant.payment_status !== "unpaid" ? (
                                    <span
                                      className={`mr-2 text-${
                                        participant.payment_status === "cash" ||
                                        participant.payment_status === "prepaid"
                                          ? "orange"
                                          : participant.payment_status === "alipay"
                                          ? "blue"
                                          : "green"
                                      }`}>
                                      <Icon icon={participant.payment_status} />
                                    </span>
                                  ) : (
                                    <span className="mr-2 text-red">
                                      <Icon icon="exclamation" />
                                    </span>
                                  )}
                                </>
                              ) : null}
                              {user.type === "Staff" ||
                              lead_teachers.filter(t => t.id === user.userable_id).length ? (
                                <Link to={`/participants/${participant.id}`}>
                                  {participant.full_name}
                                </Link>
                              ) : (
                                participant.full_name
                              )}
                              {participant.payment_status === "unpaid" &&
                              (user.role === "admin" ||
                                user.role === "manager" ||
                                (user.role === "teacher" &&
                                  lead_teachers.filter(t => t.id === user.userable_id).length)) ? (
                                <>
                                  <button
                                    disabled={submittingPaymentId === participant.id}
                                    onClick={() => this.manualPayment(participant, "cash")}
                                    className="p-1 mx-2 text-xs text-white border rounded bg-orange border-orange hover:bg-orange-dark hover:border-orange-dark">
                                    {submittingPaymentId === participant.id &&
                                    submittingPaymentType === "cash" ? (
                                      <Trans>Paying...</Trans>
                                    ) : (
                                      <Trans>Cash</Trans>
                                    )}
                                  </button>
                                  {user.type === "Staff" ? (
                                    <button
                                      disabled={submittingPaymentId === participant.id}
                                      onClick={() => this.manualPayment(participant, "wechat")}
                                      className="p-1 text-xs text-white border rounded bg-green border-green hover:bg-green-dark hover:border-green-dark">
                                      {submittingPaymentId === participant.id &&
                                      submittingPaymentType === "wechat" ? (
                                        <Trans>Paying...</Trans>
                                      ) : (
                                        <Trans>WeChat</Trans>
                                      )}
                                    </button>
                                  ) : null}
                                </>
                              ) : null}
                              {user.role === "admin" || user.role === "manager" ? (
                                <button
                                  disabled={deletingParticipantId === participant.id}
                                  onClick={() => this.removeParticipant(participant)}
                                  className="ml-2 border rounded text-red border-red hover:bg-red hover:text-white"
                                  style={{
                                    height: 23,
                                    width: 23
                                  }}>
                                  <Icon size={10} icon="close" />
                                </button>
                              ) : null}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )}

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Gallery</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Current Photos</Trans>
                  </label>
                  {!images.length ? (
                    <span className="text-sm">
                      <Trans>No photos yet.</Trans>
                    </span>
                  ) : (
                    <Gallery images={images} />
                  )}
                </div>

                <div className="form-actions">
                  {user.role === "admin" ||
                  user.role === "teacher" ||
                  user.role === "team_leader" ||
                  user.role === "manager" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block ml-auto btn btn-solid-indigo no-external"
                      download
                      href={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/trip-pdf/${id}?token=${localStorage.getItem("INDIER_JWT")}`}>
                      <Trans>PDF</Trans>
                    </a>
                  ) : null}
                  {user.role === "admin" ||
                  user.role === "teacher" ||
                  user.role === "team_leader" ||
                  user.role === "manager" ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block ml-auto btn btn-solid-green no-external"
                      download
                      href={`${
                        process.env.REACT_APP_API_BASE_URL
                      }/medical-trip-pdf/${id}?token=${localStorage.getItem("INDIER_JWT")}`}>
                      <Trans>Individual Student Info</Trans>
                    </a>
                  ) : null}
                  {user.role === "admin" || user.role === "manager" ? (
                    <>
                      {lead_teachers.length || teachers.length ? (
                        <button
                          disabled={isNotifyingTeachers}
                          className="block mr-2 btn btn-solid-orange"
                          onClick={this.notifyTeachers}>
                          {isNotifyingTeachers ? (
                            <Trans>Notifying Teachers...</Trans>
                          ) : (
                            <Trans>Notify Teachers</Trans>
                          )}
                        </button>
                      ) : null}
                      {participants.filter(p => p.payment_status === "unpaid").length ? (
                        <button
                          disabled={isSendingPaymentReminder}
                          className="block btn btn-solid-green"
                          onClick={this.sendPaymentReminder}>
                          {isSendingPaymentReminder ? (
                            <Trans>Sending Reminder...</Trans>
                          ) : (
                            <Trans>Payment Reminder</Trans>
                          )}
                        </button>
                      ) : null}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block ml-auto btn btn-solid-teal no-external"
                        download
                        href={`${
                          process.env.REACT_APP_API_BASE_URL
                        }/trip-csv/${id}?token=${localStorage.getItem("INDIER_JWT")}`}>
                        <Trans>CSV</Trans>
                      </a>
                      <Link
                        className="block ml-auto btn btn-solid-pink"
                        to={`/trips/${id}/feedback`}>
                        <Trans>Feedback</Trans>
                      </Link>
                      <Link className="block ml-auto btn btn-solid-blue" to={`/trips/${id}/edit`}>
                        <Trans>Edit</Trans>
                      </Link>
                    </>
                  ) : null}
                </div>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default TripShow;
