import React, { Component } from "react";
import moment from "moment";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { Link } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";
import LoadingWrapper from "../components/LoadingWrapper";
import Advert from "../components/Advert";
import MediaUpload from "../components/MediaUpload";
import { createResource, fetchResources, updateResource } from "../api";
import { i18n } from "../App";

class AdvertCreate extends Component {
  static contextType = ApiContext;
  state = {
    title: "",
    description: "",
    url: "",
    trip_id: "",
    published_at: "",
    trips: [],
    image_id: null,
    isLoading: false,
    isSubmitting: false,
    isFetchingTrips: false
  };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      this.fetchAdvert();
    }
    this.fetchTrips();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchAdvert = () => {
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources(`adverts/${id}`))
      .then(({ data }) => {
        this.safeSetState({
          title: data.title,
          description: data.description,
          trip_id: data.trip_id,
          url: data.url,
          trip_code: data.trip_code,
          trip_name: data.trip_name,
          published_at: data.published_at ? moment(data.published_at).format("YYYY-MM-DD") : "",
          image_id: data.image_id,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch adverts.</Trans>);
        }
        toast.error(error);
      });
  };

  fetchTrips = () => {
    this.safeSetState({ isFetchingTrips: true });
    this.context
      .callApi(() => fetchResources("trips?adverts"))
      .then(({ data }) => {
        this.safeSetState({
          trips: data,
          isFetchingTrips: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isFetchingTrips: false, error });
        if (typeof error === "object") {
          return toast.error(<Trans>Failed to fetch trips.</Trans>);
        }
        toast.error(error);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      match: {
        params: { id }
      }
    } = this.props;
    if (id) {
      return this.handleUpdate();
    }
    let { title, image_id, description, trip_id, published_at, url } = this.state;

    this.safeSetState({ isSubmitting: true, error: null });

    let data = {
      title,
      description,
      image_id,
      url,
      published_at,
      trip_id
    };

    this.context
      .callApi(() => createResource("/adverts", data))
      .then(() => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Advert created!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create advert.</Trans>);
      });
  };

  handleUpdate = () => {
    let { title, image_id, description, trip_id, published_at, url } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    let data = {
      title,
      description,
      url,
      published_at,
      image_id,
      trip_id
    };
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.context
      .callApi(() => updateResource("/adverts/" + id, data))
      .then(() => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Advert updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        if (error.message) {
          return toast.error(error.message);
        }
        toast.error(<Trans>Failed to create advert.</Trans>);
      });
  };

  handleImageChange = (data, file) => {
    this.safeSetState({
      image_id: data.id
    });
  };

  render() {
    let {
      title,
      trip_id,
      image_id,
      isLoading,
      url,
      published_at,
      trips,
      isSubmitting,
      isFetchingTrips,
      error,
      description
    } = this.state;
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8" style={{ maxWidth: 700 }}>
          <div>
            <h1 className="page-header">
              {id ? <Trans>Update Advert</Trans> : <Trans>Create an Advert</Trans>}
            </h1>
            <LoadingWrapper isLoading={isLoading || isFetchingTrips}>
              <div className="form-container">
                <form action="#" onSubmit={this.handleSubmit}>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Title</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        disabled={isSubmitting}
                        className={`${error && error.title ? "border-red" : ""} form-input w-full`}
                        value={title}
                        onChange={e => this.safeSetState({ title: e.target.value })}
                      />
                      {error && error.title && (
                        <span className="text-red text-xs block mt-1">{error.title}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Trip Details (URL)</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="text"
                        disabled={isSubmitting}
                        className={`${error && error.url ? "border-red" : ""} form-input w-full`}
                        value={url}
                        onChange={e =>
                          this.safeSetState({
                            url: e.target.value
                          })
                        }
                      />
                      {error && error.url && (
                        <span className="text-red text-xs block mt-1">{error.url}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Published At</Trans>
                      <br />
                      <Trans>(leave empty to hide)</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="date"
                        disabled={isSubmitting}
                        className={`${
                          error && error.published_at ? "border-red" : ""
                        } form-input w-full`}
                        value={published_at}
                        onChange={e =>
                          this.safeSetState({
                            published_at: e.target.value
                          })
                        }
                      />
                      {error && error.published_at && (
                        <span className="text-red text-xs block mt-1">{error.published_at}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Trip</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        disabled={!trips.length || isSubmitting}
                        value={trip_id}
                        className="form-input w-full"
                        onChange={e =>
                          this.safeSetState({
                            trip_id: parseInt(e.target.value, 10)
                          })
                        }>
                        <option value="">-- {i18n._(t`Select a Trip`)} --</option>
                        {trips.map((trip, index) => (
                          <option key={index} value={trip.id}>
                            {trip.name} ({moment.utc(trip.start_date).format("LL")} -{" "}
                            {moment.utc(trip.end_date).format("LL")})
                          </option>
                        ))}
                      </select>
                      {!isLoading && !trips.length && (
                        <span className="text-red text-xs block mt-1">
                          <Trans>No upcoming open trips.</Trans>
                        </span>
                      )}
                      {error && error.trip_id && (
                        <span className="text-red text-xs block mt-1">{error.trip_id}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Description</Trans>
                    </label>
                    <div className="w-full">
                      <textarea
                        rows="5"
                        className={`${
                          error && error.description ? "border-red" : ""
                        } form-input w-full`}
                        onChange={e =>
                          this.safeSetState({
                            description: e.target.value
                          })
                        }
                        disabled={isSubmitting}
                        value={description}
                      />
                      {error && error.description && (
                        <span className="text-red text-xs block mt-1">{error.description}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">Image</label>
                    <div className="w-full">
                      <MediaUpload
                        onSuccess={this.handleImageChange}
                        url={`/images`}
                        accept="image/*"
                      />
                      <span className="block text-xs text-grey leading-normal">Images only.</span>
                      {error && error.image_id && (
                        <span className="text-red text-xs block mt-1">{error.image_id}</span>
                      )}
                    </div>
                  </div>

                  <div className="m-4 p-4 shadow rounded border text-sm flex flex-col items-center justify-center">
                    <h3 className="mb-4">
                      <Trans>Advert Preview</Trans>
                    </h3>
                    <Advert
                      title={title}
                      url={url}
                      trip_name={
                        trips.filter(t => t.id === trip_id).length
                          ? trips.filter(t => t.id === trip_id)[0].name
                          : ""
                      }
                      trip_code={
                        trips.filter(t => t.id === trip_id).length
                          ? trips.filter(t => t.id === trip_id)[0].code
                          : ""
                      }
                      description={description}
                      image_id={image_id}
                    />
                  </div>

                  <div className="form-actions border-t">
                    <Link
                      to="/adverts"
                      style={{ minWidth: "150px" }}
                      type="submit"
                      className="btn w-full btn-solid-red">
                      <Trans>Cancel</Trans>
                    </Link>
                    {process.env.NODE_ENV === "development" ? (
                      <span
                        onClick={() =>
                          this.setState({
                            title: "Advert Title",
                            description: "Woah!"
                          })
                        }
                        className="btn mx-auto">
                        <Trans>Generate</Trans>
                      </span>
                    ) : null}
                    <button
                      style={{ minWidth: "150px" }}
                      type="submit"
                      className="ml-auto w-full btn btn-solid-blue"
                      disabled={isSubmitting}>
                      {isSubmitting ? (
                        id ? (
                          <Trans>Updating...</Trans>
                        ) : (
                          <Trans>Creating...</Trans>
                        )
                      ) : id ? (
                        <Trans>Update Advert</Trans>
                      ) : (
                        <Trans>Create Advert</Trans>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default AdvertCreate;
