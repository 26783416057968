import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources } from "../api";

class TeacherShow extends Component {
  static contextType = ApiContext;
  state = {
    full_name: "",
    email: "",
    school: "",
    phone: "",
    message: "",
    trips: [],
    participants: [],
    isLoading: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchTeacher();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchTeacher();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchTeacher = () => {
    const { callApi } = this.context;
    const {
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources("teachers/" + id))
      .then(({ data }) => {
        this.safeSetState({
          full_name: data.full_name,
          email: data.email,
          school: data.school,
          phone: data.phone,
          school_id: data.school_id,
          trips: data.trips,
          participants: data.participants,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error,
          message: error.status === 403 ? <Trans>You are not allowed to view this item.</Trans> : ""
        });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  render() {
    const {
      full_name,
      email,
      school,
      school_id,
      phone,
      participants,
      isLoading,
      error,
      message,
      trips
    } = this.state;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>Teacher Details</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading} error={error} message={message}>
              <div className="form-container form-read-only">
                <div className="form-section-header bg-grey-lighter">
                  <Trans>Basic Info</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">{full_name}</span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Email</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">
                      <a href={`mailto:${email}`}>{email}</a>
                    </span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Phone</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">
                      {phone ? <a href={`tel:${phone}`}>{phone}</a> : <Trans>None</Trans>}
                    </span>
                  </div>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>School</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">
                      {user.role === "admin" || user.role === "manager" ? (
                        <Link to={`/schools/${school_id}`}>{school}</Link>
                      ) : (
                        school
                      )}
                    </span>
                  </div>
                </div>

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Participants</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">
                      {!participants.length && (
                        <div>
                          <Trans>None</Trans>
                        </div>
                      )}
                      {participants.map(p => (
                        <div key={p.id} className="mb-1">
                          <Link to={`/participants/${p.id}`}>{p.full_name}</Link>
                        </div>
                      ))}
                    </span>
                  </div>
                </div>

                <div className="form-section-header bg-grey-lighter">
                  <Trans>Registered Trips</Trans>
                </div>
                <div className="form-input-group">
                  <label className="form-label">
                    <Trans>Trip Name</Trans>
                  </label>
                  <div className="w-full">
                    <span className="form-input w-full">
                      {!trips.length && (
                        <div>
                          <Trans>None</Trans>
                        </div>
                      )}
                      {trips.map((trip, index) => (
                        <div key={index} className="mb-1">
                          <Link to={`/trips/${trip.slug}`}>{trip.trip_name}</Link> (
                          {trip.participant_full_name === "LEAD_TEACHER" ? (
                            <Trans>Lead Teacher</Trans>
                          ) : (
                            <Link to={`/participants/${trip.participant_id}`}>
                              {trip.participant_full_name}
                            </Link>
                          )}
                          )
                        </div>
                      ))}
                    </span>
                  </div>
                </div>

                {/* <div className="form-actions"> */}
                {/*     <Link */}
                {/*         className="btn btn-solid-blue block ml-auto" */}
                {/*         to={`/teachers/${id}/edit`}> */}
                {/*         <Trans>Edit</Trans> */}
                {/*     </Link> */}
                {/* </div> */}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default TeacherShow;
