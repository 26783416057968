import React, { Component } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import Icon from "../components/Icon";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, createResource as postResource } from "../api";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "#EFF8FF" : "white",
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#EFF8FF" : "white",
  width: "100%"
});

class Feedback extends Component {
  static contextType = ApiContext;
  state = {
    error: undefined,
    isLoading: true,
    issues: [],
    openCount: null,
    closedCount: null,
    issuesByDate: [],
    isSubmitting: false,
    isUpdating: false,
    sortBy: "order",
    status: "open"
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchFeedback();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchFeedback = (url = null, open = true) => {
    this.setState({
      isLoading: url ? false : true,
      isUpdating: true,
      error: null,
      status: open ? "open" : "closed"
    });

    this.context
      .callApi(() => fetchResources(`feedback/?admin&${open ? "is_open=true" : "is_closed=true"}`))
      .then(({ data }) => {
        this.safeSetState({
          issues: data.data,
          openCount: data.open_count,
          closedCount: data.closed_count,
          issuesByDate: _.orderBy(data.data, ["created_at"], ["desc"]),
          isLoading: false,
          isUpdating: false
        });

        localStorage.setItem("INDIER_LAST_FEEDBACK_FETCH", moment().toISOString());
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          isUpdating: false,
          error
        });
      });
  };

  onDragEnd = result => {
    if (this.state.sortBy !== "order") {
      return;
    }
    const preDragItems = [...this.state.issues];

    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const issues = reorder(this.state.issues, result.source.index, result.destination.index);
    const payload = issues.map(item => item.id);
    this.safeSetState({ isSubmitting: true, issues });
    this.context
      .callApi(() => postResource(`feedback/order`, { order: payload }))
      .then(res => {
        this.safeSetState({
          isSubmitting: false
        });
        toast.success(<Trans>Ordering updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error, issues: preDragItems });
        toast.error(<Trans>Failed to reorder issues.</Trans>);
      });
  };

  changeSortBy = () => {
    this.safeSetState({
      sortBy: this.state.sortBy === "order" ? "data" : "order"
    });
  };

  render() {
    const {
      issues,
      openCount,
      closedCount,
      isSubmitting,
      isUpdating,
      isLoading,
      issuesByDate,
      sortBy,
      status
    } = this.state;

    const sortedIssues = sortBy === "order" ? issues : issuesByDate;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>User Feedback</Trans>
            </h1>
            <div className="flex items-center px-4 rounded-t bg-grey-lighter justify-between border-b">
              <div className="text-sm flex">
                <span
                  className={`${
                    status === "open"
                      ? "text-grey-darker hover:text-grey-darkest"
                      : "text-grey-dark hover:text-grey-darker"
                  } block mr-2 py-4 cursor-pointer`}
                  onClick={this.fetchFeedback}>
                  {openCount} <Trans>Open</Trans>
                </span>
                <span
                  className={`${
                    status === "closed"
                      ? "text-grey-darker hover:text-grey-darkest"
                      : "text-grey-dark hover:text-grey-darker"
                  } block mr-2 py-4 cursor-pointer`}
                  onClick={() => this.fetchFeedback(null, false)}>
                  {closedCount} <Trans>Closed</Trans>
                </span>
              </div>
              <div className="text-sm flex items-center">
                <span
                  className="block text-grey-dark py-4 mr-4 hover:text-grey-darker cursor-pointer"
                  onClick={this.changeSortBy}>
                  {sortBy === "order" ? <Trans>By Date</Trans> : <Trans>By Severity</Trans>}
                </span>
                <Link className="btn btn-solid btn-xs" to="/feedback">
                  <Trans>New Issue</Trans>
                </Link>
              </div>
            </div>

            <div className="mb-2">
              <LoadingWrapper isLoading={isLoading} message={<Trans>Fetching issues...</Trans>}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <Droppable
                    isDropDisabled={isSubmitting || sortBy !== "order"}
                    droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {!issues.length ? (
                          <div className="list-item p-4 text-sm rounded-b">
                            <Trans>All quiet on the western front...</Trans>
                          </div>
                        ) : (
                          sortedIssues.map((issue, index) => (
                            <Draggable key={issue.id} draggableId={issue.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                  className={`list-issue`}>
                                  <IssueRow issue={issue} isUpdating={isUpdating} />
                                </div>
                              )}
                            </Draggable>
                          ))
                        )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </LoadingWrapper>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const IssueRow = ({ issue, isUpdating }) => (
  <div key={issue.id} className="flex border-b p-4">
    <div className={`text-${issue.closed_at ? "red" : "teal"}`}>
      <Icon icon={issue.closed_at ? "closed-issue" : "open-issue"} />
    </div>
    <div>
      <div className="flex mb-2">
        <div className="text-lg mr-2 flex flex-col md:flex-row">
          <Link
            className="mb-1 md:mb-0 text-grey-darker hover:text-teal no-underline"
            to={`/admin-feedback/${issue.id}`}>
            {issue.title}
          </Link>
          {/* {issue.files.length ? ( */}
          {/*     <span className="ml-2 text-indigo"> */}
          {/*         <Icon icon="attachment" size={20} /> */}
          {/*     </span> */}
          {/* ) : null} */}
          <div className="whitespace-no-wrap text-xs md:ml-2">
            <span
              className={`inline-block text-white rounded mr-1 p-1 bg-${
                issue.severity === "high" ? "red" : issue.severity === "medium" ? "orange" : "blue"
              }`}>
              {issue.severity}
            </span>
            <span
              className={`inline-block text-white rounded mr-1 p-1 bg-${
                issue.type === "bug" ? "red" : issue.type === "feature" ? "green" : "pink"
              }`}>
              {issue.type}
            </span>
            <span className={`inline-block text-white rounded mr-1 p-1 bg-grey`}>
              {issue.platform}
            </span>
            {issue.image ? (
              <span className="text-indigo">
                <Icon icon="attachment" />
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <div>
        <div className="text-xs text-grey-dark mr-2 whitespace-no-wrap">
          #{issue.id} opened {moment(issue.created_at).fromNow()} by{" "}
          <a
            className="text-grey-dark no-underline hover:text-teal"
            href={`mailto:${issue.email}?subject=RE: ${issue.title}`}>
            {issue.email}
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default Feedback;
