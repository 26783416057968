import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans, t } from "@lingui/macro";
import { i18n } from "../App";
import { Link } from "react-router-dom";
import moment from "moment";
import { fetchResources as getRequest, createResource } from "../api";
import { ApiContext } from "../contexts/ApiContext";
import history from "../components/history";
// import Icon from "../components/Icon";
import LoadingWrapper from "../components/LoadingWrapper";

class TripRegistration extends Component {
  static contextType = ApiContext;
  state = {
    participants: [],
    participant_id: "",
    code: "",
    signature: "",
    waiver: "",
    waiver_read: false,
    promo_emails: false,
    chinese_waiver: "",
    trip: null,
    isLoading: false,
    isFetchingTrip: false,
    isLoadingOpenTrips: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchParticipants();
    const { code } = this.props.match.params;
    if (code) {
      this.safeSetState({ code }, () => this.fetchTripByCode());
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchTripByCode = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    const { callApi } = this.context;
    const { code } = this.state;

    if (!code) {
      return;
    }
    this.safeSetState({ isFetchingTrip: true, error: null });
    callApi(() => createResource("trip-by-code", { code }))
      .then(
        ({
          data: {
            trip,
            waiver: { content, chinese_content }
          }
        }) => {
          this.safeSetState({
            trip: trip,
            waiver: content,
            chinese_waiver: chinese_content,
            isFetchingTrip: false
          });
        }
      )
      .catch(error => {
        this.safeSetState({ isFetchingTrip: false, error: error.errors });
        // toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchParticipants = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoading: true, error: null });
    callApi(() => getRequest("participants?simple"))
      .then(({ data }) => {
        this.safeSetState({
          participants: data,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  registerParticipant = e => {
    e.preventDefault();
    const { callApi } = this.context;
    const {
      trip: { id },
      signature,
      participant_id,
      waiver,
      promo_emails,
      waiver_read,
      chinese_waiver
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    const payload = {
      signature,
      participant_id,
      promo_emails,
      waiver,
      waiver_read,
      chinese_waiver
    };
    callApi(() => createResource("trip-registration/" + id, payload))
      .then(({ data }) => {
        this.safeSetState({
          isSubmitting: false
        });
        history.push("/participants/" + participant_id);
        toast.success(
          <Trans>
            Registration successful. Please check the participant details are correct. If the
            details are correct, please view your trips to pay.
          </Trans>,
          { autoClose: false }
        );
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(<Trans>Failed to register.</Trans>);
      });
  };

  render() {
    const {
      participants,
      isLoading,
      promo_emails,
      error,
      isSubmitting,
      participant_id,
      code,
      trip,
      signature,
      isFetchingTrip,
      waiver,
      chinese_waiver,
      isLoadingOpenTrips,
      waiver_read
    } = this.state;
    const { user } = this.context;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              <Trans>School Trip Registration</Trans>
            </h1>
            <LoadingWrapper isLoading={isLoading || isLoadingOpenTrips}>
              <div className="form-container mb-4">
                {!participants.length ? (
                  <div className="p-4 text-sm">
                    <Trans>
                      You need to create a participant before you can register for a trip. Click{" "}
                      <Link to="/participants/create">here</Link> to create a participant.
                    </Trans>
                  </div>
                ) : null}
                {!participants.length ? null : (
                  <form
                    method="POST"
                    onSubmit={trip ? this.registerParticipant : this.fetchTripByCode}>
                    <div className="form-section-header bg-grey-lighter">
                      <Trans>Registration Code</Trans>
                    </div>

                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Trip Code</Trans>
                      </label>
                      <div className="w-full">
                        <div className="flex w-full">
                          <input
                            className="form-input mr-2 flex-1"
                            type="text"
                            value={code}
                            autoFocus
                            disabled={isSubmitting}
                            onChange={e =>
                              this.safeSetState({
                                code: e.target.value
                              })
                            }
                          />
                          <button
                            onClick={this.fetchTripByCode}
                            type="button"
                            disabled={isFetchingTrip}
                            className="btn btn-solid-blue">
                            {isFetchingTrip ? (
                              <Trans>Fetching trip details...</Trans>
                            ) : (
                              <Trans>View Trip Details</Trans>
                            )}
                          </button>
                        </div>
                        {error && error.code && (
                          <span className="text-red text-xs">{error.code}</span>
                        )}
                      </div>
                    </div>

                    {trip && (
                      <LoadingWrapper isLoading={isLoading}>
                        <div className="form-input-group">
                          <label className="form-label">
                            <Trans>Trip Details</Trans>
                          </label>
                          <div className="w-full">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>Trip Name</Trans>
                                  </td>
                                  <td>{trip.trip_name}</td>
                                </tr>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>School Name</Trans>
                                  </td>
                                  <td>{trip.school_name}</td>
                                </tr>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>Location</Trans>
                                  </td>
                                  <td>{trip.location_name}</td>
                                </tr>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>Price</Trans>
                                  </td>
                                  <td>{trip.price} CNY</td>
                                </tr>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>Start Date</Trans>
                                  </td>
                                  <td>{moment(trip.start_date).format("LL")}</td>
                                </tr>
                                <tr>
                                  <td className="pr-4 py-1 text-sm">
                                    <Trans>End Date</Trans>
                                  </td>
                                  <td>{moment(trip.end_date).format("LL")}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="form-input-group">
                          <label className="form-label">
                            <Trans>Participant</Trans>
                          </label>
                          <div className="w-full">
                            <select
                              value={participant_id}
                              disabled={isSubmitting}
                              className="form-input w-full"
                              onChange={e =>
                                this.safeSetState({
                                  participant_id: e.target.value
                                })
                              }>
                              <option value="">-- {i18n._(t`Select Participant`)} --</option>
                              {participants.map((participant, index) => (
                                <option key={index} value={participant.id}>
                                  {participant.full_name}
                                </option>
                              ))}
                            </select>
                            {error && error.participant_id && (
                              <span className="text-red text-xs">{error.participant_id}</span>
                            )}
                          </div>
                        </div>

                        <div className="form-section-header bg-grey-lighter">
                          <Trans>Legal</Trans>
                        </div>

                        <div className="form-input-group">
                          <label className="form-label">
                            <Trans>Waiver</Trans>
                          </label>
                          <div
                            style={{ maxHeight: 300 }}
                            className="form-waiver form-input w-full overflow-y-auto"
                            dangerouslySetInnerHTML={{
                              __html: user.locale === "en" ? waiver : chinese_waiver
                            }}
                          />
                        </div>

                        <div className="p-4 border-b">
                          <label className="flex text-xs cursor-pointer justify-end">
                            <span className="mr-2">
                              <Trans>
                                By checking this box you are confirming you have read and agree with
                                the waiver above.
                              </Trans>
                            </span>
                            <input
                              type="checkbox"
                              value={waiver_read}
                              disabled={isSubmitting}
                              className="bg-white"
                              onChange={e =>
                                this.safeSetState(prevState => ({
                                  waiver_read: !prevState.waiver_read
                                }))
                              }
                            />
                          </label>

                          {error && error.waiver_read && (
                            <div className="text-right mt-2 text-red text-xs">
                              {error.waiver_read}
                            </div>
                          )}
                        </div>

                        <div className="form-input-group">
                          <label className="form-label leading-normal">
                            <Trans>
                              Parent or Legal
                              <br />
                              Guardians Name
                            </Trans>
                          </label>
                          <div className="w-full">
                            <input
                              type="text"
                              value={signature}
                              disabled={isSubmitting}
                              className="form-input w-full"
                              onChange={e =>
                                this.safeSetState({
                                  signature: e.target.value
                                })
                              }
                            />
                            {error && error.signature && (
                              <span className="text-red text-xs">{error.signature}</span>
                            )}
                          </div>
                        </div>

                        <div className="p-4 border-b">
                          <label className="flex text-xs cursor-pointer justify-end">
                            <span className="mr-2">
                              <Trans>
                                We occasionally send out promotional emails, check the box to stay
                                in touch!
                              </Trans>
                            </span>
                            <input
                              type="checkbox"
                              value={promo_emails}
                              disabled={isSubmitting}
                              className="bg-white"
                              onChange={e =>
                                this.safeSetState(prevState => ({
                                  promo_emails: !prevState.promo_emails
                                }))
                              }
                            />
                          </label>
                        </div>
                      </LoadingWrapper>
                    )}

                    <div className="form-actions">
                      <Link className="btn btn-solid-red" to={`/my-trips`}>
                        <Trans>Cancel</Trans>
                      </Link>
                      {trip ? (
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className="btn btn-solid-blue">
                          {isSubmitting ? <Trans>Registering...</Trans> : <Trans>Register</Trans>}
                        </button>
                      ) : null}
                    </div>
                  </form>
                )}
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}
export default TripRegistration;
