import React, { Component } from "react";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import LoadingWrapper from "../components/LoadingWrapper";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource } from "../api";

class RiskAssessmentTemplate extends Component {
  static contextType = ApiContext;
  state = {
    isLoading: false,
    isUpdating: false,
    general_intro: "",
    specific_intro: "",
    zh_general_intro: "",
    zh_site_specific_intro: ""
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchRiskAssessmentIntro();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchRiskAssessmentIntro = () => {
    this.safeSetState({ isLoading: true });
    this.context
      .callApi(() => fetchResources(`general_risk_assessment`))
      .then(({ data }) => {
        this.safeSetState({
          general_intro: data.general_intro,
          site_specific_intro: data.site_specific_intro,
          zh_general_intro: data.zh_general_intro,
          zh_site_specific_intro: data.zh_site_specific_intro,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false });
        toast.error(<Trans>Failed to fetch intro.</Trans>);
      });
  };

  updateRiskAssessmentIntro = () => {
    this.safeSetState({ isUpdating: true });
    this.context
      .callApi(() =>
        updateResource(`general_risk_assessment`, {
          site_specific_intro: this.state.site_specific_intro,
          general_intro: this.state.general_intro,
          zh_site_specific_intro: this.state.zh_site_specific_intro,
          zh_general_intro: this.state.zh_general_intro
        })
      )
      .then(({ data }) => {
        this.safeSetState({
          general_intro: data.site_specific_intro,
          specific_intro: data.general_intro,
          isUpdating: false
        });
        toast.success(<Trans>Updated risk assessment intro!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isUpdating: false });
        toast.error(<Trans>Failed to update intro.</Trans>);
      });
  };

  render() {
    const {
      isLoading,
      isUpdating,
      general_intro,
      site_specific_intro,
      zh_general_intro,
      zh_site_specific_intro
    } = this.state;

    return (
      <div>
        <LoadingWrapper isLoading={isLoading}>
          <div>
            <div className="mb-4 pb-4 border-b">
              <div className="mb-4">
                <label className="text-sm mb-1 block">General Introduction</label>
                <textarea
                  disabled={isUpdating}
                  value={general_intro}
                  onChange={e =>
                    this.setState({
                      general_intro: e.target.value
                    })
                  }
                  className="form-input w-full mb-2"
                  rows={10}
                />
              </div>
              <div className="mb-4">
                <label className="text-sm mb-1 block">Site Specific Introduction</label>
                <textarea
                  disabled={isUpdating}
                  value={site_specific_intro}
                  onChange={e =>
                    this.setState({
                      site_specific_intro: e.target.value
                    })
                  }
                  className="form-input w-full mb-2"
                  rows={10}
                />
              </div>
            </div>

            <div className="">
              <div className="mb-4">
                <label className="text-sm mb-1 block">Chinese General Introduction</label>
                <textarea
                  disabled={isUpdating}
                  value={zh_general_intro}
                  onChange={e =>
                    this.setState({
                      zh_general_intro: e.target.value
                    })
                  }
                  className="form-input w-full mb-2"
                  rows={10}
                />
              </div>
              <div className="mb-4">
                <label className="text-sm mb-1 block">Chinese Site Specific Introduction</label>
                <textarea
                  disabled={isUpdating}
                  value={zh_site_specific_intro}
                  onChange={e =>
                    this.setState({
                      zh_site_specific_intro: e.target.value
                    })
                  }
                  className="form-input w-full mb-2"
                  rows={10}
                />
              </div>
            </div>

            <button
              disabled={isUpdating}
              onClick={this.updateRiskAssessmentIntro}
              className="btn btn-solid">
              <Trans>Update</Trans>
            </button>
          </div>
        </LoadingWrapper>
      </div>
    );
  }
}

export default RiskAssessmentTemplate;
