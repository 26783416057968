import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const MyParticipants = props => (
  <div>
    <ResourceIndex
      endpoint="participants"
      canCreate={true}
      canSearch={false}
      model={<Trans>Participants</Trans>}
      headers={["full_name"]}
      translations={[<Trans>Name</Trans>]}
      actions={["show", "edit", "delete"]}
      {...props}
    />
  </div>
);

export default MyParticipants;
