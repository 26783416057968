import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
import DeleteButton from "../components/DeleteButton";
import { ApiContext } from "../contexts/ApiContext";
import { fetchResources, updateResource, createResource } from "../api";

class ResourceEdit extends Component {
  static propTypes = {
    endpoint: PropTypes.string.isRequired,
    model: PropTypes.string.isRequired,
    isCreate: PropTypes.bool.isRequired
  };
  static contextType = ApiContext;
  static defaultProps = { isCreate: false };
  state = {
    item: null,
    name: "",
    translation: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.isCreate) return;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    const { callApi } = this.context;
    const {
      endpoint,
      match: {
        params: { id }
      }
    } = this.props;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources(endpoint + "/" + id))
      .then(({ data }) => {
        this.safeSetState({
          name: data.name,
          translation: data.translation,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleCreate = () => {
    const { callApi } = this.context;
    const { endpoint } = this.props;
    const { name, translation } = this.state;
    this.safeSetState({ isSubmitting: true });

    callApi(() =>
      createResource(`${endpoint}`, {
        name,
        translation
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({
          isSubmitting: false,
          error: null
        });
        history.push(`/${endpoint}/${res.data.slug ? res.data.slug : res.data.id}/edit`);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (this.props.isCreate) {
      return this.handleCreate();
    }

    const {
      endpoint,
      match: {
        params: { id }
      }
    } = this.props;
    const { name, translation } = this.state;
    const { callApi } = this.context;

    this.safeSetState({ isSubmitting: true });

    callApi(() => updateResource(`${endpoint}/${id}`, { name, translation }))
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({
          isSubmitting: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleDelete = () => {
    history.push("/" + this.props.endpoint);
  };

  render() {
    const {
      endpoint,
      match: {
        params: { id }
      },
      model,
      isCreate
    } = this.props;
    const { name, isLoading, error, isSubmitting, translation } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {isCreate ? <Trans>Create</Trans> : <Trans>Edit</Trans>} {model}
            </h1>
            <LoadingWrapper isLoading={isLoading} retry={() => this.fetchItem(endpoint)}>
              {(name || isCreate) && (
                <div className="form-container">
                  <form method="POST" onSubmit={this.handleSubmit}>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Name</Trans>
                      </label>
                      <div className="w-full">
                        <input
                          className="form-input w-full"
                          type="text"
                          value={name}
                          disabled={isSubmitting}
                          onChange={e => this.safeSetState({ name: e.target.value })}
                        />
                        {error && error.name && (
                          <span className="text-red text-xs">{error.name}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-input-group">
                      <label className="form-label">
                        <Trans>Translation</Trans>
                      </label>
                      <div className="w-full">
                        <input
                          className="form-input w-full"
                          type="text"
                          value={translation}
                          disabled={isSubmitting}
                          onChange={e =>
                            this.safeSetState({
                              translation: e.target.value
                            })
                          }
                        />
                        {error && error.translation && (
                          <span className="text-red text-xs">{error.translation}</span>
                        )}
                      </div>
                    </div>
                    <div className="form-actions">
                      {!isCreate ? (
                        <DeleteButton url={`${endpoint}/${id}`} onDelete={this.handleDelete}>
                          <button type="button" className="btn btn-solid-red">
                            <Trans>Delete</Trans>
                          </button>
                        </DeleteButton>
                      ) : (
                        <Link className="btn btn-solid-red" to={`/${endpoint}`}>
                          <Trans>Cancel</Trans>
                        </Link>
                      )}
                      <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                        {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default ResourceEdit;
