import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Trans, t } from "@lingui/macro";
import history from "../components/history";
import LoadingWrapper from "../components/LoadingWrapper";
import DeleteButton from "../components/DeleteButton";
import MediaUpload from "../components/MediaUpload";
import GlobalSearch from "../components/GlobalSearch";
import Icon from "../components/Icon";
import { ApiContext } from "../contexts/ApiContext";
import { createResource, fetchResources, updateResource } from "../api";
import moment from "moment";
import { i18n } from "../App";

class TripCreateForm extends Component {
  static contextType = ApiContext;
  state = {
    name: "",
    school_id: "",
    location_id: "",
    trip_leader_id: "",
    teachers: [],
    teacher_given_names: "",
    teacher_family_name: "",
    teacher_email: "",
    freelancers: "",
    price: 0,
    code: "",
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    expected_participants: 0,
    prepaid: false,
    open_registration: false,
    locations: [],
    staff: [],
    allocated_staff: [],
    trip_attachments: [],
    schools: [],
    isLoading: false,
    isLoadingSchools: false,
    isLoadingLocations: false,
    isLoadingStaff: false,
    isAddingParticipant: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchLocations();
    this.fetchSchools();
    if (this.props.match.params.id) {
      this.fetchTrip();
      this.fetchStaff();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id === undefined && this.props.match.params.id !== undefined) {
      this.fetchStaff();
    }
  }

  generateTrip = () => {
    this.safeSetState({
      name: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 9),
      end_date: moment().add(1, "days").format("YYYY-MM-DD"),
      school_id: 1,
      location_id: 1,
      price: 0,
      code: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5)
    });
  };

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleDelete = () => {
    toast.success(<Trans>Trip Deleted</Trans>);
    history.push("/trips");
  };

  handleUpload = data => {
    if (this.state.trip_attachments.some(a => a.id === data.id)) {
      return;
    }

    this.safeSetState({
      trip_attachments: this.state.trip_attachments.concat({
        id: data.id,
        filename: data.name ? data.name : data.filename
      })
    });
  };

  handleStaffUpdate = id => {
    this.safeSetState(prevState => ({
      allocated_staff: prevState.allocated_staff.includes(id)
        ? prevState.allocated_staff.filter(med => med !== id)
        : prevState.allocated_staff.concat(id)
    }));
  };

  fetchTrip = () => {
    const { callApi } = this.context;
    const { id } = this.props.match.params;
    this.safeSetState({ isLoading: true });
    callApi(() => fetchResources("trips/" + id + "?edit"))
      .then(({ data }) => {
        this.safeSetState({
          name: data.trip_name,
          school_id: data.school_id,
          location_id: data.location_id,
          trip_leader_id: data.trip_leader_id,
          price: data.price,
          code: data.code,
          teachers: data.teachers,
          freelancers: data.freelancers,
          allocated_staff: data.allocated_staff,
          trip_attachments: data.trip_attachments,
          start_date: moment.utc(data.start_date).format("YYYY-MM-DD"),
          end_date: moment.utc(data.end_date).format("YYYY-MM-DD"),
          expected_participants: data.expected_participants,
          prepaid: data.prepaid,
          open_registration: data.open_registration,
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchStaff = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingStaff: true });
    callApi(() => fetchResources("staffs/?all"))
      .then(({ data }) => {
        this.safeSetState({
          staff: data,
          isLoadingStaff: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingStaff: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchLocations = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingLocations: true });
    callApi(() => fetchResources("locations/?all"))
      .then(({ data }) => {
        this.safeSetState({
          locations: data,
          isLoadingLocations: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingLocations: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  fetchSchools = () => {
    const { callApi } = this.context;
    this.safeSetState({ isLoadingSchools: true });
    callApi(() => fetchResources("schools/?all"))
      .then(({ data }) => {
        this.safeSetState({
          schools: data,
          isLoadingSchools: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoadingSchools: false, error });
        toast.error(<Trans>Failed to fetch item.</Trans>);
      });
  };

  handleSubmit = e => {
    e.preventDefault();
    if (this.props.match.params.id) {
      return this.handleUpdate();
    }
    this.handleCreate();
  };

  handleUpdate = () => {
    const { callApi } = this.context;
    const { id } = this.props.match.params;
    const {
      name,
      school_id,
      location_id,
      price,
      code,
      start_date,
      end_date,
      expected_participants,
      trip_attachments,
      allocated_staff,
      teachers,
      freelancers,
      open_registration,
      prepaid,
      trip_leader_id
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    callApi(() =>
      updateResource(`/trips/${id}`, {
        name,
        school_id,
        location_id,
        trip_leader_id,
        teachers,
        freelancers,
        price,
        code,
        start_date,
        end_date,
        expected_participants,
        allocated_staff,
        trip_attachments,
        open_registration,
        prepaid
      })
    )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
        history.push(`/trips/${res.data.id}/edit`);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleRemoveTeacher = id => {
    const confirm = window.confirm(
      i18n._(
        t`Are you sure you want to remove this teacher from the trip (this will not deleted their account)?`
      )
    );

    if (!confirm) return;

    this.setState(prevState => ({
      teachers: prevState.teachers.filter(a => a.id !== id)
    }));
  };

  handleRemoveAttachment = id => {
    const confirm = window.confirm(
      i18n._(t`Are you sure you want to remove this file from the trip (it will not be deleted)?`)
    );

    if (!confirm) return;

    this.setState(prevState => ({
      trip_attachments: prevState.trip_attachments.filter(a => a.id !== id)
    }));
  };

  handleCreate = () => {
    const {
      name,
      school_id,
      location_id,
      price,
      code,
      start_date,
      end_date,
      expected_participants,
      open_registration,
      prepaid
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() =>
        createResource(`/trips`, {
          name,
          school_id,
          location_id,
          price,
          code,
          start_date,
          end_date,
          expected_participants,
          open_registration,
          prepaid
        })
      )
      .then(res => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({ isSubmitting: false });
        history.push(`/trips/${res.data.slug}/edit`);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleAddParticipant = data => {
    const confirm = window.confirm(`Are you sure you want to add ${data.name} to this trip?`);

    if (!confirm) {
      return;
    }

    const { id } = this.props.match.params;
    this.safeSetState({ isAddingParticipant: true, error: null });
    this.context
      .callApi(() => createResource(`/trips/${id}/add-participant/${data.id}`))
      .then(res => {
        this.safeSetState({ isAddingParticipant: false });
        toast.success(<Trans>Participant added to trip!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isAddingParticipant: false, error: error.errors });
        toast.error(error.message);
      });
  };

  handleTeacherCreate = () => {
    const { teacher_family_name, teacher_given_names, teacher_email, school_id } = this.state;
    if (!teacher_given_names || !teacher_family_name || !teacher_email) {
      return toast.error(<Trans>Please fill in all fields.</Trans>);
    }

    this.safeSetState({ isCreatingTeacher: true, error: null });

    this.context
      .callApi(() =>
        createResource(`/teachers`, {
          family_name: teacher_family_name,
          given_names: teacher_given_names,
          email: teacher_email,
          school_id,
          is_admin: true
        })
      )
      .then(res => {
        toast.success(<Trans>Teacher added!</Trans>);
        this.safeSetState({
          isCreatingTeacher: false,
          teachers: this.state.teachers.concat(res.data),
          teacher_family_name: "",
          teacher_given_names: "",
          teacher_email: ""
        });
      })
      .catch(error => {
        this.safeSetState({ isCreatingTeacher: false, error: error.errors });
        toast.error(error.message);
      });
  };

  render() {
    const { id } = this.props.match.params;
    const {
      name,
      school_id,
      location_id,
      trip_leader_id,
      price,
      code,
      start_date,
      end_date,
      expected_participants,
      open_registration,
      prepaid,
      trip_attachments,
      isLoading,
      isLoadingSchools,
      teachers,
      freelancers,
      teacher_email,
      teacher_given_names,
      teacher_family_name,
      isLoadingLocations,
      isCreatingTeacher,
      isLoadingStaff,
      locations,
      schools,
      staff,
      allocated_staff,
      isSubmitting,
      error
    } = this.state;

    return (
      <div className="main-page-container">
        <div className="p-2 md:p-8">
          <div>
            <h1 className="page-header">
              {id ? <Trans>Edit Trip</Trans> : <Trans>Create Trip</Trans>}
            </h1>
            <LoadingWrapper
              isLoading={isLoading || isLoadingSchools || isLoadingLocations || isLoadingStaff}>
              <div className="form-container">
                <form method="POST" onSubmit={this.handleSubmit}>
                  <div className="form-section-header bg-grey-lighter">
                    <Trans>Basic Details</Trans>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Name</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={name}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ name: e.target.value })}
                      />
                      {error && error.name && (
                        <span className="text-xs text-red">{error.name}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>School</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        value={school_id}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e => this.safeSetState({ school_id: e.target.value })}>
                        <option value="">-- Select School --</option>
                        {schools.map((school, index) => (
                          <option key={index} value={school.id}>
                            {school.name}
                          </option>
                        ))}
                      </select>
                      {error && error.school_id && (
                        <span className="text-xs text-red">{error.school_id}</span>
                      )}
                    </div>
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Location</Trans>
                    </label>
                    <div className="w-full">
                      <select
                        value={location_id}
                        disabled={isSubmitting}
                        className="w-full form-input"
                        onChange={e =>
                          this.safeSetState({
                            location_id: e.target.value
                          })
                        }>
                        <option value="">-- Select Location --</option>
                        {locations.map((location, index) => (
                          <option key={index} value={location.id}>
                            {location.name}
                          </option>
                        ))}
                      </select>
                      {error && error.location_id && (
                        <span className="text-xs text-red">{error.location_id}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Price (per person)</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="number"
                        min={0}
                        step={1}
                        value={price}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ price: e.target.value })}
                      />
                      {error && error.price && (
                        <span className="text-xs text-red">{error.price}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Code</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="text"
                        value={code}
                        disabled={isSubmitting}
                        onChange={e => this.safeSetState({ code: e.target.value })}
                      />
                      {error && error.code && (
                        <span className="text-xs text-red">{error.code}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Start Date</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="date"
                        value={start_date}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            start_date: e.target.value
                          })
                        }
                      />
                      {error && error.start_date && (
                        <span className="text-xs text-red">{error.start_date}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>End Date</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="date"
                        value={end_date}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            end_date: e.target.value
                          })
                        }
                      />
                      {error && error.end_date && (
                        <span className="text-xs text-red">{error.end_date}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans># of Expected Participants</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        className="w-full form-input"
                        type="number"
                        step={1}
                        min={0}
                        value={expected_participants}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.safeSetState({
                            expected_participants: e.target.value
                          })
                        }
                      />
                      {error && error.expected_participants && (
                        <span className="text-xs text-red">{error.expected_participants}</span>
                      )}
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Open Registration</Trans>
                      <br />(<Trans>Summer Camp</Trans>)
                    </label>
                    <div className="w-full">
                      <input
                        type="checkbox"
                        checked={open_registration}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.setState(prevState => ({
                            open_registration: !prevState.open_registration
                          }))
                        }
                      />
                    </div>
                  </div>

                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Prepaid</Trans>
                    </label>
                    <div className="w-full">
                      <input
                        type="checkbox"
                        checked={prepaid}
                        disabled={isSubmitting}
                        onChange={e =>
                          this.setState(prevState => ({
                            prepaid: !prevState.prepaid
                          }))
                        }
                      />
                    </div>
                  </div>

                  {id && (
                    <>
                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Staff</Trans>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Trip Leader</Trans>
                        </label>
                        <div className="w-full">
                          <select
                            value={trip_leader_id}
                            disabled={isSubmitting}
                            className="w-full form-input"
                            onChange={e =>
                              this.safeSetState({
                                trip_leader_id: e.target.value
                              })
                            }>
                            <option value="">-- {i18n._(t`Select Trip Leader`)} --</option>
                            {staff.map((staff, index) => (
                              <option key={index} value={staff.id}>
                                {staff.full_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Staff</Trans>
                        </label>
                        <div className="w-full">
                          {staff.map(staff => (
                            <div key={staff.id} className="mb-1 text-sm">
                              <label className="cursor-pointer">
                                <input
                                  type="checkbox"
                                  onChange={() => this.handleStaffUpdate(staff.id)}
                                  checked={allocated_staff.includes(staff.id)}
                                  className="mr-2"
                                />
                                {staff.full_name}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Freelancers</Trans>
                        </label>
                        <div className="w-full">
                            <div className="mb-1 text-sm">
                              <label className="cursor-pointer">
                                <textarea
                                  value={freelancers}
                                  onChange={e =>
                                      this.setState({
                                        freelancers: e.target.value
                                      })
                                  }
                                  rows={5}
                                  className="w-full mr-2 form-input"
                                >
                                  {freelancers}
                                </textarea>
                              </label>
                            </div>
                        </div>
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Teachers</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Teacher Family Name</Trans>
                        </label>
                        <input
                          className="w-full form-input"
                          type="text"
                          value={teacher_family_name}
                          disabled={isSubmitting}
                          onChange={e =>
                            this.setState({
                              teacher_family_name: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Teacher Given Names</Trans>
                        </label>
                        <input
                          className="w-full form-input"
                          type="text"
                          value={teacher_given_names}
                          disabled={isSubmitting}
                          onChange={e =>
                            this.setState({
                              teacher_given_names: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Teacher Email</Trans>
                        </label>
                        <input
                          className="w-full form-input"
                          type="email"
                          value={teacher_email}
                          disabled={isSubmitting}
                          onChange={e =>
                            this.setState({
                              teacher_email: e.target.value
                            })
                          }
                        />
                      </div>
                      <div className="form-input-group">
                        <button
                          type="button"
                          onClick={this.handleTeacherCreate}
                          className="w-full btn">
                          {isCreatingTeacher ? (
                            <Trans>Adding Teacher...</Trans>
                          ) : (
                            <Trans>Add Teacher</Trans>
                          )}
                        </button>
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Lead Teachers</Trans>
                        </label>
                        {!teachers.length ? (
                          <span className="text-sm">
                            <Trans>No teachers yet.</Trans>
                          </span>
                        ) : (
                          <table>
                            <tbody>
                              {teachers.map(teacher => (
                                <tr key={teacher.id} className="mb-1 text-sm">
                                  <td className="pr-2">{teacher.full_name}</td>
                                  <td className="whitespace-no-wrap">
                                    <button
                                      type="button"
                                      onClick={() => this.handleRemoveTeacher(teacher.id)}
                                      className="btn btn-xs btn-solid-red">
                                      &times;
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Add Participant</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Participant Name</Trans>
                        </label>
                        <div className="w-full border rounded">
                          <GlobalSearch
                            onSelect={this.handleAddParticipant}
                            url="participants"
                            placeholder="Add a participant"
                          />
                        </div>
                      </div>

                      <div className="form-section-header bg-grey-lighter">
                        <Trans>Attachments</Trans>
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Upload A New File</Trans>
                        </label>
                        <MediaUpload url="attachments" onSuccess={this.handleUpload} />
                      </div>

                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Search Existing Files</Trans>
                        </label>
                        <GlobalSearch
                          border
                          url="attachments"
                          placeholder={i18n._(t`Type to search attachments`)}
                          onSelect={this.handleUpload}
                        />
                      </div>
                      <div className="form-input-group">
                        <label className="form-label">
                          <Trans>Current Files</Trans>
                        </label>
                        {!trip_attachments.length ? (
                          <span className="text-sm">
                            <Trans>No attachments yet.</Trans>
                          </span>
                        ) : (
                          <table>
                            <tbody>
                              {trip_attachments.map(attachment => (
                                <tr key={attachment.id} className="mb-1 text-sm">
                                  <td>{attachment.filename}</td>
                                  <td className="whitespace-no-wrap">
                                    <a
                                      rel="noopener noreferrer"
                                      className="mx-2 mr-2 no-underline text-grey hover:text-blue"
                                      href={`${process.env.REACT_APP_API_BASE_URL}/attachments/${
                                        attachment.id
                                      }?token=${localStorage.getItem("INDIER_JWT")}`}>
                                      <Icon icon="download" size={16} />
                                    </a>
                                    <button
                                      type="button"
                                      onClick={() => this.handleRemoveAttachment(attachment.id)}
                                      className="btn btn-xs btn-solid-red">
                                      &times;
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </>
                  )}

                  <div className="form-actions">
                    {id ? (
                      <DeleteButton url={`trips/${id}`} onDelete={this.handleDelete}>
                        <button type="button" className="btn btn-solid-red">
                          <Trans>Delete</Trans>
                        </button>
                      </DeleteButton>
                    ) : (
                      <Link className="btn btn-solid-red" to={`/trips`}>
                        <Trans>Cancel</Trans>
                      </Link>
                    )}
                    {id && (
                      <Link className="btn btn-solid-teal" to={`/trips/${id}`}>
                        <Trans>View</Trans>
                      </Link>
                    )}
                    {process.env.NODE_ENV === "development" && (
                      <button type="button" className="btn" onClick={this.generateTrip}>
                        Generate
                      </button>
                    )}
                    <button type="submit" disabled={isSubmitting} className="btn btn-solid-blue">
                      {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                    </button>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </div>
      </div>
    );
  }
}

export default TripCreateForm;
