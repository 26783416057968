import React from "react";
import Calendar from "../components/Calendar";

const Timesheet = ({ match }) => (
  <div className="main-page-container">
    <div className="p-2 md:p-8">
      <div>
        <Calendar match={match} hasAgenda={false} readOnly={false} />
      </div>
    </div>
  </div>
);

export default Timesheet;
