import React from "react";
import { Route, Switch } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import ManagerOnlyRoute from "./ManagerOnlyRoute";
import TeamLeaderOnlyRoute from "./TeamLeaderOnlyRoute";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ResetPassword from "../pages/auth/ResetPassword";
import RequestPasswordReset from "../pages/auth/RequestPasswordReset";
import VerifyEmail from "../pages/auth/VerifyEmail";
// const ResetPassword = React.lazy(() => import("./pages/auth/ResetPassword"));
// const RequestPasswordReset = React.lazy(() => import("./pages/auth/RequestPasswordReset"));
// const VerifyEmail = React.lazy(() => import("./pages/auth/VerifyEmail"));
import Profile from "../pages/Profile";
import Terms from "../pages/Terms";
import Gallery from "../pages/Gallery";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TeacherShow from "../pages/TeacherShow";
import ClientShow from "../pages/ClientShow";

import WhatsNew from "../pages/WhatsNew";

import FeedbackForm from "../pages/FeedbackForm";
import AdminFeedback from "../pages/AdminFeedback";
import Feedback from "../pages/Feedback";
import AdminFeedbackShow from "../pages/AdminFeedbackShow";
import FeedbackShow from "../pages/FeedbackShow";

import PaymentPage from "../pages/PaymentPage";
import PaymentIndex from "../pages/PaymentIndex";
import PaymentConfirm from "../pages/PaymentConfirm";

import RiskAssessmentPage from "../pages/RiskAssessmentPage";
import HazardCreate from "../pages/HazardCreate";
import RiskAssessmentCreate from "../pages/RiskAssessmentCreate";

import AdvertIndex from "../pages/AdvertIndex";
import AdvertCreate from "../pages/AdvertCreate";

import Timesheet from "../pages/Timesheet";
import MyTimesheet from "../pages/MyTimesheet";
import TimesheetIndex from "../pages/TimesheetIndex";

import MyPayments from "../pages/MyPayments";

import Impersonate from "../pages/Impersonate";

import MyParticipants from "../pages/MyParticipants";
import ParticipantCreateForm from "../pages/ParticipantCreateForm";
import ParticipantShow from "../pages/ParticipantShow";

import ChangePassword from "../pages/ChangePassword";

import StaffCreateForm from "../pages/StaffCreateForm";
import StaffIndex from "../pages/StaffIndex";
import ArchivedStaffIndex from "../pages/ArchivedStaffIndex";
import StaffEditForm from "../pages/StaffEditForm";
import StaffShow from "../pages/StaffShow";

import SubscriberIndex from "../pages/SubscriberIndex";

import TripRegistration from "../pages/TripRegistration";
import SummerCampRegistration from "../pages/SummerCampRegistration";

import ClientIndex from "../pages/ClientIndex";
import TeacherIndex from "../pages/TeacherIndex";
import ParticipantIndex from "../pages/ParticipantIndex";
import MedicalConditionIndex from "../pages/MedicalConditionIndex";
import AttachmentIndex from "../pages/AttachmentIndex";

import SkillIndex from "../pages/SkillIndex";
import SkillCreate from "../pages/SkillCreate";
import SkillEdit from "../pages/SkillEdit";

import MyTrips from "../pages/MyTrips";

import TripOverview from "../pages/TripOverview";
import TripIndex from "../pages/TripIndex";
import TripShow from "../pages/TripShow";
import TripCreateForm from "../pages/TripCreateForm";
import TripFeedback from "../pages/TripFeedback";

import TripArchiveIndex from "../pages/TripArchiveIndex";

import LocationIndex from "../pages/LocationIndex";
import LocationCreate from "../pages/LocationCreate";
import LocationShow from "../pages/LocationShow";
import LocationEdit from "../pages/LocationEdit";

import SchoolIndex from "../pages/SchoolIndex";
import SchoolCreate from "../pages/SchoolCreate";
import SchoolShow from "../pages/SchoolShow";
import SchoolEdit from "../pages/SchoolEdit";

import MedicalConditionForm from "../pages/MedicalConditionForm";
import Dashboard from "../pages/Dashboard";
import Waiver from "../pages/Waiver";

import NoMatch from "../pages/NoMatch";

const RoutesContainer = ({ user }) => (
  <Switch>
    <ProtectedRoute
      exact
      path="/"
      component={user && user.type !== "Staff" ? MyParticipants : Dashboard}
    />
    {/* Auth */}
    <Route exact path="/login" component={Login} />
    <Route exact path="/register" component={Register} />
    <Route exact path="/forgot" component={RequestPasswordReset} />
    <Route exact path="/verify/:email/:token" component={VerifyEmail} />
    <Route exact path="/reset/:token" component={ResetPassword} />
    <Route exact path="/change-password" component={ChangePassword} />
    <Route exact path="/impersonate" component={Impersonate} />
    {/* Feedback */}
    <ProtectedRoute exact path="/whats-new" component={WhatsNew} />
    <ManagerOnlyRoute exact path="/feedback/:id" component={FeedbackShow} />
    <ManagerOnlyRoute exact path="/feedback" component={Feedback} />
    <ManagerOnlyRoute exact path="/admin-feedback/:id" component={AdminFeedbackShow} />
    <ManagerOnlyRoute exact path="/admin-feedback" component={AdminFeedback} />
    <ProtectedRoute exact path="/submit-feedback" component={FeedbackForm} />
    <ProtectedRoute exact path="/gallery" component={Gallery} />
    <ProtectedRoute exact path="/hazards/:id/edit" component={HazardCreate} />
    <ProtectedRoute exact path="/hazards/create" component={HazardCreate} />
    <ProtectedRoute exact path="/risk_assessments" component={RiskAssessmentPage} />
    <ProtectedRoute exact path="/risk_assessments/template" component={RiskAssessmentPage} />
    <ProtectedRoute exact path="/risk_assessments/hazards" component={RiskAssessmentPage} />
    <ProtectedRoute exact path="/risk_assessments/create/:copy_id?" component={RiskAssessmentCreate} />
    <ProtectedRoute exact path="/risk_assessments/:id/edit" component={RiskAssessmentCreate} />
    <ProtectedRoute exact path="/adverts/:id/edit" component={AdvertCreate} />
    <ProtectedRoute exact path="/adverts/create" component={AdvertCreate} />
    <ProtectedRoute exact path="/adverts" component={AdvertIndex} />
    <ProtectedRoute exact path="/profile" component={Profile} />
    <ProtectedRoute exact path="/my-trips" component={MyTrips} />
    <ProtectedRoute exact path="/my-trips/:id" component={TripShow} />
    {/* Participants */}
    <ProtectedRoute exact path="/participants/create" component={ParticipantCreateForm} />
    <ProtectedRoute exact path="/participants/:id" component={ParticipantShow} />
    <ProtectedRoute exact path="/participants/:id/edit" component={ParticipantCreateForm} />
    {/* Trip registration and payments */}
    <ProtectedRoute exact path="/trip-registration/:code" component={TripRegistration} />
    <ProtectedRoute exact path="/trip-registration" component={TripRegistration} />
    <ProtectedRoute exact path="/summer-camp-registration" component={SummerCampRegistration} />
    <ProtectedRoute exact path="/my-payments" component={MyPayments} />
    <ProtectedRoute exact path="/payments/confirm" component={PaymentConfirm} />
    <ProtectedRoute exact path="/pay/:trip_id/:participant_id" component={PaymentPage} />
    {/* Staff */}
    <ProtectedRoute exact path="/timesheets/:staff_id" component={Timesheet} />
    <ProtectedRoute exact path="/my-timesheet" component={MyTimesheet} />
    <ManagerOnlyRoute exact path="/staffs/create" component={StaffCreateForm} />
    <ProtectedRoute exact path="/staffs/:id" component={StaffShow} />
    <ProtectedRoute exact path="/staffs/:id/edit" component={StaffEditForm} />
    <ManagerOnlyRoute exact path="/archived-staffs" component={ArchivedStaffIndex} />
    <ManagerOnlyRoute exact path="/staffs" component={StaffIndex} />
    {/* Subscribers */}
    <ManagerOnlyRoute exact path="/subscribers" component={SubscriberIndex} />
    {/* Clients */}
    <ManagerOnlyRoute exact path="/clients" component={ClientIndex} />
    <ProtectedRoute exact path="/clients/:id" component={ClientShow} />
    {/* Teachers */}
    <ManagerOnlyRoute exact path="/teachers" component={TeacherIndex} />
    <ProtectedRoute exact path="/teachers/:id" component={TeacherShow} />
    {/* Office */}
    <ManagerOnlyRoute path="/terms-and-conditions" component={Terms} />
    <ManagerOnlyRoute path="/privacy-policy" component={PrivacyPolicy} />
    <ManagerOnlyRoute exact path="/overview" component={TripOverview} />
    <ManagerOnlyRoute exact path="/timesheets" component={TimesheetIndex} />
    <ManagerOnlyRoute exact path="/waiver" component={Waiver} />
    <ProtectedRoute exact path="/attachments" component={AttachmentIndex} />
    <ManagerOnlyRoute exact path="/payments" component={PaymentIndex} />
    <ManagerOnlyRoute exact path="/participants" component={ParticipantIndex} />
    {/* Trips */}
    <TeamLeaderOnlyRoute exact path="/trips" component={TripIndex} />
    <ManagerOnlyRoute exact path="/trips/create" component={TripCreateForm} />
    <ProtectedRoute exact path="/trips/:id" component={TripShow} />
    <ManagerOnlyRoute exact path="/trips/:id/feedback" component={TripFeedback} />
    <ManagerOnlyRoute exact path="/trips/:id/edit" component={TripCreateForm} />
    <ManagerOnlyRoute exact path="/archived-trips" component={TripArchiveIndex} />
    <ManagerOnlyRoute exact path="/archived-trips/:id" component={TripShow} />
    <ManagerOnlyRoute exact path="/archived-trips/:id/edit" component={TripCreateForm} />
    {/* Skills */}
    <ManagerOnlyRoute exact path="/skills" component={SkillIndex} />
    <ManagerOnlyRoute exact path="/skills/create" component={SkillCreate} />
    <ManagerOnlyRoute exact path="/skills/:id/edit" component={SkillEdit} />
    {/* Locations */}
    <ManagerOnlyRoute exact path="/locations" component={LocationIndex} />
    <ManagerOnlyRoute exact path="/locations/create" component={LocationCreate} />
    <ManagerOnlyRoute exact path="/locations/:id" component={LocationShow} />
    <ManagerOnlyRoute exact path="/locations/:id/edit" component={LocationEdit} />
    {/* Schools */}
    <ManagerOnlyRoute exact path="/schools" component={SchoolIndex} />
    <ManagerOnlyRoute exact path="/schools/create" component={SchoolCreate} />
    <ManagerOnlyRoute exact path="/schools/:id" component={SchoolShow} />
    <ManagerOnlyRoute exact path="/schools/:id/edit" component={SchoolEdit} />
    {/* Medical conditions */}
    <ManagerOnlyRoute exact path="/medical_conditions" component={MedicalConditionIndex} />
    <ManagerOnlyRoute
      exact
      path="/medical_conditions/create"
      render={props => (
        <MedicalConditionForm
          isCreate={true}
          {...props}
          endpoint="medical_conditions"
          model="Medical Condition"
        />
      )}
    />
    <ManagerOnlyRoute
      exact
      path="/medical_conditions/:id/edit"
      render={props => (
        <MedicalConditionForm {...props} endpoint="medical_conditions" model="Medical Condition" />
      )}
    />
    )}
    <Route component={NoMatch} />
  </Switch>
);

export default RoutesContainer;
